import { useCallback, useEffect, useState } from 'react';

export default function useCounter(run = false) {
  const [time, setTime] = useState(60);

  const remainTime = useCallback(() => {
    if (!run) {
      setTime(60);
      return;
    }

    if (time === 0) {
      setTime(60);
    } else {
      setTime(time - 1);
    }
  }, [time, run]);

  useEffect(() => {
    setTimeout(remainTime, 1e3);
  }, [time, remainTime]);

  return {
    time
  };
}
