import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import { GET } from 'helpers/request';

export default function SelectInputSearch({
  url,
  valueField,
  nameField,
  lastNameField,
  classes,
  placeholder,
  onChange,
  paramsBusinessId = '0',
  setBusinessData,
  label,
  defaultValue,
  error,
  disabled,
  noDeleted
}) {
  const [inputValue, setInputValue] = useState({});
  const [data, setData] = useState([{ value: null, label: 'Cargando...' }]);
  const [loading, setLoading] = useState(true);

  const getData = useCallback(async () => {
    let res = await GET(url);

    if (noDeleted) {
      res = {
        ...res,
        datos: res.datos.filter((item) => item[noDeleted] === null)
      };
    }

    let mapped;

    if (res.datos?.length === 0) {
      mapped = [
        {
          value: '0',
          label: 'No exiten resultados'
        }
      ];

      // implementation only for situacion documental screen
      // if params id exits, set business data with businees data from fetch to show title in screen
      if (paramsBusinessId !== '0') {
        setBusinessData?.(
          mapped.find((item) => item.value === paramsBusinessId)
        );
      }

      setLoading(false);
      return setData(mapped);
    }

    if (res.datos?.length >= 1) {
      if (lastNameField) {
        mapped = res.datos.map((item) => ({
          value: item[valueField],
          label: item[nameField] + ' ' + item[lastNameField]
        }));

        setInputValue(mapped.filter((item) => item.value === defaultValue));
        setLoading(false);

        setData(mapped);

        return;
      }

      mapped = res.datos.map((item) => ({
        value: item[valueField],
        label: item[nameField]
      }));

      // implementation only for situacion documental screen
      // if params id exits, set business data with businees data from fetch to show title in screen
      if (paramsBusinessId !== '0') {
        setBusinessData?.(
          mapped.find((item) => item.value === paramsBusinessId)
        );
      }

      setInputValue(mapped.filter((item) => item.value === defaultValue));
      setLoading(false);

      setData(mapped);

      return;
    }
  }, [
    url,
    nameField,
    valueField,
    lastNameField,
    paramsBusinessId,
    setBusinessData,
    defaultValue,
    noDeleted
  ]);

  const handleChange = (value) => {
    onChange(value);
    setInputValue(value);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (localStorage.getItem('darkSwitch')) {
      const selectSearch = document.querySelector(
        '.react-select-search__input'
      );
      if (selectSearch) {
        selectSearch.style.color = '#fff';
      }
    } else {
      const selectSearch = document.querySelector(
        '.react-select-search__input'
      );
      if (selectSearch) {
        selectSearch.style.color = '#fff';
      }
    }

    //eslint-disable-next-line
  }, []);

  return (
    <div className={classes}>
      <label className="mb-2">{label}</label>
      <Select
        options={data}
        placeholder={placeholder}
        isLoading={loading}
        onChange={(value) => handleChange(value)}
        value={inputValue}
        className="react-select-search"
        classNamePrefix="react-select-search"
        isDisabled={disabled}
      />
      {error && <p className="mt-2 text-danger w-100 mb-0">{error}</p>}
    </div>
  );
}
