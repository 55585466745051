import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { AuthProvider } from '../auth/authContext';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import Navbar from 'components/layout/Navbar';
import Sidebar from 'components/layout/Sidebar';

// grupo 2
import SituacionDocumental from 'pages/SituacionDocumental';
import Login from 'pages/Login';

import ModulePage from 'pages/ModulePage';

import ReportGeneral from 'pages/reports/ReportGeneral';
import ReportValidation from 'pages/reports/ReportValidation';
import ReportAudit from 'pages/reports/ReportAudit';
import ReportValids from 'pages/reports/ReportValids';
import ReportInexistentes from 'pages/reports/ReportInexistentes';
import CargarDatos from 'pages/CargarDatos';
import ReportSituDoc from 'pages/reports/ReportSituDoc';
import ModulePageMkt from 'marketing/ModulePageMkt';
import ModuleFormPageMkt from 'marketing/ModuleFormPageMkt';
import ReportType from 'pages/reports/ReportType';
import ReportDesempeno from 'pages/reports/ReportDesempeno';
import ReportProv from 'pages/reports/Reportprov';
import ReportPorExpirar from 'pages/reports/ReportPorExpirar';
import CargaMasiva from 'pages/CargaMavisa';
import SituDocClasificacion from 'pages/SituDocClasificacion';
import SituDocWoh from 'pages/SituDocWoh';
import ReportClasificacion from 'pages/reports/ReportClasificacion';
import CRUDPageV2 from 'pages/CRUDPageV2';
import ReportSinAsignar from 'pages/reports/ReportSinAsignar';
import ReportFormatos from 'pages/reports/ReportFormatos';
import Planes from 'pages/Planes';
import ReportClicks from 'pages/reports/ReportClicks';
import ChangePassword from 'pages/ChangePassword';
import ChangePasswordRoute from './ChangePasswordRoute';
import Reporte20 from 'pages/reports/Reporte20';
import Reporte21 from 'pages/reports/Reporte21';
import Reporte22 from 'pages/reports/Reporte-22';
import Report_formatos_inexistentes from 'pages/reports/Report_formatos_inexistentes';
import Reporte_23 from 'pages/reports/Reporte-23';
import ReportDocsLectura from 'pages/reports/ReportDocsLectura';
import FaltasEmpresa from 'pages/Faltas-empresa';
// import Test from "pages/Test";

//prettier-ignore
// dentro de router le cambio basename="/adminflex"
export default function AppRouter() {
  return (
    <Router>
      <AuthProvider>
      <div className="app-content">
        <Navbar />
        <Sidebar />

        <Switch>
          <PrivateRoute exact path="/situacionDocumental/:paramsBusinessId/:paramCatId/:paramBusName" component={SituacionDocumental} />
          <PrivateRoute exact path="/situdoccla" component={SituDocClasificacion} />
          <PrivateRoute exact path="/situdocwoh" component={SituDocWoh} />
          <PrivateRoute exact path="/planes" component={Planes} />

          <PrivateRoute exact path="/faltas-empresa" component={FaltasEmpresa} />

          <PrivateRoute
            exact
            path="/module/:module/:id/:typeId/:typeTitle"
            component={ModulePage}
          />
            
          <PrivateRoute path="/module/:module/:id/:title?" component={ModulePage} />
          <PrivateRoute path="/module/:module/:id/:busId?" component={ModulePage} />
          <PrivateRoute exact path="/module/:module/:id" component={ModulePage} />


          <PrivateRoute exact path="/crud/:crudModule/:busId/:busName?" component={CRUDPageV2} />
          <PrivateRoute exact path="/crud/:crudModule" component={CRUDPageV2} />

          <PrivateRoute exact path="/reportgeneral" component={ReportGeneral} />
          <PrivateRoute exact path="/reportvalidation" component={ReportValidation} />
          <PrivateRoute exact path="/reportaudit" component={ReportAudit} />
          <PrivateRoute exact path="/reportvalids" component={ReportValids} />
          <PrivateRoute exact path="/reportinexistentes" component={ReportInexistentes} />
          <PrivateRoute exact path="/reportsituDoc" component={ReportSituDoc} />
          <PrivateRoute exact path="/reporttype" component={ReportType} />
          <PrivateRoute exact path="/ReportDesempeno" component={ReportDesempeno} />
          <PrivateRoute exact path="/reportprov" component={ReportProv} />
          <PrivateRoute exact path="/reportexpirar" component={ReportPorExpirar} />
          <PrivateRoute exact path="/reportclasificacion" component={ReportClasificacion} />
          <PrivateRoute exact path="/reportsinasignar" component={ReportSinAsignar} />
          <PrivateRoute exact path="/reportformatos" component={ReportFormatos} />
          <PrivateRoute exact path="/reportclicks" component={ReportClicks} />
          <PrivateRoute exact path="/reportedocsempresa" component={Reporte20} />
          <PrivateRoute exact path="/reporte21" component={Reporte21} />
          <PrivateRoute exact path="/reporte22" component={Reporte22} />
          <PrivateRoute exact path="/reporte23" component={Reporte_23} />
          <PrivateRoute exact path="/reporte_formatos_no_existentes" component={Report_formatos_inexistentes} />
          <PrivateRoute exact path="/reporte-docs-lectura" component={ReportDocsLectura} />
          

          <PrivateRoute exact path="/cargardatos" component={CargarDatos} />

          <PrivateRoute exact path="/marketing/:module" component={ModulePageMkt} />
          <PrivateRoute exact path="/marketing/:module/:id" component={ModuleFormPageMkt} />
          <PrivateRoute exact path="/marketing/:module/:id/:several" component={ModuleFormPageMkt} />

          <PrivateRoute exact path="/emails/:module/:id/:title" component={ModulePageMkt} />

          <PrivateRoute exact path="/cargas" component={CargaMasiva} />

          <ChangePasswordRoute exact path="/changepassword" component={ChangePassword} />

          <PublicRoute exact path="/login" component={Login} />

          {/* <Route path="/404" component={NotFoundPage} /> */}
          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </div>
      </AuthProvider>
    </Router>
  );
}
