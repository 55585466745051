import FullWidthContainer from 'components/layout/FullWidthContainer';
import Autocomplete from 'components/select-search/Autocomplete';
import { DWNLD_POST_V2, POST } from 'helpers/request';
import useRequest from 'hooks/useRequest';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function ReportFormatos() {
  const { reqTokenBase } = useRequest();

  const toggleModal = useRef();
  const history = useHistory();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalBusiness, setModalBusiness] = useState(null);

  const getData = async (busId) => {
    setLoading(true);
    setData([]);

    const res = await POST(
      { cual: '17', bus_int_id: busId, xls: '0' },
      // { cual: '10', bus_int_id: '353', typ_int_id: '21', xls: '0' },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    if (res.tipos && res.formatos) {
      setData({ tipos: res.tipos, formatos: res.formatos });
      setBusiness({ ...res.cab, id: busId });
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const handleDescargar = () => {
    DWNLD_POST_V2(
      'reports',
      `reporte-formatos-${business.Empresa}-${business.Fecha}.xlsx`,
      17,
      {
        bus_int_id: business.id,
        xls: 1
      }
    );
  };

  const getModalReportDialog = async (que, mostrar, for_int_id) => {
    setLoadingModal(true);
    setModalData([]);

    const { data } = await reqTokenBase.post('empresa/reports', {
      cual: '19',
      bus_int_id: businessId,
      xls: '0',
      que,
      mostrar,
      for_int_id
    });

    const values = data['0'].valor;

    setModalData(values);
    setModalBusiness({ ...data.cab });
    setLoadingModal(false);

    toggleModal.current.click();
  };

  const redirect = (id) => {
    history.push(`/situacionDocumental/${id}/0/0`);
  };

  const ReportModal = () => {
    return (
      <div
        className="modal fade"
        id="modalReportDialog"
        tabIndex={-1}
        aria-labelledby="modalReportDialogLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content bg-light">
            <div className="modal-header">
              <h5 className="modal-title" id="modalReportDialogLabel">
                Proveedores {' '}
                {modalBusiness ? `de ${modalBusiness.Empresa}` : ''}
              </h5>
              <button
                type="button"
                className="btn-close-modal btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalData([])}
              >
                <i className="fas fa-times fa-lg"></i>
              </button>
            </div>
            <div className="modal-body py-5">
              {loadingModal && (
                <p className="text-center w-100 m-0">Cargando...</p>
              )}

              {!loadingModal && modalData.length > 0 && (
                <table className="table table-striped table-bordered m-0 w-100">
                  <thead>
                    <tr
                      className="text-white"
                      style={{ backgroundColor: '#6f6f6f' }}
                    >
                      <th scope="col">Id</th>
                      <th scope="col">Proveedor</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalData.map((item) => (
                      <tr key={item.bus_int_id}>
                        <td>{item.bus_int_id}</td>
                        <td
                          className="w-100 fw-bold sit-doc-item-row text-decoration-none text-darkmode d-block"
                          onClick={() => redirect(item.bus_int_id)}
                          data-bs-dismiss="modal"
                          style={{ cursor: 'pointer' }}
                        >
                          {item.bus_text_name}
                        </td>
                        <td>{item.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {!loadingModal && modalData.length === 0 && (
                <p className="text-center w-100 m-0">Sin datos</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setModalData([])}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">
        Situación Documental General por Empresa
      </h2>

      <button
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#modalReportDialog"
        ref={toggleModal}
      ></button>

      <ReportModal />

      <div className="d-flex align-items-end gap-3 mb-3">
        <div className="w-100 d-flex gap-3 align-items-end">
          <Autocomplete
            label="Selecciona un proveedor"
            classes="flex-grow-1"
            url="business_admin"
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              getData(item.value);
              setBusinessId(item.value);
            }}
          />
        </div>

        {!loading && data && (
          <button
            onClick={handleDescargar}
            type="button"
            className="btn btn-success d-flex align-items-center"
          >
            <i className="fas fa-download me-2"></i>Excel
          </button>
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading &&
        data &&
        data.tipos.map((item) => (
          <div key={item.typ_int_id} className="w-100 d-flex flex-column mb-4">
            <div
              className="w-100 p-3 border border-bottom-0"
              style={{ backgroundColor: '#5e5e5e' }}
            >
              <h5 className="m-0 text-center text-white">
                {item.typ_text_name}
              </h5>
            </div>
            <div className="w-100">
              <table className="table table-striped table-bordered m-0 w-100">
                <thead>
                  <tr
                    className="text-white"
                    style={{ backgroundColor: '#6f6f6f' }}
                  >
                    <th scope="col"></th>
                    <th scope="col" style={{ width: '40%' }}>
                      Formato
                    </th>
                    <th scope="col">Validos</th>
                    <th scope="col">Caducados</th>
                    <th scope="col">Inexistentes</th>
                    <th scope="col">Pendientes</th>
                    <th scope="col">Invalidados</th>
                    <th scope="col">Obsoletos</th>
                    <th scope="col">Exigibles</th>
                  </tr>
                </thead>
                <tbody>
                  {data.formatos[item.typ_int_id].map((formato) => (
                    <tr key={formato.for_int_id}>
                      <td>
                        <i
                          className={`mx-auto fas ${
                            formato.for_int_tipo === '2' && 'fa-building'
                          } ${
                            formato.for_int_tipo === '3' && 'fa-user-friends'
                          } ${
                            formato.for_int_tipo === '4' && 'fa-tools'
                          } hover-primary`}
                          title={`Formato: ${formato.for_int_id}`}
                        />
                      </td>
                      <td style={{ width: '40%' }}>{formato.for_text_name}</td>
                      <td
                        className={`${
                          formato.validos !== '0' ? 'hover-list-item' : ''
                        }`}
                        style={{
                          cursor:
                            formato.validos !== '0' ? 'pointer' : 'not-allowed'
                        }}
                        onClick={() =>
                          formato.validos !== '0'
                            ? getModalReportDialog(
                                formato.for_int_tipo,
                                'validos',
                                formato.for_int_id
                              )
                            : null
                        }
                      >
                        {formato.validos}
                      </td>
                      <td
                        className={`${
                          formato.caducados !== '0' ? 'hover-list-item' : ''
                        }`}
                        style={{
                          cursor:
                            formato.caducados !== '0'
                              ? 'pointer'
                              : 'not-allowed'
                        }}
                        onClick={() =>
                          formato.caducados !== '0'
                            ? getModalReportDialog(
                                formato.for_int_tipo,
                                'caducados',
                                formato.for_int_id
                              )
                            : null
                        }
                      >
                        {formato.caducados}
                      </td>
                      <td
                        className={`${
                          formato.inexistentes !== '0' ? 'hover-list-item' : ''
                        }`}
                        style={{
                          cursor:
                            formato.inexistentes !== '0'
                              ? 'pointer'
                              : 'not-allowed'
                        }}
                        onClick={() =>
                          formato.inexistentes !== '0'
                            ? getModalReportDialog(
                                formato.for_int_tipo,
                                'existencia',
                                formato.for_int_id
                              )
                            : null
                        }
                      >
                        {formato.inexistentes}
                      </td>
                      <td
                        className={`${
                          formato.pendientes !== '0' ? 'hover-list-item' : ''
                        }`}
                        style={{
                          cursor:
                            formato.pendientes !== '0'
                              ? 'pointer'
                              : 'not-allowed'
                        }}
                        onClick={() =>
                          formato.pendientes !== '0'
                            ? getModalReportDialog(
                                formato.for_int_tipo,
                                'pendiente',
                                formato.for_int_id
                              )
                            : null
                        }
                      >
                        {formato.pendientes}
                      </td>
                      <td
                        className={`${
                          formato.invalidados !== '0' ? 'hover-list-item' : ''
                        }`}
                        style={{
                          cursor:
                            formato.invalidados !== '0'
                              ? 'pointer'
                              : 'not-allowed'
                        }}
                        onClick={() =>
                          formato.invalidados !== '0'
                            ? getModalReportDialog(
                                formato.for_int_tipo,
                                'invalidos',
                                formato.for_int_id
                              )
                            : null
                        }
                      >
                        {formato.invalidados}
                      </td>
                      <td
                        className={`${
                          formato.obsoletos !== '0' ? 'hover-list-item' : ''
                        }`}
                        style={{
                          cursor:
                            formato.obsoletos !== '0'
                              ? 'pointer'
                              : 'not-allowed'
                        }}
                        onClick={() =>
                          formato.obsoletos !== '0'
                            ? getModalReportDialog(
                                formato.for_int_tipo,
                                'obsoletos',
                                formato.for_int_id
                              )
                            : null
                        }
                      >
                        {formato.obsoletos}
                      </td>
                      <td
                        className={`${
                          formato.exigibles !== '0' ? 'hover-list-item' : ''
                        }`}
                        style={{
                          cursor:
                            formato.exigibles !== '0'
                              ? 'pointer'
                              : 'not-allowed'
                        }}
                        onClick={() =>
                          formato.exigibles !== '0'
                            ? getModalReportDialog(
                                formato.for_int_tipo,
                                'exigibles',
                                formato.for_int_id
                              )
                            : null
                        }
                      >
                        {formato.exigibles}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
    </FullWidthContainer>
  );
}
