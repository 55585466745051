import React, { useRef, useState } from "react";
import { DELETE } from "helpers/request";

export default function DeleteModal({ recordId, recordName, setRegistros }) {
  const closeBtnRef = useRef();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    const res = await DELETE("records", recordId);

    if (res.request.status === 200) {
      setLoading(false);

      closeBtnRef.current.click();

      setRegistros((prevState) =>
        prevState.map((item) =>
          item.rec_int_id === recordId
            ? { ...item, rec_date_deletion_date: new Date().toGMTString() }
            : item
        )
      );
    }
  };

  return (
    <div
      className="modal fade"
      id={`deleteRecModal-${recordId}`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteModalLabel">
              Borrar registro
            </h5>
            <button
              disabled={loading ? true : false}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <h4 className="text-danger text-center mb-4">
              ¿ Estas seguro que deseas borrar este registro ?
            </h4>
            <p className="text-center mb-0 fw-normal">{recordName}</p>
          </div>
          <div className="modal-footer">
            <button
              ref={closeBtnRef}
              disabled={loading ? true : false}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              onClick={handleDelete}
              disabled={loading ? true : false}
              type="button"
              className="btn btn-danger"
            >
              Borrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
