import React, { useState, useEffect } from 'react';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { POST } from 'helpers/request';
import { Link } from 'react-router-dom';
import Autocomplete from 'components/select-search/Autocomplete';

export default function ReportType() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [busId, setBusId] = useState('');
  const [typeId, setTypeId] = useState('');

  const getData = async () => {
    setLoading(true);
    setData([]);

    const res = await POST(
      { cual: '7', bus_int_id: busId, typ_int_id: typeId },
      'reports',
      null,
      null
    );

    if (res[0].valor) {
      setData(res[0].valor);
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const colorSelector = (value) => {
    if (value === 100) return 'success';
    // if (value < 100 && value > 50) return "warning";
    if (value < 100) return 'danger';
  };

  const generalColorSelector = (value) => {
    if (value === 100) return 'success';
    if (value < 100 && value > 50) return 'warning';
    if (value < 50) return 'danger';
  };

  useEffect(() => {
    if (busId && typeId) {
      getData();
    }

    //eslint-disable-next-line
  }, [busId, typeId]);

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">Reporte por tipo de requisito</h2>

      <div className="w-100 mb-3 d-flex flex-wrap gap-3">
        <Autocomplete
          label="Selecciona una empresa"
          classes="flex-grow-1"
          url="business_admin"
          //son los nombres de los campos de el esquema, de id y de nombre
          valueField="bus_int_id"
          nameField="bus_text_name"
          placeholder="Escoja una opción"
          onChange={(item) => {
            setBusId('');
            setTypeId('');
            setData(null);

            setTimeout(() => {
              setBusId(item.value);
            }, 100);
          }}
        />

        {busId && (
          <Autocomplete
            label="Tipo de requisito"
            classes="flex-grow-1"
            url={`business_types/${busId}`}
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="typ_int_id"
            nameField="typ_text_name"
            placeholder="Escoja un tipo"
            onChange={(item) => setTypeId(item.value)}
          />
        )}
      </div>

      <div
        className="w-100 px-3 bg-secondary text-white d-flex"
        style={{ borderRadius: '3px 3px 0 0' }}
      >
        <p className="mb-0 w-50 text-start fw-bold text-truncate border-end py-3">
          Proveedor
        </p>
        <p className="mb-0 w-50 text-center fw-bold text-truncate py-3">
          Estado
        </p>
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="alert alert-warning text-center boder py-3 mt-3">
          La empresa no tiene proveedores con el requisito seleccionado
        </div>
      )}

      {!loading &&
        data &&
        data.length > 0 &&
        data.map((item) => (
          <div key={item.bus_int_id} className="border d-flex px-3">
            <div className="w-50 py-3 border-end pe-3 d-flex align-items-center d-flex flex-column align-items-center justify-content-center">
              <Link
                to={`/situacionDocumental/${item.bus_int_id}/0/0`}
                className="mb-0 w-100 text-center word-break fw-bold sit-doc-item-row py-2 text-decoration-none text-darkmode"
              >
                {item.bus_text_name}
              </Link>

              <div className="progress w-100" style={{ height: '8px' }}>
                <div
                  className={`progress-bar bg-${generalColorSelector(
                    Number(
                      (
                        ((item.empresa_e + item.trabajador_e + item.equipo_e) /
                          (item.empresa_r +
                            item.trabajador_r +
                            item.equipo_r)) *
                        100
                      ).toFixed(0)
                    )
                  )}`}
                  role="progressbar"
                  style={{
                    width: `${(
                      ((item.empresa_e + item.trabajador_e + item.equipo_e) /
                        (item.empresa_r + item.trabajador_r + item.equipo_r)) *
                      100
                    ).toFixed(0)}%`,
                    fontSize: '12px'
                  }}
                  aria-valuenow={(
                    ((item.empresa_e + item.trabajador_e + item.equipo_e) /
                      (item.empresa_r + item.trabajador_r + item.equipo_r)) *
                    100
                  ).toFixed(0)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div className="w-50 py-3 ps-3 d-flex flex-column">
              <div className="row mx-0 w-100 align-items-center justify-content-center pb-2">
                <span className="col-4 fw-bold">Empresa:</span>
                <span className="col-4">
                  {item.empresa_e} de {item.empresa_r}
                </span>
                <div className="col-4">
                  <div className="progress" style={{ height: '20px' }}>
                    <div
                      className={`progress-bar bg-${colorSelector(
                        Number(
                          ((item.empresa_e / item.empresa_r) * 100).toFixed(0)
                        )
                      )}`}
                      role="progressbar"
                      style={{
                        width: `${(item.empresa_e / item.empresa_r) * 100}%`,
                        fontSize: '12px'
                      }}
                      aria-valuenow={(item.empresa_e / item.empresa_r) * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {item.empresa_e > 0 && item.empresa_r > 0
                        ? `${((item.empresa_e / item.empresa_r) * 100).toFixed(
                            0
                          )}%`
                        : ''}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0 w-100 align-items-center justify-content-center py-2">
                <span className="col-4 fw-bold">Trabajador:</span>
                <span className="col-4">
                  {item.trabajador_e} de {item.trabajador_r}
                </span>
                <div className="col-4">
                  <div className="progress" style={{ height: '20px' }}>
                    <div
                      className={`progress-bar bg-${colorSelector(
                        Number(
                          (
                            (item.trabajador_e / item.trabajador_r) *
                            100
                          ).toFixed(0)
                        )
                      )}`}
                      role="progressbar"
                      style={{
                        width: `${
                          (item.trabajador_e / item.trabajador_r) * 100
                        }%`,
                        fontSize: '12px'
                      }}
                      aria-valuenow={
                        (item.trabajador_e / item.trabajador_r) * 100
                      }
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {item.trabajador_e > 0 && item.trabajador_r > 0
                        ? `${(
                            (item.trabajador_e / item.trabajador_r) *
                            100
                          ).toFixed(0)}%`
                        : ''}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0 w-100 align-items-center justify-content-center pt-2">
                <span className="col-4 fw-bold">Equipo:</span>
                <span className="col-4">
                  {item.equipo_e} de {item.equipo_r}
                </span>
                <div className="col-4">
                  <div className="progress" style={{ height: '20px' }}>
                    <div
                      className={`progress-bar bg-${colorSelector(
                        Number(
                          ((item.equipo_e / item.equipo_r) * 100).toFixed(0)
                        )
                      )}`}
                      role="progressbar"
                      style={{
                        width: `${(item.equipo_e / item.equipo_r) * 100}%`,
                        fontSize: '12px'
                      }}
                      aria-valuenow={(item.equipo_e / item.equipo_r) * 100}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {item.equipo_e > 0 && item.equipo_r > 0
                        ? `${((item.equipo_e / item.equipo_r) * 100).toFixed(
                            0
                          )}%`
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </FullWidthContainer>
  );
}
