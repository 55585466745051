const parentKeyTable = {
  business: "bus_int_id",
  categories: "cat_int_id",
  users: "use_int_id",
};

export function parentKeySelector(label) {
  return parentKeyTable[label];
}

const keyTable = {
  business: "typ_int_id",
  categories: "typ_int_id",
  users: "acc_int_id",
};

export function keySelector(label) {
  return keyTable[label];
}

const moduleTable = {
  business: "business_types",
  categories: "categories_types",
  users: "access_users",
};

export function moduleSelector(label) {
  return moduleTable[label];
}

const childAssignedItemTable = {
  business: "typ_int_id",
  categories: "typ_int_id",
  users: "acc_int_id",
};

export function childKeySelector(label) {
  return childAssignedItemTable[label];
}

const titleSelector = {
  business: "Tipos de requisitos",
  categories: "Tipos de requisitos",
  users: "Accesos",
};

export function titleKeySelector(label) {
  return titleSelector[label];
}
