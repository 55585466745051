import React, { useEffect, useState } from 'react';
import { GET, POST, PUT } from 'helpers/request';
import { useHistory } from 'react-router';

import { useForm } from 'hooks/useForm';
import { DefaultInput, DefaultSelect } from 'templates/input';

import AlertMessage from 'components/layout/AlertMessage';
import RelationEditionList from 'components/EditionGroups/RelationEditionList';

const defaultValues = {
  user_email_text: '',
  user_pass_text: '',
  user_user_text: '',
  use_text_email: '',
  use_text_pass: '',
  bus_int_id: '',
  use_int_admin: '',
  use_text_user: '',
  use_text_name: '',
  use_text_lastname: '',
  use_text_phone: '',
  use_text_address: ''
};

export default function UsersForm({
  module,
  id,
  data,
  title,
  businessId,
  handleGoBack
}) {
  const [values, handleInputChange, reset] = useForm(
    data
      ? {
          ...data,
          user_email_text: data.use_text_email || '',
          user_user_text: data.use_text_user || '',
          user_pass_text: '',
          bus_int_id: data.bus_int_id || '',
          use_int_admin: data.use_int_admin || '0',
          use_text_name: data.use_text_name || '',
          use_text_lastname: data.use_text_lastname || '',
          use_text_phone: data.use_text_phone || '',
          use_text_address: data.use_text_address || ''
        }
      : { ...defaultValues, bus_int_id: businessId }
  );
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  const [business, setBusiness] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const data = await GET('business?l=999&0=0');
        setBusiness(
          data.datos.map(({ bus_int_id, bus_text_name }) => ({
            value: bus_int_id,
            name: bus_text_name
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetcher();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    const correctData = {
      use_text_user: values.user_user_text,
      use_int_admin: values.use_int_admin,
      bus_int_id: values.bus_int_id,
      use_text_email: values.user_email_text,
      use_text_pass: values.user_pass_text || '',
      use_text_name: values.use_text_name,
      use_text_lastname: values.use_text_lastname,
      use_text_phone: values.use_text_phone,
      use_text_address: values.use_text_address
    };

    if (id === '0') {
      const data = await POST(correctData, module, setError, reset);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
      setSuccess(data ? data.status : 500);
    }

    //prettier-ignore
    if (id !== "0") {
      const data = await PUT(defaultValues, correctData, module, id, setError, reset);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setLoading(false)
      setSuccess(data ? data.status : 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      history.goBack();
    }
  }, [success, history]);

  const {
    bus_int_id,
    user_user_text,
    user_email_text,
    user_pass_text,
    use_text_name,
    use_text_lastname,
    use_text_phone,
    use_text_address,
    use_int_admin
  } = values;

  return (
    <div className="row w-100 mx-0">
      {id !== '0' ? (
        <h2 className="w-100 text-center mb-3 mb-lg-5">
          Editar usuario ( {title.replace(/-/g, ' ')} )
        </h2>
      ) : (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Nuevo usuario</h2>
      )}

      <div className="d-flex justify-content-start align-items-center mb-4 gap-3">
        <button
          className="btn btn-outline-danger me-auto btn-sm"
          onClick={handleGoBack}
          type="button"
        >
          <i className="fa fas fa-long-arrow-alt-left me-2"></i> Volver a la
          lista
        </button>
      </div>

      <form
        onSubmit={handleSubmit}
        className={`${
          id !== '0' ? 'col-7' : 'col-12'
        } col-7 px-3 px-xl-5 d-flex flex-column`}
        autoComplete="off"
      >
        {success && <AlertMessage status={success} id={id} />}

        <DefaultSelect
          label="Seleccione un rol"
          inputName="use_int_admin"
          placeholder="Selecciona una opción"
          onChange={handleInputChange}
          error={errorData?.use_int_admin}
          defaultValue={use_int_admin}
          options={[
            { value: '0', name: 'Usuario' },
            { value: '1', name: 'Administrador' }
          ]}
        />

        {!businessId && (
          <DefaultSelect
            label="Empresa"
            defaultValue={bus_int_id}
            inputName="bus_int_id"
            placeholder="Selecciona una empresa"
            onChange={handleInputChange}
            error={errorData?.bus_int_id}
            options={business}
          />
        )}

        <DefaultInput
          label="Usuario de acceso"
          type="text"
          value={user_user_text}
          inputName="user_user_text"
          onChange={handleInputChange}
          placeholder="Ingresa un usuario"
          error={errorData?.use_text_user}
        />

        <DefaultInput
          label="Correo electrónico"
          type="text"
          value={user_email_text}
          inputName="user_email_text"
          onChange={handleInputChange}
          placeholder="Ingresa un usuario"
          error={errorData?.use_text_email}
        />

        <DefaultInput
          label="Contraseña"
          type="text"
          value={user_pass_text}
          inputName="user_pass_text"
          onChange={handleInputChange}
          placeholder="Ingresa una contraseña"
          error={errorData?.use_text_pass}
          required={false}
        />

        <DefaultInput
          label="Nombre"
          type="text"
          value={use_text_name}
          inputName="use_text_name"
          onChange={handleInputChange}
          placeholder="Ingresa un nombre"
          error={errorData?.use_text_name}
        />

        <DefaultInput
          label="Apellido"
          type="text"
          value={use_text_lastname}
          inputName="use_text_lastname"
          onChange={handleInputChange}
          placeholder="Ingresa un apellido"
          error={errorData?.use_text_lastname}
        />

        <DefaultInput
          label="Teléfono"
          type="number"
          value={use_text_phone}
          inputName="use_text_phone"
          onChange={handleInputChange}
          placeholder="Ingresa un número telefónico"
          error={errorData?.use_text_phone}
        />

        <DefaultInput
          label="Dirección"
          type="text"
          value={use_text_address}
          inputName="use_text_address"
          onChange={handleInputChange}
          placeholder="Ingresa una dirección"
          error={errorData?.use_text_address}
        />

        <button
          type="submit"
          className="btn btn-primary mt-4"
          disabled={loading ? true : false}
        >
          {loading ? (
            <>
              <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
            </>
          ) : id !== '0' ? (
            'Editar'
          ) : (
            'Crear'
          )}
        </button>
      </form>

      {id !== '0' && <RelationEditionList groupFor="users" id={id} />}
    </div>
  );
}
