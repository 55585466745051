/* eslint-disable react/jsx-pascal-case */
import FullWidthContainer from 'components/layout/FullWidthContainer';
import { Modal_info } from 'components/reporte-formatos-inexistentes/Modal-info';
import Autocomplete from 'components/select-search/Autocomplete';
import random_id from 'helpers/random_id';
import { GET, POST } from 'helpers/request';
import { useState } from 'react';

export default function Report_formatos_inexistentes() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parentBusiness, setParentBusiness] = useState(null);
  const [actualFormatIds, setActualFormatIds] = useState({
    bus_int_id: '',
    for_int_id: '',
    typ_int_id: '',
    for_int_tipo: ''
    // woh_int_id: '',
  });

  const [infoFormato, setInfoFormato] = useState({
    tipo: '',
    loading: false,
    data: [],
    error: false,
    formato: '',
    empresa: ''
  });

  const object_builder = (obj, value_key_name) => {
    if (!obj) return;

    const entries = Object.entries(obj);

    const model = entries.map((item) => ({
      nombre: item[0],
      [value_key_name]: item[1]
    }));

    return model;
  };

  const agrupar_valores_formatos = (data) => {
    const result = [];

    for (const key in data) {
      const type = key; // Obtiene el tipo (Trabajadores o Equipos)
      const items = data[key];

      for (const itemKey in items) {
        const newItem = {
          ...items[itemKey],
          nombre: itemKey,
          tipo: type
        };
        result.push(newItem);
      }
    }

    return result;
  };

  const data_mapper = (data) => {
    const entries = Object.entries(data);

    const build_business = entries.map((business) => ({
      nombre: business[0],
      tipos: object_builder(business[1], 'formatos')
    }));

    const build_formatos = build_business.map((business) => ({
      ...business,
      tipos: business.tipos.map((tipo) => ({
        ...tipo,
        // formatos: agrupar_valores_formatos(tipo.formatos)
        formatos: agrupar_valores_formatos(tipo.formatos)
      }))
    }));

    return build_formatos;
  };

  const getData = async (business) => {
    try {
      setLoading(true);
      setData([]);

      const url = `reporteformatosnoexistentes/${business.item.bus_int_pid}/${business.item.bus_int_id}`;

      const res = await GET(url);

      if (res.status === 404) {
        setLoading(false);
        setData([]);
        return;
      }

      const data = data_mapper(res);

      setData(data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setData([]);
    }
  };

  const get_info = async (data) => {
    try {
      setInfoFormato({
        tipo: '',
        data: [],
        error: false,
        formato: '',
        empresa: '',
        loading: true
      });

      const res = await POST(data, 'reporteformatosnoexistentes');

      const values = Object.entries(res);

      const filtered = values.filter((el) => typeof el[1] === 'object');

      const mapped = filtered
        .map((el) => ({ ...el[1], woh_int_id: el[0] }))
        .sort((a, b) => a.isvalid - b.isvalid);

      const formato = res.formato;
      const empresa = res.proveedor;

      setInfoFormato({
        ...infoFormato,
        tipo: data.type,
        loading: false,
        data: mapped,
        formato,
        empresa
      });
    } catch (err) {
      setInfoFormato({
        tipo: '',
        loading: false,
        data: [],
        error: false,
        formato: '',
        empresa: ''
      });
    }
  };

  const Valores = ({ formato }) => {
    const { for_int_id, typ_int_id, bus_int_id } = formato;
    const trabajador = formato.tipo === 'Trabajadores';
    const valores = `${formato.cumpliendo} / ${formato.asignados}`;

    const onClick = () => {
      setActualFormatIds({
        for_int_tipo: trabajador ? '3' : '4',
        typ_int_id,
        bus_int_id,
        for_int_id
        // woh_int_id: '',
      });

      get_info({
        for_int_id,
        typ_int_id,
        bus_int_id,
        type: trabajador ? 'trabajadores' : 'equipos'
      });
    };

    if (trabajador) {
      return (
        <>
          <td
            onClick={onClick}
            data-bs-toggle="modal"
            data-bs-target="#reporte-formatos-inexistentes-modal"
            className="hover-list-item cursor-pointer"
          >
            {valores}
          </td>
          <td>---</td>
        </>
      );
    }

    return (
      <>
        <td>---</td>
        <td
          onClick={onClick}
          data-bs-toggle="modal"
          data-bs-target="#reporte-formatos-inexistentes-modal"
          className="hover-list-item cursor-pointer"
        >
          {valores}
        </td>
      </>
    );
  };

  return (
    <FullWidthContainer>
      <Modal_info infoFormato={infoFormato} actualFormatIds={actualFormatIds} />

      <h2 className="text-center mb-5">Reporte Supervisor</h2>

      <div className="d-flex align-items-end gap-3 mb-3">
        <Autocomplete
          label="Selecciona una empresa"
          classes="flex-grow-1"
          url="business_admin"
          //son los nombres de los campos de el esquema, de id y de nombre
          valueField="bus_int_id"
          nameField="bus_text_name"
          placeholder="Escoja una opción"
          onChange={(business) => {
            setParentBusiness(null);

            setTimeout(() => {
              setParentBusiness(business);
            }, 500);
          }}
        />

        {parentBusiness && (
          <Autocomplete
            label="Selecciona un proveedor"
            classes="flex-grow-1"
            url="business_admin"
            customParams={[`c=${parentBusiness.value}`]}
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              getData(item);
            }}
          />
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading &&
        data &&
        data.length > 0 &&
        data.map((business) => {
          const tipos = business.tipos;

          return (
            <div className="mb-5" key={random_id()}>
              <h5 className="mb-3 fw-bold border-bottom pb-2">
                {business.nombre}
              </h5>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className="bg-secondary text-white">
                    <th style={{ width: '30%' }} scope="col">
                      Tipo documental
                    </th>
                    <th style={{ width: '30%' }} scope="col">
                      Formato
                    </th>
                    <th style={{ width: '20%' }} scope="col">
                      Trabajadores
                    </th>
                    <th style={{ width: '20%' }} scope="col">
                      Equipo
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tipos.map((tipo, tipo_idx) => {
                    const formatos = tipo.formatos;
                    const formato_cero = formatos[0];
                    const otros_formatos = formatos.filter((_, i) => i !== 0);

                    return (
                      <>
                        <tr>
                          <th rowSpan={formatos.length} scope="rowgroup">
                            {tipo.nombre}
                          </th>

                          <th scope="row">{formato_cero.nombre}</th>
                          <Valores formato={formato_cero} />
                        </tr>

                        {otros_formatos.map((formato) => (
                          <tr key={random_id()}>
                            <th scope="row">{formato.nombre}</th>
                            <Valores formato={formato} />
                          </tr>
                        ))}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
    </FullWidthContainer>
  );
}
