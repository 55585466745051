import FullWidthContainer from 'components/layout/FullWidthContainer';
import { format, lastDayOfMonth, startOfMonth } from 'date-fns';
import { DWNLD_POST_V2, POST } from 'helpers/request';
import { useEffect, useState } from 'react';
import { DefaultInput } from 'templates/input';

export default function ReportClicks() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [dateStart, setDateStart] = useState(
    format(startOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [dateEnd, setDateEnd] = useState(
    format(lastDayOfMonth(new Date()), 'yyyy-MM-dd')
  );

  const getData = async (busId) => {
    setLoading(true);
    setData([]);

    const res = await POST(
      { cual: '19', desde: dateStart, hasta: dateEnd, xls: '0' },
      // { cual: '10', bus_int_id: '353', typ_int_id: '21', xls: '0' },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    if (res[0].valor) {
      setData(res[0].valor);
      setBusiness({ ...res.cab, id: busId });
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const handleDescargar = () => {
    DWNLD_POST_V2(
      'reports',
      `reporte-por-clicks-${business.Empresa}-${business.Fecha}.xlsx`,
      19,
      {
        desde: dateStart,
        hasta: dateEnd,
        xls: 1
      }
    );
  };

  useEffect(() => {
    if (dateStart && dateEnd) {
      getData();
    }

    // eslint-disable-next-line
  }, [dateStart, dateEnd]);

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">Reporte clics por revisor</h2>

      <div className="d-flex align-items-end gap-3 mb-3">
        <div className="w-100 d-flex gap-3 align-items-end">
          <DefaultInput
            label="Desde"
            type="date"
            customClass="flex-grow-1"
            mb="0"
            value={dateStart}
            onChange={({ target }) => {
              setDateStart(target.value);
            }}
          />

          <DefaultInput
            label="Hasta"
            type="date"
            customClass="flex-grow-1"
            mb="0"
            value={dateEnd}
            onChange={({ target }) => {
              setDateEnd(target.value);
            }}
          />
        </div>

        {!loading && data && data.length > 0 && (
          <button
            onClick={handleDescargar}
            type="button"
            className="btn btn-success d-flex align-items-center"
          >
            <i className="fas fa-download me-2"></i>Excel
          </button>
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading && data && data.length > 0 && (
        <table className="table table-striped table-bordered">
          <thead>
            <tr className="bg-secondary text-white">
              <th scope="col">id</th>
              <th scope="col">Revisor</th>
              <th scope="col">Clics</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.use_int_id}</td>
                <td>{item.use_names}</td>
                <td>{item.clics}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FullWidthContainer>
  );
}
