import React from "react";
import { DefaultInput } from "templates/input";

export default function ContactsForm({
  id,
  title,
  handleSubmit,
  handleChange,
  setData,
  loading,
  error,

  con_varchar_email,
  con_varchar_whatsapp,
}) {
  return (
    <div className="w-100 d-flex flex-column align-items-center justify-content-center rounded bg-light">
      <div className="row w-100 mx-0 align-items-center">
        <h3 className="text-center mb-3">
          {id !== "0" ? "Editar contacto" : "Nuevo contacto"} ({" "}
          {title.replace(/-/g, " ")} )
        </h3>

        <DefaultInput
          customClass="col-12"
          label="Correo"
          type="email"
          placeholder="e.j. prefijo@dominio."
          value={con_varchar_email}
          inputName="con_varchar_email"
          onChange={handleChange}
          error={error.con_varchar_email}
        />

        <DefaultInput
          customClass="col-12"
          label="Whatsapp"
          type="number"
          placeholder="e.j. 0123456789"
          value={con_varchar_whatsapp}
          inputName="con_varchar_whatsapp"
          onChange={handleChange}
          error={error.con_varchar_whatsapp}
        />

        <div className="col-12 d-flex justify-content-end">
          <button
            type="button"
            onClick={handleSubmit}
            className={`btn btn-${loading ? "secondary" : "primary"}`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-save me-2"></i>
                Guardar
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
