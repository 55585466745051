import TextEditor from './TextEditor';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useState } from 'react';

import {
  DefaultFile,
  DefaultInput,
  DefaultSelect,
  DefaultTextarea
} from 'templates/input';
import MailsPreviewModal from './MarketingItem/MailsPreviewModal';

export default function NewsletterForm({
  id,
  handleSubmit,
  handleChange,
  loading,
  setData,
  error,
  handleGoBack,
  setFiles,
  response,

  nlt_text_subjet,
  nlt_text_content,
  nlt_text_content_html,
  bus_int_id,
  typ_int_id,
  for_int_id
}) {
  const { reqTokenBase, reqDownload, reqToken } = useRequest();

  const [email, setEmail] = useState('');
  const [newsletterFiles, setNewsletterFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [business, setBusiness] = useState([]);
  const [types, setTypes] = useState([]);
  const [formats, setFormats] = useState([]);
  const [mails, setMails] = useState([]);

  const regex = /([a-zA-Z0-9.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9._-]+)/gi;

  const handleTextEditor = (value) => {
    setData((prevData) => ({ ...prevData, nlt_text_content_html: value }));
  };

  const handleFileChange = ({ target }) => {
    if (target.files.length === 0) return;

    setFiles(target.files);
  };

  const handleSaveAndQueue = () => {
    setData((prevData) => ({ ...prevData, inQueue: true }));
  };

  const handleTestEmail = () => {
    setData((prevData) => ({ ...prevData, email }));
  };

  const getBusiness = async () => {
    const res = await reqToken.get(`business_admin?l=50000&o=0&k=&t=&ft=`);
    setBusiness(
      res.data.datos.map((item) => ({
        value: item.bus_int_id,
        name: item.bus_text_name
      }))
    );
  };

  const handleGetFiles = async () => {
    const res = await reqTokenBase.get(
      `marketing/files_newsletter/${id}?que=2`
    );
    setNewsletterFiles(res.data);
  };

  const handleDeleteFile = async (fileId) => {
    setFilesLoading(true);
    try {
      await reqTokenBase.delete(`marketing/files_newsletter/${fileId}`);

      setNewsletterFiles(
        newsletterFiles.filter((item) => item.fxn_int_id !== fileId)
      );

      setFilesLoading(false);
    } catch (error) {
      console.log(error);
      setFilesLoading(false);
    }
  };

  const handleDownloadFile = async (fileId, title) => {
    setFilesLoading(true);
    try {
      const res = await reqDownload.get(
        `marketing/files_newsletter/${fileId}?que=1`
      );

      const href = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', title); // name with extension
      document.body.appendChild(link);
      link.click();

      setFilesLoading(false);
    } catch (error) {
      console.log(error);
      setFilesLoading(false);
    }
  };

  const getTypes = async () => {
    setTypes([]);
    const res = await reqToken.get(`business_types/${bus_int_id}`);
    setTypes([
      { name: 'Todos', value: '0' },
      ...res.data.datos.map((item) => ({
        name: item.typ_text_name,
        value: item.typ_int_id
      }))
    ]);
  };

  const getFormats = async () => {
    setFormats([]);
    const res = await reqToken.get(
      `formatsxbusiness/${typ_int_id}/${bus_int_id}`
    );
    setFormats([
      { name: 'Todos', value: '0' },
      ...res.data.datos.map((item) => ({
        name: item.for_text_name,
        value: item.for_int_id
      }))
    ]);
  };

  const getMails = async () => {
    const res = await reqTokenBase.get(
      `marketing/newsletter/${id}/whosend/${bus_int_id}/${typ_int_id}/${for_int_id}`
    );
    setMails(res.data);
  };

  useEffect(() => {
    if (!bus_int_id) return;
    getTypes();

    //eslint-disable-next-line
  }, [bus_int_id]);

  useEffect(() => {
    if (!typ_int_id) return;
    getFormats();

    //eslint-disable-next-line
  }, [typ_int_id]);

  useEffect(() => {
    handleGetFiles();
    getBusiness();
    //eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit} className="row w-100 mx-0" autoComplete="off">
      <h3 className="text-center">
        {id !== '0' ? 'Editar newsletter' : 'Nuevo newsletter'}
      </h3>

      <div className="d-flex justify-content-start align-items-center gap-3 pb-4 w-100">
        <button
          className="btn btn-primary btn-sm"
          onClick={handleGoBack}
          type="button"
        >
          <i className="fa fas fa-long-arrow-alt-left me-2"></i> Volver a la
          lista
        </button>
      </div>

      {response && (
        <div className="alert alert-success text-center border p-3">
          Email de prueba enviado
        </div>
      )}

      <MailsPreviewModal mails={mails} />

      <div className="d-flex gap-3 align-items-end w-100 mb-3">
        <DefaultSelect
          // disabled={id !== "0" ? true : false}
          mb="0"
          customClass="flex-grow-1"
          label="Empresa"
          inputName="bus_int_id"
          placeholder="Selecciona una opción"
          onChange={handleChange}
          error={error?.bus_int_id}
          defaultValue={bus_int_id}
          options={business}
        />
        {bus_int_id && types.length > 0 && (
          <DefaultSelect
            // disabled={id !== "0" ? true : false}
            mb="0"
            customClass="flex-grow-1"
            label="Tipo"
            inputName="typ_int_id"
            // placeholder="Selecciona una opción"
            onChange={handleChange}
            error={error?.typ_int_id}
            defaultValue={typ_int_id}
            options={types}
          />
        )}
        {bus_int_id && typ_int_id && formats.length > 0 && (
          <DefaultSelect
            // disabled={id !== "0" ? true : false}
            mb="0"
            customClass="flex-grow-1"
            label="Formato"
            inputName="for_int_id"
            // placeholder="Selecciona una opción"
            onChange={handleChange}
            error={error?.for_int_id}
            defaultValue={for_int_id}
            options={formats}
          />
        )}

        {bus_int_id && id !== '0' && (
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-toggle="modal"
            data-bs-target="#mails-preview-modal"
            onClick={getMails}
          >
            Destinatarios
          </button>
        )}
      </div>

      <DefaultInput
        customClass="col-12"
        label="Tema"
        placeholder="ej. Nuevos productos..."
        value={nlt_text_subjet}
        inputName="nlt_text_subjet"
        onChange={handleChange}
        error={error.nlt_text_subjet}
      />

      <DefaultTextarea
        customClass="col-12"
        label="Contenido ( Texto )"
        placeholder="ej. Nuevos productos..."
        value={nlt_text_content}
        inputName="nlt_text_content"
        onChange={handleChange}
        error={error.nlt_text_content}
      />

      <div className="col-12 mb-3">
        <label className="mb-2 fw-bold">Contenido *No usar modo obscuro mientras edita</label>
        <TextEditor
          height="600"
          onChange={handleTextEditor}
          defaultContent={nlt_text_content_html}
        />
      </div>

      <DefaultFile
        label="Archivos"
        customClass="col-12"
        onChange={handleFileChange}
        multiple
      />

      {newsletterFiles.length > 0 && (
        <>
          <label className="mb-2">Archivos previos</label>
          <div className="d-flex flex-column border p-2 col-12 col-md-10 offset-md-1">
            <ul className="list-group list-group-flush">
              {newsletterFiles.map((item) => (
                <li
                  key={item.fxn_int_id}
                  className="list-group-item bg-transparent d-flex align-items-center"
                >
                  <span className="text-truncate flex-grow-1">
                    {item.fxn_text_file}
                  </span>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm ms-auto me-2"
                    onClick={() =>
                      handleDownloadFile(item.fxn_int_id, item.fxn_text_file)
                    }
                    disabled={filesLoading ? true : false}
                  >
                    <i className="fas fa-download"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm ms-auto"
                    onClick={() => handleDeleteFile(item.fxn_int_id)}
                    disabled={filesLoading ? true : false}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}

      <div className="col-12 d-flex align-items-end mt-3">
        <DefaultInput
          customClass="flex-grow-1"
          placeholder="Email de prueba"
          type="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          mb="0"
          required={false}
        />

        <button
          onClick={handleTestEmail}
          type="submit"
          className={`btn btn-secondary mx-3`}
          disabled={loading ? true : regex.test(email) ? false : true}
        >
          {loading ? (
            <>
              <i className="fas fa-circle-notch fa-spin me-2"></i>
              Guardando...
            </>
          ) : (
            <>
              <i className="fas fa-mail-bulk me-2"></i>
              Probar email
            </>
          )}
        </button>

        {id !== '0' && (
          <button
            onClick={handleSaveAndQueue}
            type="submit"
            className={`btn btn-${loading ? 'secondary' : 'warning'} me-3`}
            disabled={loading ? true : false}
          >
            {loading ? (
              <>
                <i className="fas fa-circle-notch fa-spin me-2"></i>
                Guardando...
              </>
            ) : (
              <>
                <i className="fas fa-mail-bulk me-2"></i>
                Guardar y poner en cola
              </>
            )}
          </button>
        )}

        <button
          type="submit"
          className={`btn btn-${loading ? 'secondary' : 'primary'}`}
          disabled={loading ? true : false}
        >
          {loading ? (
            <>
              <i className="fas fa-circle-notch fa-spin me-2"></i>
              Guardando...
            </>
          ) : (
            <>
              <i className="fas fa-save me-2"></i>
              Guardar
            </>
          )}
        </button>
      </div>
    </form>
  );
}
