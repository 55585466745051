import React from "react";

//prettier-ignore
export default function SituDocItemRow({ item, onClick, id, idField }) {
  const infoDataArr = item.situaciondocumental.replace(/[()]/g, "").split(",");

  return (
    <div className="w-100 border row mx-0">
      <span className="fw-bold text-center col-1 py-3 border-end">
        {item.cat_int_tipo === "1" && (
          <i
            className="fas fa-book"
            style={{ pointerEvents: "none" }}
          ></i>
        )}

        {item.cat_int_tipo === "2" && (
          <i className="far fa-building" style={{ pointerEvents: "none" }}></i>
        )}

        {item.cat_int_tipo === "3" && (
          <i
            className="fas fa-user-friends"
            style={{ pointerEvents: "none" }}
          ></i>
        )}

        {item.cat_int_tipo === "4" && (
          <i
            className="fas fa-tools"
            style={{ pointerEvents: "none" }}
          ></i>
        )}
      </span>
      <span className="fw-bold text-center col-5 py-3 border-end flex-grow-1 word-break sit-doc-item-row" onClick={() => onClick(item.cat_int_id, item[idField])} style={{ cursor: "pointer" }} title={`b${item.bus_int_id} t${item[idField]} f${item.for_int_id} c${item.cat_int_id}`}>{item.cat_text_name}</span>
      <span className={`fw-bold text-center col-1 py-3 text-truncate border-end ${infoDataArr[0]==="0" ? 'text-danger':'text-secondary'} col-cat-${id}-pos-0`}>{infoDataArr[0]}</span>
      <span className={`fw-bold text-center col-1 py-3 text-truncate border-end ${infoDataArr[1]==="0" ? 'text-secondary':'text-danger'} col-cat-${id}-pos-1`}>{infoDataArr[1]}</span>
      <span className={`fw-bold text-center col-1 py-3 text-truncate border-end ${infoDataArr[2]==="0" ? 'text-muted':'text-danger'} col-cat-${id}-pos-2`}>{infoDataArr[2]}</span>
      <span className={`fw-bold text-center col-1 py-3 text-truncate border-end ${infoDataArr[3]==="0" ? 'text-secondary':'text-success'} col-cat-${id}-pos-3`}>{infoDataArr[3]}</span>
      <span className={`fw-bold text-center col-1 py-3 text-truncate border-end text-secondary col-cat-${id}-pos-4`}>{infoDataArr[4]}</span>
      <span className={`fw-bold text-center col-1 py-3 text-truncate col-cat-${id}-pos-5`}>{infoDataArr[5]}</span>
    </div>
  );
}
