import React from 'react';

export default function DeleteModal({
  customId,
  title,
  id,
  deleteFunction,
  erreasing,
  homeType
}) {
  return (
    <div
      className="modal fade"
      id={customId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5
              className="modal-title text-danger"
              id={`business-modal-delete-${id}-label`}
            >
              <i className="fas fa-exclamation-triangle me-2"></i>
              Eliminar relación
            </h5>
            <button
              type="button"
              className="btn-close-modal btn text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body py-5">
            <p className="text-center h5 text-danger pb-2">
              Estás por eliminar el siguiente elemento
            </p>

            <ul className="list-group list-group-flush w-100">
              <li className="list-group-item bg-light">
                <div className="row mx-0">
                  <span className="col-4 fw-bold text-start">ID</span>
                  <span className="col-8 text-start">{id}</span>
                </div>
              </li>

              <li className="list-group-item bg-light">
                <div className="row mx-0">
                  <span className="col-4 fw-bold text-start">Nombre</span>
                  <span className="col-8 text-start">{title}</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              disabled={erreasing ? true : false}
            >
              <i className="fas fa-times me-2"></i>
              Cancelar
            </button>
            <button
              onClick={deleteFunction}
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              disabled={erreasing ? true : false}
            >
              <i className="far fa-trash-alt me-2"></i>
              Borrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
