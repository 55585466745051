import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import titleSelector from './tables/titleSelector';
import FullWidthContainer from 'components/layout/FullWidthContainer';
import useScrollContainer from 'hooks/useScrollContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import MarketingListItem from './MarketingItem/MarketingListItem';
import QueueListItem from './MarketingItem/QueueListItem';
import ConfirmEmail from './MarketingItem/ConfirmEmail';
import { DefaultInput } from 'templates/input';
import useRequest from 'hooks/useRequest';

export default function ModulePageMkt() {
  const { module, id, title } = useParams();
  const history = useHistory();
  const { reqTokenBase } = useRequest();

  const [processEmail, setProcessEmail] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    email: '',
    itemId: '',
    files: null,
    onlyView: true
  });

  const urlBuilder = (module) => {
    if (module === 'queue') {
      return `/marketing/newsletter/${id}/towork`;
    }
    if (module === 'sent') {
      return `/marketing/newsletter/${id}/sent`;
    }
    return '/marketing/newsletter';
  };

  const {
    clearPrevData,
    loading,
    data,
    error,
    keyword,
    setKeyword,
    hasMore,
    erreasing,
    edit,
    getFilter,
    handleScroll,
    handleDelete,
    handleRestore,
    handleHardDelete
  } = useScrollContainer({
    type: module,
    url: urlBuilder(module),
    deleteUrl: '/marketing/newsletter'
  });

  const handleEmailsQueue = async () => {
    setProcessEmail(true);
    await reqTokenBase.get('Enviocorreos');
    setProcessEmail(false);
  };

  const handleGoBack = () => {
    history.replace(`/marketing/newsletter`);
  };

  const handleNew = () => {
    history.replace(`/marketing/newsletter/0`);
  };

  return (
    <FullWidthContainer>
      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
        <h2 className="text-center mb-3">
          {titleSelector[module]}
          {title ? ` para ${title.replace(/-/g, ' ')}` : ''}
        </h2>

        <form
          onSubmit={getFilter}
          className="d-none d-md-flex w-100 align-items-end gap-3 mb-4"
          autoComplete="off"
        >
          {(module === 'queue' || module === 'sent') && (
            <button
              className="btn btn-secondary"
              onClick={handleGoBack}
              type="button"
              title="Volver atrás"
            >
              <i className="fa fas fa-caret-left me-2 fa-lg"></i>
              Volver atrás
            </button>
          )}

          <DefaultInput
            customClass="flex-grow-1"
            onChange={({ target }) => setKeyword(target.value)}
            value={keyword}
            placeholder="Ingresa palabras clave"
            mb="0"
            required={false}
          />

          <button type="submit" className="btn btn-primary">
            Buscar
            <i className="fas fa-search ms-2"></i>
          </button>

          {module !== 'queue' && (
            <button
              onClick={handleNew}
              type="button"
              className="btn btn-success"
            >
              Agregar
              <i className="fas fa-plus ms-2"></i>
            </button>
          )}

          {module === 'newsletter' && (
            <button
              onClick={handleEmailsQueue}
              type="button"
              className="btn btn-warning"
              disabled={processEmail ? true : false}
              title="Procesar cola de emails"
            >
              <i className="far fa-paper-plane me-2"></i>
              Procesar emails
            </button>
          )}
        </form>

        {module === 'newsletter' && (
          <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
            <span className="col-3 col-md-1 fw-bold border-end py-2 text-center text-white">
              ID
            </span>
            <span className="col-9 col-md-7 fw-bold border-end py-2 text-white">
              Nombre
            </span>
            <span className="d-none d-md-block col-4 col-md-4 fw-bold text-center py-2 text-white">
              Opciones
            </span>
          </div>
        )}

        {module !== 'newsletter' && (
          <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-3">
            <span className="col-3 col-md-1 fw-bold border-end py-2 text-center text-white">
              ID
            </span>
            <span className="col-9 col-md-4 fw-bold border-end py-2 text-white">
              Nombre
            </span>
            <span className="col-9 col-md-5 fw-bold border-end py-2 text-white">
              Destinatario
            </span>
            <span className="d-none d-md-block col-4 col-md-2 fw-bold text-center py-2 text-white">
              Opciones
            </span>
          </div>
        )}

        {error && (
          <div className="alert alert-danger text-center p-3">
            Un error ha ocurrido
          </div>
        )}

        {!error && !loading ? (
          <div className="w-100">
            <InfiniteScroll
              className="autocomplete-list"
              dataLength={data.length}
              next={handleScroll}
              // scrollThreshold={0.9}
              hasMore={hasMore}
              loader={
                data.length > 0 ? (
                  <p className="text-center w-100 py-3 mb-0 border">
                    Cargando...
                  </p>
                ) : (
                  <p className="text-center w-100 py-3 mb-0 border">
                    Sin datos
                  </p>
                )
              }
              height={data.length >= 10 ? 510 : 'auto'}
              endMessage={
                <p className="text-center w-100 mb-0 py-3 border">
                  No hay más datos
                </p>
              }
            >
              {module === 'newsletter'
                ? data.map((item) => (
                    // <div className="w-100">{item.name}</div>
                    <MarketingListItem
                      clearPrevData={clearPrevData}
                      key={item.id}
                      type={module}
                      id={item.id}
                      title={item.name}
                      item={item.item}
                      deleted={item.deleted}
                      erreasing={erreasing}
                      edit={edit}
                      setModalInfo={setModalInfo}
                      handleDelete={handleDelete}
                      handleRestore={handleRestore}
                      handleHardDelete={handleHardDelete}
                    />
                  ))
                : data.map((item) => (
                    // <div className="w-100">{item.name}</div>
                    <QueueListItem
                      key={item.id}
                      type={module}
                      id={item.id}
                      title={item.name}
                      item={item.item}
                      deleted={item.deleted}
                      erreasing={erreasing}
                      edit={edit}
                      setModalInfo={setModalInfo}
                      handleDelete={handleDelete}
                      handleRestore={handleRestore}
                      handleHardDelete={handleHardDelete}
                    />
                  ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div className="border w-100 text-center py-3">Cargando...</div>
        )}

        <ConfirmEmail customId="list-item-email-view" modalInfo={modalInfo} />
      </div>
    </FullWidthContainer>
  );
}
