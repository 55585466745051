// import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { useForm } from 'hooks/useForm';
import { useHistory } from 'react-router';
import { adminUrl, headerToken } from 'api/api';

import useRequest from 'hooks/useRequest';
import useAuditorEdit from 'hooks/useAuditorEdit';

import RelationEditionList from 'components/EditionGroups/RelationEditionList';

import AlertMessage from 'components/layout/AlertMessage';
import TabsButtons from 'components/BusinessForm/TabsButtons';
import DataInputs from 'components/BusinessForm/DataInputs';
import CRUDPageById from 'pages/CRUDPageById';
import PrivateRoute from 'routes/PrivateRoute';
import ModuleFormPageById from 'pages/ModuleFormPageById';
import ClassificationModal from 'components/BusinessForm/ClassificationModal';

// const regex = /^@\w[\w.-]+\.[\w.-]*[a-z]$/i;

const defaultValues = {
  bus_text_name: '',
  bus_text_identification: '',
  bus_text_address: '',
  bus_text_phone: '',
  bus_text_domain: '',

  use_text_name: '',
  use_text_lastname: '',
  use_text_phone: '',
  use_text_address: '',
  use_int_admin: '0'
};

const moduleUrl = {
  business_admin: 'administration/business_admin',
  proveedores: 'empresa/proveedores'
};

export default function BusinessForm({
  module,
  id,
  data,
  title,
  handleGoBack
}) {
  const { reqTokenBase } = useRequest();
  const { handleAuditor, auditorEdited } = useAuditorEdit(id);
  const [prevAuditor, setPrevAuditor] = useState(null);
  const { path } = useRouteMatch();
  const location = useLocation();

  const [values, handleInputChange, _, setValues] = useForm(
    data || defaultValues
  );
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [tab, setTab] = useState(1);

  const [reqResData, setReqResData] = useState(null);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const regexDomain = /^@\w[\w.-]/i;
    const regexUser = /^[\w.-]*$/gi;

    setLoading(true);
    setError(defaultValues);

    let res;

    try {
      if (id === '0') {
        if (!regexDomain.test(values.bus_text_domain)) {
          setError({
            ...errorData,
            bus_text_domain:
              "La estructura del dominio debe ser '@dominio', no puedes ingresar caracteres especiales o espacios, solo caracteres alfa-numéricos, guiones y puntos"
          });

          setLoading(false);
          return;
        }

        if (!regexUser.test(values.use_text_user)) {
          setError({
            ...errorData,
            use_text_user:
              'No puedes ingresar caracteres especiales o espacios, solo caracteres alfa-numéricos, guiones y puntos'
          });

          setLoading(false);
          return;
        }

        if (!reqResData) {
          res = await reqTokenBase.post(`${moduleUrl[module]}`, values);
          setReqResData(res.data);
        }

        await reqTokenBase.post('administration/users', {
          ...values,
          use_text_user:
            values.use_text_user.replace(/\s/g, '') + values.bus_text_domain,
          bus_int_id: res ? res.data.id : reqResData.id
        });

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setSuccess(201);
      }

      //prettier-ignore
      if (id !== "0") {
        await reqTokenBase.put(`${moduleUrl[module]}/${id}`, {
          bus_text_name: values.bus_text_name,
          bus_text_identification: values.bus_text_identification,
          bus_text_address: values.bus_text_address,
          bus_text_phone: values.bus_text_phone,
          bus_text_domain: values.bus_text_domain,
          bus_int_notgar: values.bus_int_notgar
        });
        
        window.scrollTo({ top: 0, behavior: "smooth" });
        setSuccess(201)
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(error.response?.data.messages);
    }
  };

  const handleTab = ({ target }) => {
    setTab(Number(target.value));
  };

  useEffect(() => {
    (async function () {
      const prevAud = await adminUrl.get(
        `business_auditors/${id}`,
        headerToken()
      );

      if (prevAud.data.aud_int_id) {
        setPrevAuditor(prevAud.data.aud_int_id);
      } else {
        setPrevAuditor(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.goBack();
      }, 3000);
    }

    //eslint-disable-next-line
  }, [success]);

  useEffect(() => {
    if (location.pathname.includes('info')) {
      setTab(4);
    } else {
      setTab(3);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <div className="row w-100 mx-0 p-3 p-xl-5">
      {id !== '0' ? (
        <h2 className="w-100 text-center mb-3">
          Editar empresa ( {title.replace(/-/g, ' ')} )
        </h2>
      ) : (
        <h2 className="w-100 text-center mb-3">Nueva empresa</h2>
      )}

      <div className="d-flex justify-content-between align-items-center pb-3 gap-3">
        {/* <button
          className="btn btn-secondary me-auto btn-sm"
          onClick={handleGoBack}
          type="button"
        >
          <i className="fa fas fa-caret-left fa-lg me-2"></i> Volver a la lista
        </button> */}

        {id !== '0' && (
          <TabsButtons
            tab={tab}
            handleTab={handleTab}
            id={id}
            title={title}
            setTab={setTab}
            busIntPid={data?.bus_int_pid}
          />
        )}
      </div>

      <hr className="my-3" />

      {tab === 1 && (
        <form
          onSubmit={handleSubmit}
          className="row w-100 mx-0"
          autoComplete="off"
        >
          {success && <AlertMessage status={success} id={id} />}

          <DataInputs
            {...values}
            id={id}
            module={module}
            handleInputChange={handleInputChange}
            setValues={setValues}
            // handleValidationChange={handleValidationChange}
            errorData={errorData}
            prevAuditor={prevAuditor}
            auditorEdited={auditorEdited}
            handleAuditor={handleAuditor}
          />

          <ClassificationModal busId={id} busName={title?.replace(/-/g, ' ')} />

          <div className="d-flex align-items-center justify-content-between mt-4">
            {!loading && id !== '0' && (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-toggle="modal"
                data-bs-target="#classification_modal"
              >
                Clasificación (Empresa)
                <i className="fas fa-list ms-2"></i>
              </button>
            )}

            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading ? true : false}
            >
              {loading ? (
                <>
                  <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
                </>
              ) : id !== '0' ? (
                <>
                  Editar
                  <i className="fas fa-save ms-2"></i>
                </>
              ) : (
                <>
                  Guardar
                  <i className="fas fa-save ms-2"></i>
                </>
              )}
            </button>
          </div>
        </form>
      )}

      {tab === 3 && (
        <RelationEditionList width="100%" groupFor="business" id={id} />
      )}

      {tab === 4 && (
        <Switch>
          <PrivateRoute
            exact
            path={`${path}/info/:module`}
            component={CRUDPageById}
          />
          <PrivateRoute
            exact
            path={`${path}/info/:module/:itemId`}
            component={ModuleFormPageById}
          />
        </Switch>
      )}
    </div>
  );
}
