import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import useRequest from 'hooks/useRequest';

import { DELETE } from 'helpers/request';

import titleSelector from 'helpers/titleSelector';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import InfiniteScroll from 'react-infinite-scroll-component';

import '../styles/CRUDPage.css';
import ListItem from 'components/ListItem';

export default function CRUDPageV2() {
  const history = useHistory();
  const { crudModule, busId, busName } = useParams();
  const Title = titleSelector(crudModule);
  const { reqToken } = useRequest();

  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const [keyword, setKeyword] = useState('');
  const [index, setIndex] = useState(20);

  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const clearPrevData = () => {
    setData([]);
    setKeyword('');
    setIndex(20);
    setHasMore(true);
    setLoading(true);
  };

  const mapFn = (arr) => {
    let localArr = arr;

    let mapped = [];

    const deletedKeysTable = {
      access: 'acc_date_deletion_date',
      business_admin: 'bus_date_deletion_date',
      blacklist: 'bla_date_deletion_date',
      planes: 'pla_date_deletion_date'
    };

    const nameKeysTable = {
      access: 'acc_text_description',
      business_admin: 'bus_text_name',
      blacklist: 'bla_text_id',
      planes: 'pla_text_nombre'
    };

    const idKeysTable = {
      access: 'acc_int_id',
      business_admin: 'bus_int_id',
      blacklist: 'bla_int_id',
      planes: 'pla_int_id'
    };

    if (crudModule === 'users') {
      mapped = localArr.map((item) => ({
        label: item.use_text_name + ' ' + item.use_text_lastname,
        value: item.use_int_id,
        deleted: item.use_date_deletion_date ? true : false
      }));

      return mapped;
    }

    mapped = localArr.map((item) => ({
      label: item[nameKeysTable[crudModule]],
      value: item[idKeysTable[crudModule]],
      deleted: item[deletedKeysTable[crudModule]] ? true : false,
      item: item
    }));

    return mapped;
  };

  // funcion que trae la data
  const getInitialData = async () => {
    try {
      clearPrevData();

      const res = await reqToken.get(`${crudModule}?l=20&o=0&b=${busId || ''}`);

      setData(mapFn(res.data.datos));

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const getFilter = (e) => {
    e.preventDefault();

    setData([]);
    setIndex(20);
    setHasMore(false);
    setLoading(true);

    setTimeout(async () => {
      const res = await reqToken.get(
        `${crudModule}?l=20&o=0&k=${keyword}&b=${busId || ''}`
      );

      setData(mapFn(res.data.datos));

      setLoading(false);

      setHasMore(true);
    }, 1000);
  };

  // trae mas data si el scroll llega hasta el fondo
  const handleScroll = async () => {
    const res = await reqToken.get(
      `${crudModule}?l=20&o=${index}&k=${keyword}&b=${busId || ''}`
    );

    if (res.data.datos.length === 0) {
      setHasMore(false);
      return;
    }

    setData([...data, ...mapFn(res.data.datos)]);
    setIndex(index + 20);

    if (res.data.datos.length < 20) {
      setHasMore(false);
    }
  };

  // establece la data sin el dato eliminado
  const handleDelete = async (id) => {
    await DELETE(crudModule === 'business_admin' ? 'business' : crudModule, id);

    // if (crudModule === 'business_admin') return;

    // !response.error && setData(data.filter((item) => item.value !== id));
  };

  // establece la data sin el dato eliminado
  const handleHardDelete = async (id) => {
    try {
      await reqToken.delete(
        `${
          crudModule === 'business_admin' ? 'business' : crudModule
        }/${id}/remove`
      );
      setData(data.filter((item) => item.value !== id));
    } catch (error) {
      console.log(error);
    }
  };

  // redirige a la pag para crear un nuevo doc segun el modulo
  const handleAddRedirect = () => {
    history.push(`/module/${crudModule}/0${busId ? `/${busId}` : ''}`);
  };

  useEffect(() => {
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crudModule]);

  return (
    <FullWidthContainer>
      <h2 className="mb-5 text-center">
        {Title}
        {busName ? ` de ${busName.replace(/-/g, ' ')}` : ''}
      </h2>

      <div className="w-100 mb-5 row mx-0">
        <form
          onSubmit={getFilter}
          className="d-flex align-items-end gap-2 flex-wrap justify-content-center"
          autoComplete="off"
        >
          <div className="flex-grow-1">
            <input
              onChange={({ target }) => setKeyword(target.value)}
              value={keyword}
              type="text"
              className="form-control input-bg-dark"
              id="Buscar"
              autoComplete="off"
              placeholder="Ingresa una palabra clave..."
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary d-flex align-items-center gap-2"
          >
            Buscar
            <i className="fas fa-search"></i>
          </button>

          <button
            onClick={handleAddRedirect}
            type="button"
            className="btn btn-success d-flex align-items-center gap-2"
          >
            Agregar
            <i className="fas fa-plus"></i>
          </button>
        </form>
      </div>

      <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-2">
        <div className="col-2 col-md-1 py-2 text-white border-end fw-bold text-center">
          ID
        </div>
        <div className="col-6 col-md-8 py-2 text-white border-end fw-bold">
          Nombre
        </div>
        <div className="col-4 col-md-3 py-2 text-white fw-bold text-center">
          Opciones
        </div>
      </div>

      {error && (
        <div className="alert alert-danger text-center p-3">
          Un error ha ocurrido
        </div>
      )}

      {!error && !loading ? (
        <InfiniteScroll
          className="autocomplete-list"
          dataLength={data.length}
          next={handleScroll}
          // scrollThreshold={0.9}
          hasMore={hasMore}
          loader={
            data.length > 0 ? (
              <p className="text-center w-100 py-3 mb-0 border">Cargando...</p>
            ) : (
              <p className="text-center w-100 py-3 mb-0 border">Sin datos</p>
            )
          }
          height={data.length >= 10 ? 510 : 'auto'}
          endMessage={
            <p className="text-center w-100 mb-0 py-3 border">
              No hay más datos
            </p>
          }
        >
          {data.map((item) => (
            // <div className="w-100">{item.label}</div>
            <ListItem
              key={item.value}
              module={crudModule}
              id={item.value}
              title={item.label}
              item={item.item}
              deleted={item.deleted}
              handleDelete={handleDelete}
              handleHardDelete={handleHardDelete}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <div className="border w-100 text-center py-3">Cargando...</div>
      )}
    </FullWidthContainer>
  );
}
