import React from "react";

export default function AlertMessage({ status, id }) {
  if (status === 201) {
    return (
      <div className="alert alert-success w-100 mb-4 text-center" role="alert">
        {id !== "0" ? "Se actualizó con éxito" : "Se creó con éxito"}
      </div>
    );
  }

  return (
    <div className="alert alert-danger w-100 mb-4 text-center" role="alert">
      Un error ha ocurrido, intentalo más tarde !
    </div>
  );
}
