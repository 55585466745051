const table = {
  contacts_mkt: "/marketing",
  newsletter: "/marketing",
  queue: "/marketing/newsletter",
  sent: "/marketing/newsletter",
};

export default function redirectTable(module) {
  return table[module];
}
