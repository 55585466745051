export const Modal_info = ({ logs, loading }) => {
  return (
    <div
      className="modal fade"
      id="report-docs-lectura-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="report-docs-lectura-modal-label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down modal-dialog-scrollable">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5
              className="modal-title text-uppercase"
              id="report-docs-lectura-modal-label"
            >
              Bitácora de documentos de lectura
            </h5>
            <button
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body d-flex flex-column">
            {loading && <p>Cargando...</p>}

            {!loading && (
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Usuario</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {logs?.map((log) => (
                    <tr>
                      <th scope="row">{log.rlg_int_id}</th>
                      <td>{log.usuario}</td>
                      <td>{log.rlg_date_creation_date}</td>
                      <td>{log.rlg_text_action}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
