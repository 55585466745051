import React, { useState } from 'react';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { POST } from 'helpers/request';
import { Link } from 'react-router-dom';
import Autocomplete from 'components/select-search/Autocomplete';

export default function ReportSituDoc() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [busId, setBusId] = useState('');
  const [parentId, setParentId] = useState('');

  const getData = async (busId) => {
    setBusId(busId);
    setLoading(true);
    setData([]);

    const res = await POST(
      { cual: '5', bus_int_pid: busId },
      'reports',
      null,
      null
    );

    if (res.valor) {
      setData(res.valor);
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">Reporte situación documental</h2>

      <div className="d-flex w-100 gap-2 mb-3">
        <Autocomplete
          label="Selecciona una empresa"
          classes="flex-grow-1"
          url="business_admin"
          //son los nombres de los campos de el esquema, de id y de nombre
          valueField="bus_int_id"
          nameField="bus_text_name"
          placeholder="Escoja una opción"
          onChange={(item) => {
            getData(item.value);
            setParentId('');

            setTimeout(() => {
              setParentId(item.value);
            }, 100);
          }}
        />

        {parentId && parentId !== '0' && (
          <div className="col-12 col-lg-4">
            <Autocomplete
              label="Selecciona un proveedor"
              classes="flex-grow-1"
              url="business_admin"
              customParams={[`c=${parentId}`]}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="bus_int_id"
              nameField="bus_text_name"
              placeholder="Escoja una opción"
              onChange={(item) => {
                getData(item.value);
              }}
            />
          </div>
        )}
      </div>

      <div
        className="w-100 px-3 bg-secondary text-white d-flex"
        style={{ borderRadius: '3px 3px 0 0' }}
      >
        <p className="mb-0 w-50 text-start fw-bold text-truncate border-end py-3">
          Proveedor
        </p>
        <p className="mb-0 w-50 text-center fw-bold text-truncate py-3">
          Estado
        </p>
      </div>

      {busId && loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {busId && !loading && data.length === 0 && (
        <div className="alert alert-warning text-center boder py-3 mt-3">
          La empresa no tiene proveedores
        </div>
      )}

      {busId &&
        data?.map((item) => (
          <div key={item.bus_int_id} className="border d-flex px-3">
            <div className="w-50 py-3 border-end pe-3 d-flex align-items-center">
              <Link
                to={`/situacionDocumental/${item.bus_int_id}/0/0`}
                className="mb-0 w-100 text-center word-break fw-bold sit-doc-item-row py-2 text-decoration-none text-darkmode"
              >
                {item.bus_text_name}
              </Link>
            </div>
            <div className="w-50 py-3 ps-3 d-flex flex-column">
              <div className="row mx-0 mb-2 w-100 border-bottom pb-2">
                <span className="col-6 fw-bold">Válidos :</span>
                <span className="col-6 text-center">
                  {item.valores[0].out_validos || '-'}
                </span>
              </div>
              <div className="row mx-0 mb-2 w-100 border-bottom pb-2">
                <span className="col-6 fw-bold">Caducados :</span>
                <span className="col-6 text-center">
                  {item.valores[0].out_caducados || '-'}
                </span>
              </div>
              <div className="row mx-0 mb-2 w-100 border-bottom pb-2">
                <span className="col-6 fw-bold">Inexistentes :</span>
                <span className="col-6 text-center">
                  {item.valores[0].out_inexistente || '-'}
                </span>
              </div>
              <div className="row mx-0 mb-2 w-100 border-bottom pb-2">
                <span className="col-6 fw-bold">Pendientes :</span>
                <span className="col-6 text-center">
                  {item.valores[0].out_pendientes || '-'}
                </span>
              </div>
              <div className="row mx-0 mb-2 w-100 border-bottom pb-2">
                <span className="col-6 fw-bold">Inválidos :</span>
                <span className="col-6 text-center">
                  {item.valores[0].out_invalidos || '-'}
                </span>
              </div>
              <div className="row mx-0 mb-2 w-100">
                <span className="col-6 fw-bold">Total :</span>
                <span className="col-6 text-center">
                  {item.valores[0].out_total || '-'}
                </span>
              </div>
            </div>
          </div>
        ))}
    </FullWidthContainer>
  );
}
