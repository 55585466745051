import React, { useState } from 'react';
import useRelations from 'hooks/useRelations';

import ListItem from './ListItem';
import FormatsRelations from './FormatsRelations';

import { titleKeySelector } from './keysSelector';

import TypesFormByBusiness from 'components/Forms/TypesFormByBusiness';
import FormatFormByBusiness from 'components/Forms/FormatFormByBusiness';

import '../../styles/CRUDPage.css';

export default function GrpAccEditionGroups({ groupFor, id, width }) {
  const {
    loading,
    data,
    assigning,
    handleEditGroup,
    getData,
    erreasing,
    handleDelete,
    handleRestore,
    handleHardDelete,
    handleFilter
  } = useRelations(groupFor, id);

  const [showFormats, setShowFormats] = useState({
    businessId: '',
    typeId: '',
    typeTitle: '',
    show: false
  });

  const [currentType, setCurrentType] = useState('');
  const [step, setStep] = useState(0);
  const [info, setInfo] = useState({
    id: '',
    name: ''
  });

  const handleNew = () => {
    setInfo({ id: '0', name: '' });
    setStep(1);
  };

  const handleNewFormat = () => {
    setInfo({ id: '0', name: '' });
    setStep(2);
  };

  const handleGoBack = () => {
    setInfo({ id: '', name: '' });
    setStep(0);
  };

  const handleComplete = () => {
    setInfo({ id: '', name: '' });
    setStep(0);
    getData();
  };

  if (showFormats.show && step < 2) {
    return (
      <FormatsRelations
        setShowFormats={setShowFormats}
        businessId={showFormats.businessId}
        typeId={showFormats.typeId}
        typeTitle={showFormats.typeTitle}
        handleNewFormat={handleNewFormat}
        setStep={setStep}
        setInfo={setInfo}
        setCurrentType={setCurrentType}
      />
    );
  }

  return (
    <div className={`${width ? width : 'col-5'}`}>
      <div className="d-flex align-items-center gap-3 mb-3">
        <h5 className="text-center bg-secondary text-white py-2 rounded fw-normal flex-grow-1 mb-0">
          {info.id
            ? info.id === '0'
              ? `Nuevo ${step === 1 ? 'tipo' : 'formato'} ${
                  step === 2 ? `en ${currentType.name}` : ''
                }`
              : `Editar ${step === 1 ? 'tipo' : 'formato'} [ ${info.name} ] ${
                  step === 2 ? `en [ ${currentType.name} ]` : ''
                }`
            : titleKeySelector(groupFor)}
        </h5>
        {!info.id && groupFor === 'business' && (
          <button onClick={handleNew} type="button" className="btn btn-success">
            Crear <i className="fas fa-plus ms-2"></i>
          </button>
        )}
        {info.id && (
          <button
            onClick={handleGoBack}
            type="button"
            className="btn btn-danger"
          >
            Cancelar <i className="fas fa-times ms-2"></i>
          </button>
        )}
      </div>

      {step === 0 && (
        <>
          <div className="row w-100 mx-0 px-0 rounded bg-secondary mb-2">
            <div className="col-2 fw-bold text-white py-2 border-end text-center text-truncate">
              ID
            </div>
            <div className="col-6 fw-bold text-white py-2 border-end text-truncate">
              Nombre
            </div>
            <div className="col-4 fw-bold text-white py-2 text-center text-truncate">
              Opciones
            </div>
          </div>

          {!loading && (
            <input
              type="text"
              className="form-control w-100 mb-2 input-bg-dark"
              placeholder="Buscar..."
              onChange={({ target }) => handleFilter(target.value)}
            />
          )}

          {loading && (
            <div className="pt-5 pb-2 d-flex align-items-center justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {!loading && (
            <div className="items-list-container border-bottom">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <ListItem
                    key={`rel_list_item_${index}`}
                    type={groupFor}
                    data={item}
                    edit={handleEditGroup}
                    assigning={assigning}
                    setShowFormats={setShowFormats}
                    id={item.typ_int_id}
                    busId={id}
                    setStep={setStep}
                    setInfo={setInfo}
                    erreasing={erreasing}
                    handleDelete={handleDelete}
                    handleRestore={handleRestore}
                    handleHardDelete={handleHardDelete}
                  />
                ))
              ) : (
                <div className="alert alert-warning text-center border p-3 mb-0">
                  No hay datos
                </div>
              )}
            </div>
          )}
        </>
      )}

      {step === 1 && (
        <TypesFormByBusiness
          id={info.id}
          name={info.name}
          busId={id}
          handleComplete={handleComplete}
        />
      )}

      {step === 2 && (
        <FormatFormByBusiness
          id={info.id}
          name={info.name}
          typeId={currentType.id}
          typeTitle={currentType.name}
          handleComplete={handleComplete}
        />
      )}
    </div>
  );
}
