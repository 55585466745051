export default function MailsPreviewModal({ mails }) {
  return (
    <div
      className="modal fade"
      id="mails-preview-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="queueModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content bg-light">
          <div className="modal-header bg-light">
            <h5 className="modal-title" id="mails-preview-modal-label">
              El correo se enviará a:
            </h5>
            <button
              type="button"
              className="btn-close-modal btn text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body py-5">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Empresa</th>
                  <th scope="col">Usuario</th>
                </tr>
              </thead>
              <tbody>
                {mails?.map((item, index) => (
                  <tr>
                    <th scope="row">{index}</th>
                    <td>{item.bus_text_name}</td>
                    <td>{item.use_text_user}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="modal-footer bg-light">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              <i className="fas fa-times me-2"></i>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
