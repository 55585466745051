import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import InputCheck from 'inputs/InputCheck';
import InputText from 'inputs/InputText';
import ConfirmModal from 'components/CargaMasiva/ConfirmModal';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';

const addDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);

  const parsed = copy.toISOString();

  return parsed.split('T')[0];
};

export default function CargaMavisaForm({
  currentItem,
  setStep,
  getInitialData
}) {
  const { reqTokenBase, reqTokenBaseJson } = useRequest();

  const [filePreview, setFilePreview] = useState();
  const [date, setDate] = useState(new Date());

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [obs2, setObs2] = useState('');

  // formats select value
  const [list, setList] = useState([]);

  const getData = async () => {
    setLoading(true);

    try {
      const res = await reqTokenBase.get(
        `administration/validacionmasiva/${currentItem.rma_int_id}`
      );

      const resFile = await reqTokenBase.get(
        `administration/records_lectura/${res.data.data[0].rec_int_id}`,
        {
          responseType: 'blob'
        }
      );

      const blob = new Blob([resFile.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      setFilePreview(url);

      const plusDays = addDays(
        new Date(),
        Number(res.data.data[0].for_int_days)
      );

      setDate(new Date(plusDays.replace(/[-]/g, '/')));

      setList(res.data.data.map((item) => ({ ...item, checked: true })));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCheck = ({ target }) => {
    setList(
      list.map((item) =>
        target.name === item.rec_int_id
          ? { ...item, checked: target.checked }
          : item
      )
    );
  };

  const handleSubmitDeny = async () => {
    setLoading(true);

    try {
      await reqTokenBaseJson.put(
        `administration/validacionmasiva/${currentItem.rma_int_id}`,
        {
          rma_int_status: 2,
          rma_text_obs2: obs2
        }
      );
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmitAccep = async () => {
    const unchecked = list.filter((item) => !item.checked);

    setLoading(true);

    try {
      await reqTokenBaseJson.put(
        `administration/validacionmasiva/${currentItem.rma_int_id}`,
        {
          rma_int_status: 0,
          rec_date_expiration_date: format(date, 'yyyy-MM-dd'),
          items: unchecked?.map((item) => ({ rec_int_id: item.rec_int_id }))
        }
      );
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
        setStep(1);
        getInitialData();
      }, 5000);
    }

    //eslint-disable-next-line
  }, [success]);

  return (
    <FullWidthContainer>
      {/* <h2 className="text-center mb-5">Validar carga masiva</h2> */}
      {success && (
        <div className="alert alert-success w-100 mb-3 text-center border">
          Guardado con éxito
        </div>
      )}

      <ConfirmModal confirm={handleSubmitDeny} setObs2={setObs2} obs2={obs2} />

      <div className="row mx-0 w-100 g-2 mb-4">
        <InputText
          className="col-12 col-lg-8"
          label="Validando a:"
          value={list.length > 0 ? `${list[0].bus_text_name2}*${list[0].bus_text_name}` : ''}
          disabled={true}
        />

        <div className="col-12 col-lg-4 mb-3">
          <label className="form-label"> Fecha de caducidad </label>
          <ReactDatePicker
            className="input-bg-dark border px-3 py-1 rounded w-100"
            title="Fecha de caducidad"
            dateFormat="yyyy-MM-dd"
            selected={date}
            onChange={(value) => {
              setDate(value);
            }}
          />
        </div>

        {!loading && filePreview && (
          <embed
            className="col-12 col-lg-8"
            type="application/pdf"
            src={`${filePreview}#toolbar=0`}
            height="700"
          ></embed>
        )}

        {!loading && (
          <div
            className="col-12 col-lg-4 d-flex flex-column ps-3 ms-auto users-masive-list"
            style={{ maxHeight: '700px', overflowY: 'scroll' }}
          >
            {list.map((item, index) => (
              <div
                key={item.rec_int_id}
                className="w-100 p-1 border rounded mb-2"
              >
                <InputCheck
                  id={item.rec_int_id}
                  name={item.rec_int_id}
                  onChange={handleCheck}
                  label={item.cat_text_name}
                  checked={list[index].checked}
                />
              </div>
            ))}
          </div>
        )}

        {loading && (
          <h2 className="text-center col-12 pt-5">Cargando datos...</h2>
        )}

        {list && list[0]?.rec_text_obs && (
          <div className="col-12 p-3">
            <span className="form-label fw-bold">Observaciones:</span>
            <p className="w-100 m-0 text-justify">{list[0].rec_text_obs}</p>
          </div>
        )}
      </div>

      <div className="d-flex w-100 align-items-center justify-content-between mt-auto">
        <button
          type="button"
          disabled={loading ? true : false}
          className="btn btn-secondary"
          onClick={() => setStep(1)}
        >
          <i className="fas fa-caret-left me-2"></i>
          Volver a la lista
        </button>
        <button
          type="button"
          disabled={loading ? true : false}
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target="#confirmMasiveModal"
        >
          Rechazar todo
        </button>
        <button
          type="button"
          disabled={loading ? true : date ? false : true}
          className="btn btn-success"
          onClick={handleSubmitAccep}
        >
          Aprobar
        </button>
      </div>
    </FullWidthContainer>
  );
}
