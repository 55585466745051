import random_id from 'helpers/random_id';
import useRequest from 'hooks/useRequest';
import { useEffect, useState } from 'react';

const itemColorTable = {
  0: 'text-danger',
  1: 'text-success',
  2: 'text-primary'
};

export const Modal_info = ({ infoFormato, actualFormatIds }) => {
  const { reqTokenBase } = useRequest();
  const { tipo, loading, data, error, formato, empresa } = infoFormato;

  const [list, setList] = useState([]);
  const [updating, setUpdating] = useState(false);

  const updateItem = async (woh_int_id) => {
    const { typ_int_id, bus_int_id, for_int_tipo, for_int_id } =
      actualFormatIds;
    setUpdating(true);

    try {
      const data = {
        typ_int_id,
        bus_int_id,
        for_int_tipo,
        for_int_id,
        woh_int_id
      };

      await reqTokenBase.post('empresa/formatsxwoh', data);

      setList((prev) =>
        prev.map((el) =>
          el.woh_int_id === woh_int_id ? { ...el, isvalid: '0' } : el
        )
      );
    } catch (error) {
      console.log(error);
    }

    setUpdating(false);
  };

  useEffect(() => {
    if (data.length) setList(data);
  }, [data]);

  return (
    <div
      className="modal fade"
      id="reporte-formatos-inexistentes-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="reporte-formatos-inexistentes-modal-label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down modal-dialog-scrollable">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5
              className="modal-title text-uppercase"
              id="reporte-formatos-inexistentes-modal-label"
            >
              Información {tipo} - {empresa}
            </h5>
            <button
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body d-flex flex-column">
            {loading && !error && <p>Cargando...</p>}

            {error && (
              <p>
                No se pudo obtener la información, un error ocurrió, contacta
                con soporte
              </p>
            )}

            {!loading && !error && (
              <>
                <h5 className="mb-3">Formato: {formato}</h5>
                <div className="d-flex justify-content-center gap-4 mb-2">
                  <span className="d-flex gap-2 align-items-center justify-content-center">
                    <div
                      style={{ width: '10px', height: '10px' }}
                      className="bg-danger rounded"
                    />
                    No cumple
                  </span>
                  <span className="d-flex gap-2 align-items-center justify-content-center">
                    <div
                      style={{ width: '10px', height: '10px' }}
                      className="bg-success rounded"
                    />
                    Cumple
                  </span>
                  <span className="d-flex gap-2 align-items-center justify-content-center">
                    <div
                      style={{ width: '10px', height: '10px' }}
                      className="bg-primary rounded"
                    />
                    Sin asignar
                  </span>
                </div>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="bg-secondary text-white">
                      <th scope="col">N°</th>
                      <th scope="col">Nombre / Placa / Serie</th>
                      <th scope="col">Asignar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, i) => (
                      <tr key={random_id()}>
                        <td className={itemColorTable[item.isvalid]}>
                          {i + 1}
                        </td>
                        <td className={itemColorTable[item.isvalid]}>
                          {item.nombres}
                        </td>
                        <td>
                          {item.isvalid === '2' && (
                            <button
                              disabled={updating}
                              onClick={() => updateItem(item.woh_int_id)}
                              className="btn btn-secondary w-100 btn-sm"
                            >
                              Asignar
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
