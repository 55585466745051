import React from 'react';
import { NavLink } from 'react-router-dom';

import RouterData from 'routes/RouterData';
import CollapseMenu from 'components/layout/CollapseMenu';

import 'styles/Sidebar.css';
import useAuth from 'auth/useAuth';

export default function Sidebar() {
  const auth = useAuth();

  return (
    <div className="d-none d-lg-block nav-side bg-light">
      {RouterData.map((group, index) => (
        <div
          key={index}
          className="border-bottom pb-2 mb-2 d-flex flex-column align-items-start justify-content-center overflow-hidden"
        >
          {group.map((item) =>
            item.link ? (
              <NavLink
                className="nav-link w-100"
                key={item.link}
                to={item.link}
                activeClassName="navlink-active"
              >
                {item.name}
              </NavLink>
            ) : (
              <CollapseMenu
                key={item.name}
                name={item.name}
                childrens={item.childrens}
              />
            )
          )}
        </div>
      ))}

      <p className="fw-bold text-center mb-0 mt-5">
        La contraseña expira en {auth.user?.userData.passexp} días
      </p>
    </div>
  );
}
