import InputTextarea from 'inputs/InputTextarea';
import React from 'react';

export default function ConfirmModal({ confirm, setObs2, obs2 }) {
  return (
    <div
      className="modal fade"
      id="confirmMasiveModal"
      tabIndex="-1"
      aria-labelledby="confirmMasiveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5
              className="modal-title text-danger"
              id="confirmMasiveModalLabel"
            >
              Rechazar todo
              <i className="fas fa-exclamation-triangle ms-2"></i>
            </h5>
            <button
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body py-5">
            <p className="mb-0 text-center mb-4">
              Está a punto de rechazar el documento, ¿ desea confirmar ?
            </p>

            <InputTextarea
              label="Observaciones"
              value={obs2}
              onChange={(e) => setObs2(e.target.value)}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={confirm}
            >
              Rechazar todo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
