import React, { useEffect, useState } from "react";

import FullWidthContainer from "components/layout/FullWidthContainer";
import { POST } from "helpers/request";

export default function ReportGeneral() {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await POST({ cual: "1" }, "reports", null, null);
    setData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">Reporte general</h2>

      <div
        className="w-100 px-3 bg-secondary text-white d-flex"
        style={{ borderRadius: "3px 3px 0 0" }}
      >
        <p className="mb-0 w-50 text-start fw-bold text-truncate border-end py-3">
          Descripción
        </p>
        <p className="mb-0 w-50 text-center fw-bold text-truncate py-3">
          Valores
        </p>
      </div>

      {data?.map((item) => (
        <div
          key={item.descripcion.replace(" ", "")}
          className="border d-flex px-3"
        >
          <div className="w-50 py-3 border-end pe-3">
            <p className="mb-0 w-100 text-start word-break fw-bold">
              {item.descripcion}
            </p>
          </div>
          <div className="w-50 py-3 ps-3 d-flex flex-wrap">
            {item.valor.map((value, index) =>
              Object.values(value).length > 1 ? (
                <p key={index} className="mb-0 flex-grow-1 px-2 text-center">
                  <span className="fw-bold me-2">
                    {Object.values(value)[0]}:
                  </span>
                  {Object.values(value)[1]}
                </p>
              ) : (
                <p key={index} className="mb-0 flex-grow-1 px-2 text-center">
                  {Object.values(value)[0]}
                </p>
              )
            )}
          </div>
        </div>
      ))}
    </FullWidthContainer>
  );
}
