// import axios from "axios";
import React, { useEffect, useState } from 'react';
import { PDF_DWNLD_GET } from 'helpers/request';
import { useHistory } from 'react-router';
import useRequest from 'hooks/useRequest';

import AlertMessage from 'components/layout/AlertMessage';

import {
  DefaultInput,
  DefaultSelect,
  DefaultTextarea,
  DefaultFile
} from 'templates/input';
import SelectInputSearch from 'components/select-search/SelectInputSearch';
import { useParams } from 'react-router-dom';
import FormatClassificationModal from 'components/FormatFormComponents/FormatClassificationModal';
import { format, isValid } from 'date-fns';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const defaultValues = {
  typ_int_id: '',
  for_text_name: '',
  for_text_description: '',
  for_int_days: '',
  for_int_days_warning: '',
  for_int_tipo: '',
  file: null,
  for_int_obligatorio: '1',
  for_int_masivo: '1'
};

export default function FormatFormByBusiness({
  id,
  typeId,
  typeTitle,
  handleComplete
}) {
  const params = useParams();

  const [data, setData] = useState({
    ...defaultValues,
    typ_int_id: typeId,
    for_int_days: '0',
    for_int_days_warning: '0',
    bus_int_id: params.id,
    for_date_available_date: new Date(),
    for_int_carnet: '0'
  });

  const handleChange = ({ target }) => {
    if (target.name === 'for_int_days' && target.value === '0') {
      setData({
        ...data,
        [target.name]: target.value,
        for_int_days_warning: '0'
      });
      return;
    }

    setData({ ...data, [target.name]: target.value });
  };

  const getData = async () => {
    setDataLoading(true);
    const res = await reqTokenBase.get(`administration/formats/${id}`);
    setData({
      ...res.data,
      typ_int_id: typeId,
      for_date_available_date: isValid(
        new Date(res.data.for_date_available_date)
      )
        ? new Date(res.data.for_date_available_date)
        : new Date()
    });

    if (res.data.for_int_days === 'nulo') setExpiration('documento');
    if (res.data.for_int_days !== '0' && res.data.for_int_days !== 'nulo')
      setExpiration('formato');

    setDataLoading(false);
  };

  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = useState(defaultValues);
  const [success, setSuccess] = useState(null);
  const [fileObj, setFile] = useState(null);
  const [expiration, setExpiration] = useState('noexpira');

  const [hide, setHide] = useState(false);

  const { reqTokenBase, reqTokenBaseFormData } = useRequest();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    if (data.for_int_tipo === '1' && fileObj === null && id === '0') {
      setLoading(false);
      return setError({ ...errorData, file: 'Debe subir un archivo' });
    }

    if (
      expiration === 'formato' &&
      Number(data.for_int_days_warning) > Number(data.for_int_days)
    ) {
      setLoading(false);
      return setError({
        ...errorData,
        for_int_days_warning:
          'Los días para notificación no pueden ser mayores a los días de expiración'
      });
    }

    if (id === '0') {
      const formData = new FormData();

      for (const key in data) {
        if (key !== 'file' && key !== 'for_date_available_date') {
          formData.append(key, data[key]);
        }
      }

      formData.append(
        'for_date_available_date',
        format(data.for_date_available_date, 'yyyy-MM-dd HH:mm')
      );

      if (fileObj) {
        formData.append('file', fileObj);
      }

      try {
        const res = await reqTokenBaseFormData.post(
          `administration/formats`,
          formData
        );
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
        setSuccess(res.status === 200 ? 201 : 500);
      } catch (error) {
        console.log(error);
        setError(error.response?.data.messages);
        setLoading(false);
      }
    }

    //prettier-ignore
    if (id !== "0") {
      try {
        const res = await reqTokenBase.put(`administration/formats/${id}`, {...data, for_date_available_date: format(data.for_date_available_date, 'yyyy-MM-dd HH:mm')})

        if(fileObj) {
          const formData = new FormData();

          formData.append('id', id)
          formData.append('file', fileObj);

          await reqTokenBaseFormData.post(`administration/records2read`, formData);
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoading(false);
        setSuccess(res.status === 200 ? 201 : 500);
      } catch (error) {
        console.log(error);
        setError(error.response?.data.messages);
        setLoading(false);
      }
    }
  };

  const handleFileChange = ({ target }) => {
    if (target.files.length === 0) return;

    setFile(target.files[0]);
  };

  const handleForceDownload = async () => {
    await PDF_DWNLD_GET(
      'documentos_lectura',
      data.r2r_varchar_name.replace(/\s/g, '-'),
      data.r2r_int_id
    );
  };

  useEffect(() => {
    if (id !== '0') getData();
    if (id === '0') setDataLoading(false);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        handleComplete();
      }, 3000);
    }

    //eslint-disable-next-line
  }, [success, history]);

  const {
    typ_int_id,
    for_text_name,
    for_text_description,
    for_int_days,
    for_int_days_warning,
    for_int_tipo,
    for_int_obligatorio,
    for_int_masivo,
    fcl_int_id,
    for_date_available_date,
    for_int_carnet
  } = data;

  if (dataLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center py-5">
        <h5>Cargando...</h5>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 d-flex flex-column mt-3"
      autoComplete="off"
    >
      <FormatClassificationModal
        busId={params.id}
        formatName={for_text_name}
        setHide={setHide}
      />

      {success && <AlertMessage status={success} id={id} />}

      <div className="d-flex flex-column w-100 mb-3">
        <label className="form-label">Disponible desde</label>
        <ReactDatePicker
          className="input-bg-dark w-100 p-2 border rounded"
          title="Disponible desde"
          dateFormat="yyyy-MM-dd HH:mm"
          showTimeSelect
          selected={for_date_available_date}
          onChange={(date) => {
            setData({ ...data, for_date_available_date: date });
          }}
        />
      </div>

      <SelectInputSearch
        label="Selecciona el tipo de documento"
        classes="flex-grow-1 mb-3"
        url={`business_types/${params.id}`}
        //son los nombres de los campos de el esquema, de id y de nombre
        valueField="typ_int_id"
        nameField="typ_text_name"
        placeholder="Escoja una opción"
        defaultValue={typ_int_id}
        onChange={(item) => {
          setData({ ...data, typ_int_id: item.value });
        }}
        error={errorData.typ_int_id}
      />

      {!hide && (
        <SelectInputSearch
          label="Selecciona una clasificación"
          classes="flex-grow-1 mb-3"
          url={`formats_c?b=${params.id}&l=5000&o=0&k=`}
          //son los nombres de los campos de el esquema, de id y de nombre
          valueField="fcl_int_id"
          nameField="fcl_text_name"
          placeholder="Escoja una opción"
          defaultValue={fcl_int_id}
          onChange={(item) => {
            setData({ ...data, fcl_int_id: item.value });
          }}
          error={errorData.fcl_int_id}
        />
      )}

      <DefaultInput
        label="Nombre"
        type="text"
        value={for_text_name}
        inputName="for_text_name"
        onChange={handleChange}
        placeholder="Ingresa un nombre"
        error={errorData?.for_text_name}
      />

      <DefaultTextarea
        label="Descripción"
        value={for_text_description}
        inputName="for_text_description"
        onChange={handleChange}
        placeholder="Ingresa una descripción"
        error={errorData?.for_text_description}
      />

      <DefaultSelect
        disabled={id !== '0' ? true : false}
        label="Seleccione un tipo de documento"
        inputName="for_int_tipo"
        placeholder="Selecciona una opción"
        onChange={handleChange}
        error={errorData?.for_int_tipo}
        defaultValue={for_int_tipo}
        options={[
          { value: '1', name: 'Documento de lectura' },
          { value: '2', name: 'Empresa' },
          { value: '3', name: 'Trabajador' },
          { value: '4', name: 'Equipo' }
        ]}
      />

      {for_int_tipo === '1' && (
        <div className="w-100 d-flex align-items-end gap-3 flex-wrap">
          <DefaultFile
            customClass="flex-grow-1"
            mb="3"
            label="Documento"
            inputName="file"
            onChange={handleFileChange}
            error={errorData?.file}
            value="1"
          />
          {data?.r2r_int_id !== '0' && id !== '0' && (
            <button
              onClick={handleForceDownload}
              type="button"
              className="btn btn-primary mb-3"
            >
              Descargar documento previo
            </button>
          )}
        </div>
      )}

      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="check1"
          disabled={id !== '0' ? true : false}
          checked={expiration === 'noexpira' ? true : false}
          onChange={() => {
            setExpiration('noexpira');
            setData({
              ...data,
              for_int_days: '0',
              for_int_days_warning: '0'
            });
          }}
        />
        <label className="form-check-label" htmlFor="check1">
          Nunca expira
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="check2"
          disabled={id !== '0' ? true : false}
          checked={expiration === 'documento' ? true : false}
          onChange={() => {
            setExpiration('documento');
            setData({ ...data, for_int_days: 'nulo' });
          }}
        />
        <label className="form-check-label" htmlFor="check2">
          Fecha de expiración determinada por los documentos
        </label>
      </div>
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          type="radio"
          id="check5"
          disabled={id !== '0' ? true : false}
          checked={expiration === 'formato' ? true : false}
          onChange={() => {
            setExpiration('formato');
            setData({ ...data, for_int_days: '' });
          }}
        />
        <label className="form-check-label" htmlFor="check5">
          Ingrese la cantidad de días ( Para expirar )
        </label>
      </div>

      {expiration === 'formato' && for_int_days !== 'nulo' && (
        <DefaultInput
          label="Expira (días)"
          type="number"
          minNumber="0"
          value={for_int_days}
          inputName="for_int_days"
          onChange={handleChange}
          placeholder="Cantidad de días en que expira el archivo"
          error={errorData?.for_int_days}
          disabled={id !== '0' ? true : false}
        />
      )}

      {expiration !== 'noexpira' && (
        <DefaultInput
          label="Notificar (días)"
          type="number"
          minNumber="0"
          value={for_int_days_warning}
          inputName="for_int_days_warning"
          onChange={handleChange}
          placeholder="Días en que se notificará que debe renovar archivo"
          error={errorData?.for_int_days_warning}
          disabled={for_int_days === '0' || id !== '0' ? true : false}
        />
      )}

      <label className="fw-bold mb-2">Clase de formato</label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="check3"
          checked={for_int_obligatorio === '1' ? true : false}
          // disabled={id !== "0" ? true : false}
          onChange={() => setData({ ...data, for_int_obligatorio: '1' })}
        />
        <label className="form-check-label" htmlFor="check3">
          Obligatorio
        </label>
      </div>
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          type="radio"
          id="check4"
          checked={for_int_obligatorio === '2' ? true : false}
          // disabled={id !== "0" ? true : false}
          onChange={() => {
            setData({ ...data, for_int_obligatorio: '2' });
          }}
        />
        <label className="form-check-label" htmlFor="check4">
          Determinado por el cliente
        </label>
      </div>

      <label className="fw-bold mb-2">Permite carga masiva de datos</label>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="check6"
          checked={for_int_masivo === '1' ? true : false}
          disabled={for_int_tipo === '3' || for_int_tipo === '4' ? false : true}
          onChange={() => {
            setData({ ...data, for_int_masivo: '1' });
          }}
        />
        <label className="form-check-label" htmlFor="check6">
          Activado
        </label>
      </div>
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          type="radio"
          id="check7"
          checked={for_int_masivo === '0' ? true : false}
          disabled={for_int_tipo === '3' || for_int_tipo === '4' ? false : true}
          onChange={() => {
            setData({ ...data, for_int_masivo: '0' });
          }}
        />
        <label className="form-check-label" htmlFor="check7">
          Desactivado
        </label>
      </div>

      {for_int_tipo === '3' && (
        <>
          <label className="fw-bold mb-2">Carnet</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="check10"
              checked={for_int_carnet === '0' ? true : false}
              onChange={() => {
                setData({ ...data, for_int_carnet: '0' });
              }}
            />
            <label className="form-check-label" htmlFor="check10">
              No incluir
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="check11"
              checked={for_int_carnet === '1' ? true : false}
              onChange={() => {
                setData({ ...data, for_int_carnet: '1' });
              }}
            />
            <label className="form-check-label" htmlFor="check11">
              Incluir
            </label>
          </div>
        </>
      )}

      <div className="w 100 d-flex align-items-center mt-4 justify-content-between">
        {!loading && id !== '0' && (
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-toggle="modal"
            data-bs-target="#format_classification_modal"
          >
            Clasificación (Formato)
            <i className="fas fa-list ms-2"></i>
          </button>
        )}

        <button
          type="submit"
          className="btn btn-primary w-auto ms-auto"
          disabled={loading ? true : false}
        >
          {loading ? (
            <>
              <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
            </>
          ) : id !== '0' ? (
            <>
              Editar
              <i className="fas fa-save ms-2"></i>
            </>
          ) : (
            <>
              Guardar
              <i className="fas fa-save ms-2"></i>
            </>
          )}
        </button>
      </div>
    </form>
  );
}
