const hashTable = {
  users: 'Usuarios',
  types: 'Tipos',
  business_admin: 'Empresas',
  categories: 'Categorías',
  formats: 'Formatos',
  formatos: 'Formatos',
  groups: 'Grupos',
  records: 'Registros',
  access: 'Accesos',
  contacts: 'Contactos',
  blacklist: 'Vetados',
  planes: 'Planes'
};

export default function TitleSelector(module) {
  return hashTable[module];
}
