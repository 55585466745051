import FullWidthContainer from 'components/layout/FullWidthContainer';
import { POST } from 'helpers/request';
import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Label,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

export default function Reporte22() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keysSet, setKeysSet] = useState([]);

  const getData = async () => {
    setLoading(true);
    setData([]);

    const res = await POST(
      {
        cual: '22',
        xls: '0'
      },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    if (res) {
      setData(res);
      setKeysSet(
        Array.from(new Set(res.map((obj) => Object.keys(obj)).flat()))
      );
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="text-white p-2" style={{ backgroundColor: '#232323' }}>
          {payload.map((el) => {
            if (el.dataKey === 'name') {
              return (
                <p className="mb-1">
                  <strong>Empresa:</strong> {el.value}
                </p>
              );
            }

            return (
              <p className="mb-1">
                {el.dataKey}: {el.value}
              </p>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const randomHexColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Generate a random number and convert it to hexadecimal
    return `#${randomColor.padStart(6, '0')}`; // Return the hexadecimal color string
  };

  const format_legend = (props) => {
    const { payload } = props;

    return (
      <div className="d-flex flex-wrap gap-3 w-100 justify-content-center align-items-center">
        {payload.map((entry, index) => {
          if (entry.value === 'name' || entry.value === 'pyc') return '';

          return (
            <span
              style={{ color: entry.color }}
              key={`item-${index}`}
              className="d-flex justify-content-center align-items-center gap-1"
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 1,
                  backgroundColor: entry.color
                }}
                className="mr-2"
              />
              {entry.value}
            </span>
          );
        })}

        <span className="d-flex justify-content-center align-items-center gap-1 text-primary">
          Proveedores y contratistas
        </span>
      </div>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">Cantidad de Documentos por Empresa</h2>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading && data && data.length > 0 && (
        <div className="container-fluid">
          <ResponsiveContainer width={'100%'} height={550}>
            <BarChart
              width={500}
              height={450}
              data={data}
              margin={{
                top: 20,
                right: 20,
                left: 20,
                bottom: 60
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="empresa">
                <Label value="Empresas" offset={-10} position="insideBottom" />
              </XAxis>
              <Legend
                verticalAlign="top"
                offset={10}
                height={36}
                content={format_legend}
              />
              <YAxis
                yAxisId="left"
                orientation="left"
                stroke="#8884d8"
                label={{
                  value: 'Cantidad Documentos',
                  angle: -90,
                  position: 'insideBottomLeft'
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                stroke="#82ca9d"
                label={{
                  value: 'Cantidad Proveedores y Contratistas',
                  angle: 90,
                  position: 'insideBottomRight'
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              {/* <Tooltip /> */}
              {keysSet.map((item, index) =>
                item !== 'pyc' ? (
                  <Bar
                    yAxisId="left"
                    key={index}
                    dataKey={item}
                    fill={randomHexColor()}
                  />
                ) : (
                  ''
                )
              )}
              <Bar yAxisId="right" dataKey="pyc" fill={randomHexColor()} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </FullWidthContainer>
  );
}
