import React from "react";

export default function FullWidthContainer({ children }) {
  return (
    <div className="custom-container d-flex flex-column">
      <div className="container-fluid d-flex flex-column bg-light rounded py-3 flex-grow-1">
        {children}
      </div>
    </div>
  );
}
