export default function SituDocModal({ customId, info, type }) {
  const idTable = {
    trabajadores: "tra_int_id",
    equipos: "equ_int_id",
  };
  const nameTable = {
    trabajadores: "tra_varchar_dni",
    equipos: "equ_text_serie",
  };

  return (
    <div
      className="modal fade"
      id={customId}
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5
              className="modal-title gap-3 d-flex align-items-center"
              id="deleteModalLabel"
            >
              Situación documental
            </h5>
            <button
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            {(!info || info === "No existe") && (
              <h5 className="my-5">Sin información</h5>
            )}
            {info && info !== "No existe" && (
              <>
                <div className="row w-100 mx-0 p-0 border border-bottom-0 py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold">ID:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                    <span>{info[idTable[type]]}</span>
                  </div>
                </div>
                <div className="row w-100 mx-0 p-0 border border-bottom-0 py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold">PLACA/CÉDULA:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                    <span>{info[nameTable[type]]}</span>
                  </div>
                </div>
                <div className="row w-100 mx-0 p-0 border py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold">RUC EMPRESA:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                    <span>{info.bus_text_identification}</span>
                  </div>
                </div>

                <span className="w-100 my-2"></span>

                <div className="row w-100 mx-0 p-0 border border-bottom-0 py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold text-success">VALIDOS:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                  <span>{info.validos} de {info.deberia}</span>
                  </div>
                </div>
                <div className="row w-100 mx-0 p-0 border border-bottom-0 py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold text-warning">CADUCADOS:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                    <span>{info.caducados}</span>
                  </div>
                </div>
                <div className="row w-100 mx-0 p-0 border border-bottom-0 py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold text-danger">INEXISTENTES:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                    <span>{info.inexistentes}</span>
                  </div>
                </div>
                <div className="row w-100 mx-0 p-0 border border-bottom-0 py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold text-info">PENDIENTES:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                    <span>{info.pendientes}</span>
                  </div>
                </div>
                <div className="row w-100 mx-0 p-0 border border-bottom-0 py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold text-secondary">NO VÁLIDOS:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                    <span>{info.invalidos}</span>
                  </div>
                </div>
                <div className="row w-100 mx-0 p-0 border py-1">
                  <div className="col-6 col-md-4 d-flex justify-content-center border-end">
                    <span className="fw-bold">HISTORICO:</span>
                  </div>
                  <div className="col-6 col-md-8 d-flex justify-content-center">
                    <span>{info.hitorico}</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
