import { useEffect, useState } from "react";

import { adminUrl, headerToken } from "api/api";

export default function useAuditorEdit(id) {
  const [auditorEdited, setAuditorEdited] = useState(false);

  const handleAuditor = async (value) => {
    const res = await adminUrl.post(
      "business_auditors",
      {
        bus_int_id: id,
        use_int_id: value,
        que: "c",
      },
      headerToken()
    );

    if (res.data.status === 202) {
      setAuditorEdited(true);
    }
  };

  useEffect(() => {
    let changeState;

    function startChangeState() {
      changeState = setTimeout(() => {
        setAuditorEdited(false);
      }, 3000);
    }

    function stopChangeState() {
      window.clearTimeout(changeState);
    }

    if (auditorEdited) {
      startChangeState();
    }

    return stopChangeState;
  }, [auditorEdited]);

  return { handleAuditor, auditorEdited };
}
