import FullWidthContainer from 'components/layout/FullWidthContainer';
import Autocomplete from 'components/select-search/Autocomplete';
import useRequest from 'hooks/useRequest';
import { useState } from 'react';

const categoriaTable = {
  2: 'Grave',
  3: 'Muy grave'
};

const sancionTable = {
  4: 'Primera sanción',
  5: 'Segunda sanción',
  6: 'Suspención'
};

const defaultFormValues = {
  empresaId: '',
  proveedorId: '',
  infraccion: '',
  categoria: '2',
  sancion: '4',
  notificacion: '',
  dias: ''
};

export default function FaltasEmpresa() {
  const { reqToken } = useRequest();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState(defaultFormValues);
  const [history, setHistory] = useState(null);
  const [inAction, setInAction] = useState(false);

  const {
    empresaId,
    proveedorId,
    infraccion,
    categoria,
    sancion,
    notificacion,
    dias
  } = form;

  const submit = async () => {
    setLoading(true);
    setError(null);

    try {
      await reqToken.post('faults_business', {
        bus_int_id: proveedorId,
        fxb_text_infraccion: infraccion,
        fxb_int_severity: categoria,
        fxb_int_sancion: sancion,
        fxb_text_notificacion: notificacion,
        fxb_int_sancion_days: dias
      });

      setForm(defaultFormValues);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const getHistory = async () => {
    const response = await reqToken.get('faults_business');
    setHistory(response.data.datos);
  };

  const softDeleteFault = async (id) => {
    setInAction(true);
    await reqToken.delete(`faults_business/${id}/eliminar`);
    setInAction(false);
  };

  const hardDeleteFault = async (id) => {
    setInAction(true);
    await reqToken.delete(`faults_business/${id}/remove`);
    setInAction(false);
  };

  const restoreFault = async (id) => {
    setInAction(true);
    await reqToken.delete(`faults_business/${id}/restore`);
    setInAction(false);
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-4">Faltas por proveedor</h2>

      <div className="row">
        <div className="col-8">
          <h6 className="mb-4">Formulario de falta</h6>

          <form
            onSubmit={(e) => {
              e.preventDefault();

              const values = Object.values(form);

              const isEmpty = values.some((value) => !value);

              if (isEmpty) return setError('Faltan campos por completar');

              submit();
            }}
          >
            <div className="row align-items-center mb-2">
              <div className="col-3 text-end fw-bold">Empresa</div>
              <div className="col-9">
                <Autocomplete
                  label=""
                  classes="w-100"
                  url="business_admin"
                  //son los nombres de los campos de el esquema, de id y de nombre
                  valueField="bus_int_id"
                  nameField="bus_text_name"
                  placeholder="Escoja una opción"
                  onChange={(item) => {
                    setForm({ ...form, empresaId: '' });

                    setTimeout(() => {
                      setForm({ ...form, empresaId: item.value });
                      getHistory();
                    }, 100);
                  }}
                />
              </div>
            </div>

            <div className="row align-items-center mb-2">
              {empresaId && (
                <>
                  <div className="col-3 text-end fw-bold">Proveedor</div>
                  <div className="col-9">
                    <Autocomplete
                      label=""
                      classes="w-100"
                      url="business_admin"
                      customParams={[`c=${empresaId}`]}
                      //son los nombres de los campos de el esquema, de id y de nombre
                      valueField="bus_int_id"
                      nameField="bus_text_name"
                      placeholder="Escoja una opción"
                      onChange={(item) => {
                        setForm({ ...form, proveedorId: item.value });
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-3 text-end fw-bold">Infracción</div>
              <div className="col-9">
                <input
                  type="text"
                  className="form-control input-bg-dark"
                  id="infraccion"
                  placeholder="Sin equipo de protección..."
                  value={infraccion}
                  onChange={(e) =>
                    setForm({ ...form, infraccion: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-3 text-end fw-bold">Gravedad</div>
              <div className="col-9">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="faltagrave"
                    id="grave"
                    value="2"
                    defaultChecked
                    checked={categoria === '2'}
                    onChange={(e) =>
                      setForm({ ...form, categoria: e.target.value })
                    }
                  />
                  <label className="form-check-label" for="grave">
                    Grave
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="faltamuygrave"
                    id="muy-grave"
                    value="3"
                    checked={categoria === '3'}
                    onChange={(e) =>
                      setForm({ ...form, categoria: e.target.value })
                    }
                  />
                  <label className="form-check-label" for="muy-grave">
                    Muy grave
                  </label>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-3 text-end fw-bold">
                Sanción al contratista
              </div>
              <div className="col-9">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="primerasancion"
                    id="primera-sancion"
                    value="4"
                    defaultChecked
                    checked={sancion === '4'}
                    onChange={(e) =>
                      setForm({ ...form, sancion: e.target.value })
                    }
                  />
                  <label className="form-check-label" for="primera-sancion">
                    Primera sanción
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="segundasancion"
                    id="segunda-sancion"
                    value="5"
                    checked={sancion === '5'}
                    onChange={(e) =>
                      setForm({ ...form, sancion: e.target.value })
                    }
                  />
                  <label className="form-check-label" for="segunda-sancion">
                    Segunda sanción
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="suspencion"
                    id="suspencion"
                    value="6"
                    checked={sancion === '6'}
                    onChange={(e) =>
                      setForm({ ...form, sancion: e.target.value })
                    }
                  />
                  <label className="form-check-label" for="suspencion">
                    Suspención
                  </label>
                </div>
              </div>
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-3 text-end fw-bold">Notificación</div>
              <div className="col-9">
                <textarea
                  className="form-control input-bg-dark"
                  id="notificacion"
                  rows="5"
                  value={notificacion}
                  placeholder="Debido a la falta de..."
                  onChange={(e) =>
                    setForm({ ...form, notificacion: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-3 text-end fw-bold">Días suspendido</div>
              <div className="col-9">
                <input
                  type="number"
                  className="form-control input-bg-dark"
                  id="dias-suspencion"
                  placeholder="10"
                  value={dias}
                  onChange={(e) => setForm({ ...form, dias: e.target.value })}
                />
              </div>
            </div>

            {error && <div className="text-end text-danger">{error}</div>}

            <div className="d-flex justify-content-end gap-4">
              <button
                className="btn btn-sm btn-primary"
                type="submit"
                disabled={loading}
              >
                Guardar y notificar
              </button>
              <button
                className="btn btn-sm btn-warning"
                type="submit"
                disabled={loading}
              >
                Guardar y suspender
              </button>
              <button
                className="btn btn-sm btn-danger"
                type="submit"
                disabled={loading}
              >
                Retiro definitivo
              </button>
            </div>
          </form>
        </div>

        <div className="col-4">
          <h6 className="mb-4">Faltas de proveedores</h6>

          {(!history || !history.length) && (
            <div className="border h-100 flex flex-column gap-2">
              {!history && (
                <div className="px-2 d-flex justify-content-center align-items-center h-100">
                  Selecciona una empresa
                </div>
              )}

              {!history?.length && (
                <div className="px-2 d-flex justify-content-center align-items-center h-100">
                  No hay faltas en los proveedores
                </div>
              )}
            </div>
          )}

          {history && history.length && (
            <div
              className="border h-100 d-flex flex-column gap-2"
              style={{ overflowY: 'scroll', maxHeight: '500px' }}
            >
              {history?.map((business) => (
                <div className="p-3 d-flex flex-column gap-2 border-bottom">
                  <div className="d-flex flex-column gap-1">
                    <span className="fw-bold">Empresa</span>
                    <p className="text-start m-0">{business.bus_text_name}</p>
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <span className="fw-bold">Gravedad</span>
                    <p className="text-start m-0">{business.gravedad}</p>
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <span className="fw-bold">Sanción</span>
                    <p className="text-start m-0">
                      {sancionTable[business.fxb_int_sancion]}
                    </p>
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <span className="fw-bold">Días suspendido</span>
                    <p className="text-start m-0">
                      {business.fxb_int_sancion_days}
                    </p>
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <span className="fw-bold">Infracción</span>
                    <p className="text-start m-0">
                      {business.fxb_text_infraccion}
                    </p>
                  </div>

                  <div className="d-flex flex-column gap-1">
                    <span className="fw-bold">Notificación</span>
                    <p className="text-start m-0">
                      {business.fxb_text_notificacion}
                    </p>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => softDeleteFault(business.bus_int_id)}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </FullWidthContainer>
  );
}
