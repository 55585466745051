import React, { useState, useEffect } from "react";
import useRequest from "hooks/useRequest";
import { DefaultInput } from "templates/input";

export default function ClassificationModal({ busId, busName }) {
  const { reqToken, reqTokenBaseFormData } = useRequest();

  const [staticList, setStaticList] = useState([]);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [keyword, setKeyword] = useState("");

  const [create, setCreate] = useState(false);
  const [willDelete, setWillDelete] = useState(false);
  const [edit, setEdit] = useState("");
  const [newItemDesc, setNewItemDesc] = useState("");
  const [editText, setEditText] = useState("");

  const [inProcess, setInProcess] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await reqToken.get(`business_class/${busId}?l=999`);
      setStaticList(res.data.datos);
      setData(res.data.datos);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const handleFilter = ({ target }) => {
    setKeyword(target.value);
    setData(
      staticList.filter((item) =>
        item.cla_text_name.toLowerCase().includes(target.value.toLowerCase())
      )
    );
  };

  const handleNewItemDesc = ({ target }) => {
    setNewItemDesc(target.value);
  };

  const toggleCreate = () => {
    setCreate(!create);
    if (!create) {
      setData(staticList);
      setKeyword("");

      setTimeout(() => {
        document.getElementById("new_item_desc").focus();
      }, 100);
    }
  };

  const handleNewItemSubmit = async () => {
    setInProcess(true);

    const formData = new FormData();
    formData.append("cla_text_name", newItemDesc);

    const res = await reqTokenBaseFormData.post(
      "administration/clasificacion2b",
      formData
    );

    const list = [
      {
        cla_int_id: res.data.id,
        bxc_int_id: null,
        cla_text_name: newItemDesc,
      },
      ...staticList,
    ];

    setStaticList(list);
    setData(list);

    setCreate(false);
    setNewItemDesc("");

    setInProcess(false);
  };

  const handleAssign = async (paramId, isAssigned) => {
    setInProcess(true);

    if (!isAssigned) {
      const formData = new FormData();
      formData.append("bus_int_id", busId);
      formData.append("cla_int_id", paramId);

      const res = await reqTokenBaseFormData.post(
        "administration/business_class",
        formData
      );

      if (res.data.status === 201) {
        const list = staticList.map((item) =>
          item.cla_int_id === paramId
            ? { ...item, bxc_int_id: res.data.id }
            : item
        );
        setStaticList(list);
        setData(list);
      }
    }

    if (isAssigned) {
      await reqToken.delete(`business_class/${busId}/${paramId}`);

      const list = staticList.map((item) =>
        item.cla_int_id === paramId ? { ...item, bxc_int_id: null } : item
      );
      setStaticList(list);
      setData(list);
    }

    setInProcess(false);
  };

  const handleDelete = async (paramId) => {
    setInProcess(true);

    await reqToken.delete(`clasificacion2b/${paramId}`);

    const list = staticList.filter((item) => item.cla_int_id !== paramId);
    setStaticList(list);
    setData(list);

    setInProcess(false);
  };

  const handleEditText = ({ target }) => {
    setEditText(target.value);
  };

  const handleEdit = async (paramId) => {
    setInProcess(true);

    await reqToken.put(`clasificacion2b/${paramId}`, {
      cla_text_name: editText,
    });

    const list = staticList.map((item) =>
      item.cla_int_id === paramId ? { ...item, cla_text_name: editText } : item
    );
    setStaticList(list);
    setData(list);

    setEdit(false);
    setEditText("");

    setInProcess(false);
  };

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, []);

  return (
    <div
      className="modal fade"
      id="classification_modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="classification_modal_label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5 className="modal-title" id="classification_modal_label">
              Clasificación de empresa - {busName}
            </h5>
            <button
              type="button"
              className="btn-close-modal btn text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body p-3">
            <div className="d-none d-md-flex w-100 align-items-end gap-3 my-4">
              <DefaultInput
                customClass="flex-grow-1"
                onChange={handleFilter}
                value={keyword}
                placeholder="Ingresa palabras clave"
                mb="0"
                required={false}
              />

              <button type="button" className="btn btn-primary">
                <i className="fas fa-search me-2"></i>
                Buscar
              </button>

              <button
                onClick={toggleCreate}
                type="button"
                className="btn btn-success"
              >
                <i className="fas fa-folder-plus me-2"></i>
                Agregar
              </button>
            </div>

            <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-2">
              <div className="col-2 col-md-1 fw-bold text-white border-end py-2 text-center">
                ID
              </div>
              <div className="col-6 col-md-9 fw-bold text-white border-end py-2">
                Nombre
              </div>
              <div className="col-4 col-md-2 fw-bold text-white text-center py-2">
                Opciones
              </div>
            </div>

            {create && (
              <div className="d-none d-md-flex w-100 align-items-end gap-3 my-4">
                <DefaultInput
                  customClass="flex-grow-1"
                  onChange={handleNewItemDesc}
                  placeholder="Nombre de la nueva clasificación"
                  mb="0"
                  required={false}
                  id="new_item_desc"
                />

                <button
                  onClick={handleNewItemSubmit}
                  type="button"
                  className="btn btn-primary"
                  disabled={inProcess}
                >
                  Guardar
                  <i className="fas fa-save ms-2"></i>
                </button>
                <button
                  onClick={toggleCreate}
                  type="button"
                  className="btn btn-danger"
                  disabled={inProcess}
                >
                  Cancelar
                  <i className="fas fa-times ms-2"></i>
                </button>
              </div>
            )}

            <div className="items-list-container d-flex flex-column border-top border-end">
              {!loading && data.length === 0 && (
                <div className="alert alert-warning p-2 border text-center">
                  No hay datos
                </div>
              )}

              {!loading && error && (
                <div className="alert alert-danger p-2 border text-center">
                  Ha ocurrido un error, intente más tarde
                </div>
              )}

              {loading && (
                <div className="p-2 border text-center">Cargando...</div>
              )}

              {!loading &&
                data.map((item) => (
                  <div
                    key={item.cla_int_id}
                    className="row w-100 mx-0 px-0 border align-items-center border-top-0 border-end-0"
                  >
                    {edit !== item.cla_int_id && (
                      <>
                        <div className="col-2 col-md-1 border-end py-2 text-center">
                          {item.cla_int_id}
                        </div>
                        <div className="col-6 col-md-9 border-end py-2">
                          {item.cla_text_name}
                        </div>
                      </>
                    )}

                    {edit === item.cla_int_id && (
                      <>
                        <div className="col-2 col-md-1 border-end py-2 text-center">
                          {item.cla_int_id}
                        </div>
                        <div className="col-2 col-md-9 border-end py-2 text-center">
                          <DefaultInput
                            customClass="flex-grow-1"
                            onChange={handleEditText}
                            value={editText}
                            placeholder="Nombre de la clasificación"
                            mb="0"
                            required={false}
                          />
                        </div>
                      </>
                    )}

                    <div className="col-4 col-md-2 d-flex align-items-center justify-content-center gap-2 py-2">
                      {willDelete !== item.cla_int_id &&
                        edit !== item.cla_int_id && (
                          <>
                            <button
                              type="button"
                              className={`btn btn-${
                                item.bxc_int_id ? "success" : "secondary"
                              } btn-sm`}
                              onClick={() =>
                                handleAssign(item.cla_int_id, item.bxc_int_id)
                              }
                              disabled={inProcess}
                            >
                              <i className="fas fa-check"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              disabled={inProcess}
                              onClick={() => {
                                setEdit(item.cla_int_id);
                                setEditText(item.cla_text_name);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>

                            {item.borrable === "t" && (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                disabled={inProcess}
                                onClick={() => setWillDelete(item.cla_int_id)}
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                            )}
                          </>
                        )}

                      {willDelete === item.cla_int_id &&
                        edit !== item.cla_int_id && (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm w-100"
                              disabled={inProcess}
                              onClick={() => handleDelete(item.cla_int_id)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              disabled={inProcess}
                              onClick={() => setWillDelete(false)}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </>
                        )}

                      {edit === item.cla_int_id &&
                        willDelete !== item.cla_int_id && (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm w-100"
                              disabled={inProcess}
                              onClick={() => handleEdit(item.cla_int_id)}
                            >
                              <i className="fas fa-save"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              disabled={inProcess}
                              onClick={() => setEdit(false)}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
