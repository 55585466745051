import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import DeleteModal from './DeleteModal';
import useRequest from 'hooks/useRequest';

export default function MarketingListItem({
  clearPrevData,
  id,
  title,
  erreasing,
  type,
  item,
  deleted,
  handleDelete,
  handleRestore,
  handleHardDelete,
  setModalInfo
}) {
  const { reqTokenBase, reqToken } = useRequest();
  const history = useHistory();

  const customDeleteId = 'list-item-delete-' + id;
  const hashCustomDeleteId = '#' + customDeleteId.replaceAll(' ', '');

  const [isDeleted, setIsDeleted] = useState(deleted);
  const [loading, setLoading] = useState(false);

  const [hardDelete, setHardDelete] = useState(false);

  const handleEdit = () => {
    history.push(`/marketing/${type}/${id}`);
  };

  const handleQueue = () => {
    clearPrevData();
    history.push(`/emails/queue/${id}/${title.replace(/\s/g, '-')}`);
  };

  const handleSent = () => {
    clearPrevData();
    history.push(`/emails/sent/${id}/${title.replace(/\s/g, '-')}`);
  };

  const getFullData = async () => {
    const res = await reqTokenBase.get(`marketing/newsletter/${id}`);
    const resFiles = await reqTokenBase.get(
      `marketing/files_newsletter/${id}?que=2`
    );

    const resBusiness = await reqTokenBase.get(
      `administration/business_admin/${res.data.bus_int_id}`
    );

    let types;

    if (res.data.typ_int_id !== '0') {
      types = await reqToken.get(`business_types/${res.data.bus_int_id}`);
    }

    let formats;

    if (res.data.for_int_id !== '0') {
      formats = await reqToken.get(
        `formatsxbusiness/${res.data.typ_int_id}/${res.data.bus_int_id}`
      );
    }

    setModalInfo({
      subject: res.data.nlt_text_subjet,
      content: res.data.nlt_text_content,
      html: res.data.nlt_text_content_html,
      emails: '',
      empresa: resBusiness.data.bus_text_name,
      tipo:
        res.data.typ_int_id === '0'
          ? 'Todos'
          : types.data.datos.find(
              (item) => item.typ_int_id === res.data.typ_int_id
            ).typ_text_name,
      formato:
        res.data.for_int_id === '0'
          ? 'Todos'
          : formats.data.datos.find(
              (item) => item.for_int_id === res.data.for_int_id
            ).for_text_name,
      itemId: id,
      files: resFiles.data,
      onlyView: false,
      fillItem: res.data,
      businessData: resBusiness.data
    });
  };

  const deleteFunction = () => {
    handleDelete(id);
    setIsDeleted(true);
  };

  const handleRestoreLocal = async () => {
    setLoading(true);
    handleRestore(id);
    setIsDeleted(false);
    setLoading(false);
  };

  const handleHardDeleteLocal = async () => {
    setLoading(true);
    handleHardDelete(id);
    setLoading(false);
  };

  return (
    <div className="row w-100 mx-0 px-0 border-bottom align-items-center hover-list-item border border-bottom-0">
      <span className="col-3 col-md-1 border-end py-2 text-center">{id}</span>

      <span className="col-9 col-md-7 py-2 border-end">{title}</span>

      <div className="d-none d-md-flex border-start col-md-4 py-2 justify-content-center gap-3">
        {!isDeleted && (
          <>
            <button
              onClick={handleSent}
              type="button"
              className="btn btn-primary btn-sm"
              disabled={loading}
              title="Ver emails enviados"
            >
              <i className="far fa-paper-plane"></i>
            </button>

            <button
              onClick={handleQueue}
              type="button"
              className="btn btn-info btn-sm"
              disabled={loading}
              title="Ver emails en cola"
            >
              <i className="fas fa-list"></i>
            </button>

            <button
              type="button"
              className="btn btn-warning btn-sm"
              disabled={loading}
              title="Colocar email en cola"
              data-bs-toggle="modal"
              data-bs-target="#list-item-email-view"
              onClick={() => {
                getFullData();
              }}
            >
              <i className="fas fa-mail-bulk"></i>
            </button>

            <button
              onClick={handleEdit}
              type="button"
              className="btn btn-secondary btn-sm"
              disabled={erreasing ? true : false}
            >
              <i className="far fa-edit"></i>
            </button>

            <button
              type="button"
              className="btn btn-danger btn-sm"
              disabled={erreasing ? true : false}
              data-bs-toggle="modal"
              data-bs-target={hashCustomDeleteId}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </>
        )}

        {isDeleted && (
          <>
            {!hardDelete && (
              <button
                type="button"
                className="btn btn-warning px-2 py-1"
                onClick={handleRestoreLocal}
                disabled={erreasing}
              >
                Restaurar
              </button>
            )}

            {!hardDelete && (
              <button
                type="button"
                className="btn btn-danger px-2 py-1"
                onClick={() => setHardDelete(true)}
                disabled={erreasing}
                title="Borrar permanentemente"
              >
                <i className="fas fa-exclamation-triangle me-2"></i>
                <i className="far fa-trash-alt"></i>
              </button>
            )}

            {hardDelete && (
              <>
                <button
                  type="button"
                  className="btn btn-danger px-2 py-1"
                  onClick={handleHardDeleteLocal}
                  disabled={erreasing}
                  title="Borrar permanentemente"
                >
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  Borrar
                </button>

                <button
                  type="button"
                  className="btn btn-secondary px-2 py-1"
                  onClick={() => setHardDelete(false)}
                  disabled={erreasing}
                >
                  Cancelar
                </button>
              </>
            )}
          </>
        )}
      </div>

      <DeleteModal
        customId={customDeleteId}
        title={title}
        id={id}
        deleteFunction={deleteFunction}
        erreasing={erreasing}
      />
    </div>
  );
}
