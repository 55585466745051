import React, { useState, useEffect } from "react";
import useRequest from "hooks/useRequest";
import { DefaultInput } from "templates/input";

export default function FormatClassificationModal({
  busId,
  formatName,
  setHide,
}) {
  const { reqToken, reqTokenBaseFormData } = useRequest();

  const [staticList, setStaticList] = useState([]);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [keyword, setKeyword] = useState("");

  const [create, setCreate] = useState(false);
  const [willDelete, setWillDelete] = useState(false);
  const [edit, setEdit] = useState("");
  const [newItemDesc, setNewItemDesc] = useState("");
  const [editText, setEditText] = useState("");

  const [inProcess, setInProcess] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await reqToken.get(`formats_c?b=${busId}&l=999`);
      setStaticList(res.data.datos);
      setData(res.data.datos);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const handleFilter = ({ target }) => {
    setKeyword(target.value);
    setData(
      staticList.filter((item) =>
        item.fcl_text_name.toLowerCase().includes(target.value.toLowerCase())
      )
    );
  };

  const handleNewItemDesc = ({ target }) => {
    setNewItemDesc(target.value);
  };

  const toggleCreate = () => {
    setCreate(!create);
    if (!create) {
      setData(staticList);
      setKeyword("");

      setTimeout(() => {
        document.getElementById("new_item_desc").focus();
      }, 100);
    }
  };

  const handleNewItemSubmit = async () => {
    setInProcess(true);

    const formData = new FormData();
    formData.append("fcl_text_name", newItemDesc);
    formData.append("bus_int_id", busId);

    const res = await reqTokenBaseFormData.post(
      "administration/formats_c",
      formData
    );

    const list = [
      {
        fcl_int_id: res.data.id,
        fcl_text_name: newItemDesc,
      },
      ...staticList,
    ];

    setStaticList(list);
    setData(list);

    setCreate(false);
    setNewItemDesc("");

    setHide(true);

    setTimeout(() => {
      setHide(false);
    }, 100);

    setInProcess(false);
  };

  const handleDelete = async (paramId) => {
    setInProcess(true);

    await reqToken.delete(`formats_c/${paramId}`);

    const list = staticList.filter((item) => item.fcl_int_id !== paramId);
    setStaticList(list);
    setData(list);

    setInProcess(false);
  };

  const handleEditText = ({ target }) => {
    setEditText(target.value);
  };

  const handleEdit = async (paramId) => {
    setInProcess(true);

    await reqToken.put(`formats_c/${paramId}`, {
      fcl_text_name: editText,
    });

    const list = staticList.map((item) =>
      item.fcl_int_id === paramId ? { ...item, fcl_text_name: editText } : item
    );
    setStaticList(list);
    setData(list);

    setEdit(false);
    setEditText("");

    setInProcess(false);
  };

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, []);

  return (
    <div
      className="modal fade"
      id="format_classification_modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="format_classification_modal_label"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5 className="modal-title" id="format_classification_modal_label">
              Clasificación de formatos - {formatName}
            </h5>
            <button
              type="button"
              className="btn-close-modal btn text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body p-3">
            <div className="d-none d-md-flex w-100 align-items-end gap-3 my-4">
              <DefaultInput
                customClass="flex-grow-1"
                onChange={handleFilter}
                value={keyword}
                placeholder="Ingresa palabras clave"
                mb="0"
                required={false}
              />

              <button type="button" className="btn btn-primary">
                <i className="fas fa-search me-2"></i>
                Buscar
              </button>

              <button
                onClick={toggleCreate}
                type="button"
                className="btn btn-success"
              >
                <i className="fas fa-folder-plus me-2"></i>
                Agregar
              </button>
            </div>

            <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-2">
              <div className="col-2 col-md-1 fw-bold text-white border-end py-2 text-center">
                ID
              </div>
              <div className="col-6 col-md-9 fw-bold text-white border-end py-2">
                Nombre
              </div>
              <div className="col-4 col-md-2 fw-bold text-white text-center py-2">
                Opciones
              </div>
            </div>

            {create && (
              <div className="d-none d-md-flex w-100 align-items-end gap-3 my-4">
                <DefaultInput
                  customClass="flex-grow-1"
                  onChange={handleNewItemDesc}
                  placeholder="Nombre de la nueva clasificación"
                  mb="0"
                  required={false}
                  id="new_item_desc"
                />

                <button
                  onClick={handleNewItemSubmit}
                  type="button"
                  className="btn btn-primary"
                  disabled={inProcess}
                >
                  Guardar
                  <i className="fas fa-save ms-2"></i>
                </button>
                <button
                  onClick={toggleCreate}
                  type="button"
                  className="btn btn-danger"
                  disabled={inProcess}
                >
                  Cancelar
                  <i className="fas fa-times ms-2"></i>
                </button>
              </div>
            )}

            <div className="items-list-container d-flex flex-column border-top border-end">
              {!loading && data.length === 0 && (
                <div className="alert alert-warning p-2 border text-center">
                  No hay datos
                </div>
              )}

              {!loading && error && (
                <div className="alert alert-danger p-2 border text-center">
                  Ha ocurrido un error, intente más tarde
                </div>
              )}

              {loading && (
                <div className="p-2 border text-center">Cargando...</div>
              )}

              {!loading &&
                data.map((item) => (
                  <div
                    key={item.fcl_int_id}
                    className="row w-100 mx-0 px-0 border align-items-center border-top-0 border-end-0"
                  >
                    {edit !== item.fcl_int_id && (
                      <>
                        <div className="col-2 col-md-1 border-end py-2 text-center">
                          {item.fcl_int_id}
                        </div>
                        <div className="col-6 col-md-9 border-end py-2">
                          {item.fcl_text_name}
                        </div>
                      </>
                    )}

                    {edit === item.fcl_int_id && (
                      <>
                        <div className="col-2 col-md-1 border-end py-2 text-center">
                          {item.fcl_int_id}
                        </div>
                        <div className="col-2 col-md-9 border-end py-2 text-center">
                          <DefaultInput
                            customClass="flex-grow-1"
                            onChange={handleEditText}
                            value={editText}
                            placeholder="Nombre de la clasificación"
                            mb="0"
                            required={false}
                          />
                        </div>
                      </>
                    )}

                    <div className="col-4 col-md-2 d-flex align-items-center justify-content-center gap-2 py-2">
                      {willDelete !== item.fcl_int_id &&
                        edit !== item.fcl_int_id && (
                          <>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              disabled={inProcess}
                              onClick={() => {
                                setEdit(item.fcl_int_id);
                                setEditText(item.fcl_text_name);
                              }}
                            >
                              <i className="far fa-edit"></i>
                            </button>

                            {/* {item.borrable === "t" && ( */}
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              disabled={inProcess}
                              onClick={() => setWillDelete(item.fcl_int_id)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                            {/* )} */}
                          </>
                        )}

                      {willDelete === item.fcl_int_id &&
                        edit !== item.fcl_int_id && (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm w-100"
                              disabled={inProcess}
                              onClick={() => handleDelete(item.fcl_int_id)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              disabled={inProcess}
                              onClick={() => setWillDelete(false)}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </>
                        )}

                      {edit === item.fcl_int_id &&
                        willDelete !== item.fcl_int_id && (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm w-100"
                              disabled={inProcess}
                              onClick={() => handleEdit(item.fcl_int_id)}
                            >
                              <i className="fas fa-save"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-secondary btn-sm"
                              disabled={inProcess}
                              onClick={() => setEdit(false)}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
