import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import DeleteModal from './DeleteModal';
// import ListItemRestoreModal from './ListItemRestoreModal';
import useRequest from 'hooks/useRequest';
import SituDocModal from 'components/CrudListItemComponents/SituDocModal';
// import QRCode from 'react-qr-code';

// const BASE_BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

export default function ListItem({
  busId,
  busTitle,
  type,
  id,
  name,
  qr,
  sso,
  item,
  deleted,
  erreasing,
  edit,
  handleDelete,
  handleRestore,
  handleHardDelete
}) {
  const history = useHistory();
  const params = useParams();
  const { reqTokenBase } = useRequest();

  const customDeleteId = 'list-item-delete-' + id;
  const hashCustomDeleteId = '#' + customDeleteId.replaceAll(' ', '');

  // const customRestoreId = 'ListItemRestoreId' + id;
  // const hashCustomRestoreId = '#' + customRestoreId.replaceAll(' ', '');

  const customSituDocModalId = 'ListItemSituDocModalId' + id;
  const hashCustomSituDocModalId =
    '#' + customSituDocModalId.replaceAll(' ', '');

  const [situDocInfo, setSituDocInfo] = useState(null);

  const [hardDelete, setHardDelete] = useState(false);

  const [isDeleted, setIsDeleted] = useState(deleted);

  const handleEdit = () => {
    // path="/modulebyid/:module/:id/:busId/:title"

    if (type === 'proveedores') {
      history.replace(
        `/module/business_admin/${id}/${name.replace(/[\s]/g, '-')}`
      );
      return;
    }

    if (type === 'formatos') {
      history.replace(
        `/module/types/${params.id}/${params.title}/info/${type}/${id}`
      );
      return;
    }

    history.replace(
      `/module/business_admin/${params.id}/${params.title}/info/${type}/${id}`
    );
  };

  const getSituDocInfo = async () => {
    if (situDocInfo) return;

    const res = await reqTokenBase.get(`administration/${type}/${id}/estado`);
    setSituDocInfo(res.data.data ? res.data.data : 'No existe');
  };

  // const handleQRDownload = () => {
  //   const svg = document.getElementById(`${id}_qr`);
  //   const svgData = new XMLSerializer().serializeToString(svg);
  //   const canvas = document.createElement('canvas');
  //   const ctx = canvas.getContext('2d');
  //   const img = new Image();
  //   img.onload = () => {
  //     canvas.width = img.width;
  //     canvas.height = img.height;
  //     ctx.drawImage(img, 0, 0);
  //     const pngFile = canvas.toDataURL('image/png');
  //     const downloadLink = document.createElement('a');
  //     downloadLink.download = `${name}-QR`;
  //     downloadLink.href = `${pngFile}`;
  //     downloadLink.click();
  //   };
  //   img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  // };

  const handleLocalDelete = () => {
    handleDelete(id);
    setIsDeleted(true);
  };

  const handleLocalRestore = () => {
    handleRestore(id);
    setIsDeleted(false);
  };

  const handleLocalHardDelete = () => {
    handleHardDelete(id);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      type === 'trabajadores' ? item.tra_varchar_dni : item.equ_varchar_serie
    );
  };

  return (
    <div className="row w-100 mx-0 px-0 border border-bottom-0 align-items-center hover-list-item">
      <span className="d-none d-md-block col-md-1 border-end py-2 text-center">
        {id}
      </span>

      <span
        className={`${
          type === 'trabajadores' || type === 'equipos'
            ? 'col-3 col-md-4'
            : 'col-6 col-md-8'
        } border-end py-2 text-truncate`}
      >
        {name}
      </span>

      {(type === 'trabajadores' || type === 'equipos') && (
        <span
          onClick={handleCopy}
          className="col-3 col-md-4 border-end py-2 text-truncate text-center"
          style={{ cursor: 'copy' }}
        >
          {type === 'trabajadores'
            ? item.tra_varchar_dni
            : item.equ_varchar_serie}
        </span>
      )}

      <div className="d-flex col-6 col-md-3 py-2 justify-content-center gap-2">
        {!isDeleted && (
          <>
            {(type === 'trabajadores' || type === 'equipos') && (
              <button
                type="button"
                className="btn btn-primary px-2 py-1"
                data-bs-toggle="modal"
                data-bs-target={hashCustomSituDocModalId}
                onClick={getSituDocInfo}
                title="Situación documental"
              >
                <i className="fas fa-list"></i>
              </button>
            )}

            {/* {(type === 'trabajadores' || type === 'equipos') && (
              <button
                onClick={handleQRDownload}
                type="button"
                className="btn btn-secondary px-2 py-1"
                disabled={erreasing ? true : false}
                title="Descargar QR"
              >
                <i className="fas fa-qrcode"></i>
                <QRCode
                  value={`${BASE_BASE_URL}gv/${qr}`}
                  className="d-none"
                  id={`${id}_qr`}
                />
              </button>
            )} */}

            <button
              onClick={handleEdit}
              type="button"
              className="btn btn-secondary btn-sm"
              disabled={erreasing ? true : false}
            >
              <i className="far fa-edit"></i>
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm"
              disabled={erreasing ? true : false}
              data-bs-toggle="modal"
              data-bs-target={hashCustomDeleteId}
            >
              <i className="far fa-trash-alt"></i>
            </button>

            {type === 'proveedores' && sso && (
              <a
                href={sso}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-warning d-flex align-items-center"
              >
                <i className="fas fa-sign-in-alt"></i>
              </a>
            )}
          </>
        )}

        {/* {deleted && type !== 'proveedores' && (
          <button
            type="button"
            className="btn btn-warning px-2 py-1"
            data-bs-toggle="modal"
            data-bs-target={hashCustomRestoreId}
            disabled={edit ? true : false}
          >
            Restaurar
          </button>
        )} */}

        {isDeleted && (
          <>
            {!hardDelete && (
              <button
                type="button"
                className="btn btn-warning px-2 py-1"
                onClick={handleLocalRestore}
                disabled={erreasing || edit}
              >
                Restaurar
              </button>
            )}

            {!hardDelete && (
              <button
                type="button"
                className="btn btn-danger px-2 py-1"
                onClick={() => setHardDelete(true)}
                disabled={erreasing}
                title="Borrar permanentemente"
              >
                <i className="fas fa-exclamation-triangle me-2"></i>
                <i className="far fa-trash-alt"></i>
              </button>
            )}

            {hardDelete && (
              <>
                <button
                  type="button"
                  className="btn btn-danger px-2 py-1"
                  onClick={handleLocalHardDelete}
                  disabled={erreasing}
                  title="Borrar permanentemente"
                >
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  Borrar
                </button>

                <button
                  type="button"
                  className="btn btn-secondary px-2 py-1"
                  onClick={() => setHardDelete(false)}
                  disabled={erreasing}
                >
                  Cancelar
                </button>
              </>
            )}
          </>
        )}
      </div>

      <DeleteModal
        customId={customDeleteId}
        title={name}
        id={id}
        handleDelete={handleLocalDelete}
        erreasing={erreasing}
      />

      {/* <ListItemRestoreModal
        id={id}
        key={customRestoreId}
        customId={customRestoreId}
        title={name}
        restoreFunction={handleRestore}
      /> */}

      <SituDocModal
        customId={customSituDocModalId}
        info={situDocInfo}
        type={type}
      />
    </div>
  );
}
