const table = {
  contacts_mkt: 'marketing/contacts',
  newsletter: 'marketing/newsletter',
  queue: 'marketing/newsletter/:id/towork',
  sent: 'marketing/newsletter/:id/sent'
};

export default function moduleTable(module, id) {
  const route = table[module];

  if (id) {
    const withId = route.replace(':id', id);
    return withId;
  }

  return route;
}
