import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import moduleTable from './tables/moduleTable';
import useRequest from 'hooks/useRequest';

import NewsletterForm from './NewsletterForm';
import redirectTable from './tables/redirectTable';
import FullWidthContainer from 'components/layout/FullWidthContainer';

export default function ModuleFormPageMkt() {
  const { module, id } = useParams();
  const { reqTokenBase, reqTokenBaseFormData } = useRequest();
  const history = useHistory();

  const [reqStatus, setReqStatus] = useState(null);
  const [data, setData] = useState(null);
  const [files, setFiles] = useState([]);
  const [requestLoading, setReqLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState({});
  const [response, setResponse] = useState(null);

  const getData = async () => {
    setReqLoading(true);

    const res = await reqTokenBase.get(`${moduleTable(module)}/${id}`);
    setData(res.data);

    setReqLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError({});
    setFormLoading(true);

    try {
      if (id !== '0') {
        // /marketing/files_newsletter
        if (module === 'newsletter') {
          const res = await reqTokenBase.put(
            `${moduleTable(module)}/${id}`,
            data
          );

          if (data.inQueue) {
            await reqTokenBase.get(
              `marketing/newsletter/${res.data.id}/enqueue`
            );
          }

          for (let index = 0; index < files.length; index++) {
            const formData = new FormData();
            formData.append('nlt_int_id', res.data.id);
            formData.append('file', files[index]);

            await reqTokenBaseFormData.post(
              `marketing/files_newsletter`,
              formData
            );
          }

          if (data.email) {
            setResponse(res.data);
            setFormLoading(false);
            window.scroll({ top: 0, behavior: 'smooth' });
            return;
          }

          history.push('/marketing/newsletter');
          return;
        }

        await reqTokenBase.put(`${moduleTable(module)}/${id}`, data);
      }

      if (id === '0') {
        if (module === 'newsletter') {
          if (data.bus_int_id === '') {
            setError({ ...error, bus_int_id: 'Debe seleccionar una empresa' });
            setFormLoading(true);
            return;
          }

          const res = await reqTokenBase.post(moduleTable(module), data);

          if (data.inQueue) {
            await reqTokenBase.get(`marketing/newsletter/${id}/enqueue`);
          }

          for (let index = 0; index < files.length; index++) {
            const formData = new FormData();
            formData.append('nlt_int_id', res.data.id);
            formData.append('file', files[index]);

            await reqTokenBaseFormData.post(
              `marketing/files_newsletter`,
              formData
            );
          }

          if (data.email) {
            setResponse(res.data);
            setFormLoading(false);
            window.scroll({ top: 0, behavior: 'smooth' });
            return;
          }

          history.push('/marketing/newsletter');
          return;
        }

        await reqTokenBase.post(moduleTable(module), data);
      }

      history.push('/marketing/newsletter');
    } catch (error) {
      console.log(error);

      if (error.response) {
        setError(error.response?.data.messages);
        setFormLoading(false);
        return;
      }

      setReqStatus(false);
      setFormLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    if (target.name === 'typ_int_id') {
      return setData({ ...data, [target.name]: target.value, for_int_id: '0' });
    }
    if (target.name === 'bus_int_id') {
      return setData({
        ...data,
        [target.name]: target.value,
        typ_int_id: '0',
        for_int_id: '0'
      });
    }
    setData({ ...data, [target.name]: target.value });
  };

  const handleGoBack = () => {
    history.replace(`${redirectTable(module)}/${module}`);
  };

  useEffect(() => {
    if (id !== '0') {
      return getData();
    }

    setReqLoading(false);
    //eslint-disable-next-line
  }, []);

  if (requestLoading) {
    return (
      <FullWidthContainer>
        <div className="py-5 d-flex align-items-center justify-content-center w-100 border bg-light shadow">
          <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </FullWidthContainer>
    );
  }

  return (
    <FullWidthContainer>
      <div className="w-100 d-flex flex-column align-items-center justify-content-center">
        {reqStatus === false && (
          <div className="alert alert-danger border text-center w-100 p-3">
            Un error ha ocurrido, inténtalo más tarde
          </div>
        )}

        {module === 'newsletter' && (
          <NewsletterForm
            id={id}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            loading={formLoading}
            error={error}
            setData={setData}
            setFiles={setFiles}
            handleGoBack={handleGoBack}
            response={response}
            {...data}
          />
        )}
      </div>
    </FullWidthContainer>
  );
}
