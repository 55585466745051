import React from 'react';
import { DefaultInput } from 'templates/input';
import SelectInputSearch from 'components/select-search/SelectInputSearch';

const titleTable = {
  business_admin: 'de la empresa',
  proveedores: 'del proveedor'
};

export default function DataInputs({
  module,
  id,
  errorData,
  handleInputChange,
  setValues,
  handleValidationChange,
  prevAuditor,
  auditorEdited,
  handleAuditor,

  bus_text_name,
  bus_text_identification,
  bus_text_address,
  bus_text_phone,
  bus_text_domain,

  use_text_user,
  use_text_email,
  use_text_pass,
  use_text_name,
  use_text_lastname,
  use_text_phone,
  bus_int_notgar
}) {
  return (
    <>
      <div className={`${id === '0' ? 'col-12 col-lg-6' : 'col-12'}`}>
        {id === '0' && <h4>Información {titleTable[module]}</h4>}
        {id !== '0' && module !== 'proveedores' && (
          <>
            <SelectInputSearch
              label="Auditor"
              classes="w-100 mb-2"
              url="business_auditors"
              defaultValue={prevAuditor}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="use_int_id"
              nameField="use_text_name"
              lastNameField="use_text_lastname"
              placeholder="Escoge un auditor"
              onChange={(item) => handleAuditor(item.value)}
            />
            {auditorEdited && (
              <p className="mb-0 mt-1 alert-success p-2 rounded text-center">
                Auditor asignado
              </p>
            )}
            <hr />
          </>
        )}
        <DefaultInput
          label="Nombre"
          type="text"
          value={bus_text_name || ''}
          inputName="bus_text_name"
          onChange={handleInputChange}
          placeholder="Ingresa un nombre"
          error={errorData?.bus_text_name}
        />
        <DefaultInput
          label="Identificación"
          type="text"
          value={bus_text_identification || ''}
          inputName="bus_text_identification"
          onChange={handleInputChange}
          placeholder="Ingresa una identificación"
          error={errorData?.bus_text_identification}
        />
        <DefaultInput
          label="Dirección"
          type="text"
          value={bus_text_address || ''}
          inputName="bus_text_address"
          onChange={handleInputChange}
          placeholder="Ingresa una dirección"
          error={errorData?.bus_text_address}
        />
        <DefaultInput
          label="Teléfono"
          type="text"
          value={bus_text_phone || ''}
          inputName="bus_text_phone"
          onChange={handleInputChange}
          placeholder="Inerta un teléfono"
          error={errorData?.bus_text_phone}
        />
        <DefaultInput
          label="Dominio"
          value={bus_text_domain || ''}
          inputName="bus_text_domain"
          onChange={handleInputChange}
          placeholder="@dominio"
          error={errorData?.bus_text_domain}
          disabled={id !== '0' ? true : false}
        />

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            checked={bus_int_notgar === '1' ? true : false}
            id="bus_int_notgar"
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                bus_int_notgar: e.target.checked ? '1' : '0'
              }))
            }
          />
          <label class="form-check-label" for="bus_int_notgar">
            Notificar paso por garita
          </label>
        </div>
      </div>

      {id === '0' && (
        <div className="col-12 col-lg-6">
          <h4>Información de usuario</h4>
          {/* <DefaultInput
            label="Usuario de acceso"
            type="text"
            inputName="use_text_user"
            value={use_text_user}
            onChange={handleValidationChange}
            placeholder="Ingresa un usuario"
            error={errorData?.use_text_user}
          /> */}

          <label htmlFor="use_text_user" className="form-label fw-bold">
            Usuario de acceso
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              name="use_text_user"
              defaultValue={use_text_user}
              onChange={handleInputChange}
              className={`form-control input-bg-dark ${
                errorData?.use_text_user && 'is-invalid'
              }`}
              placeholder="Usuario para inicio de sesión"
              aria-label="Usuario de acceso"
              aria-describedby="access_user_input"
            />
            {bus_text_domain && (
              <span className="input-group-text" id="access_user_input">
                {bus_text_domain}
              </span>
            )}
            {errorData?.use_text_user && (
              <p className="mt-2 text-danger w-100 mb-0">
                {errorData?.use_text_user}
              </p>
            )}
          </div>

          <DefaultInput
            label="Correo electrónico"
            type="email"
            value={use_text_email}
            inputName="use_text_email"
            onChange={handleInputChange}
            placeholder="Ingresa un usuario"
            error={errorData?.use_text_email}
          />

          <DefaultInput
            label="Contraseña"
            type="password"
            value={use_text_pass}
            inputName="use_text_pass"
            onChange={handleInputChange}
            placeholder="Ingresa una contraseña"
            error={errorData?.use_text_pass}
            required={false}
          />

          <DefaultInput
            label="Nombre"
            type="text"
            value={use_text_name}
            inputName="use_text_name"
            onChange={handleInputChange}
            placeholder="Ingresa un nombre"
            error={errorData?.use_text_name}
          />

          <DefaultInput
            label="Apellido"
            type="text"
            value={use_text_lastname}
            inputName="use_text_lastname"
            onChange={handleInputChange}
            placeholder="Ingresa un apellido"
            error={errorData?.use_text_lastname}
          />

          <DefaultInput
            label="Teléfono"
            type="number"
            value={use_text_phone}
            inputName="use_text_phone"
            onChange={handleInputChange}
            placeholder="Ingresa un número telefónico"
            error={errorData?.use_text_phone}
          />
        </div>
      )}
    </>
  );
}
