import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import { useForm } from 'hooks/useForm';
import { DefaultFile, DefaultTextarea } from 'templates/input';

// import Loading from "components/layout/Loading";
import AlertMessage from 'components/layout/AlertMessage';
import RecordRow from 'components/situ-doc-components/RecordRow';
import ValidationTable from './ValidationTable';
import { DELETE } from 'helpers/request';

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;

const defaultValues = {
  file: '',
  rec_text_obs: '',
  cat_int_id: ''
};

export default function SituDocItemCategory({
  data,
  goBack,
  businessData,
  getCategoryInfo
}) {
  const [values, handleInputChange, reset] = useForm(defaultValues);
  const [registros, setRegistros] = useState(data.registros);
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [fileObj, setFile] = useState(null);
  const [percent, setPercent] = useState(0);

  const goBackButtonRef = useRef();

  const customId = `cat_del_modal_${data.categorias.cat_int_id}`;

  const [havePendingDocs, setHavePendingDocs] = useState(
    registros.filter((item) => item.rec_int_status === '1').length > 0
  );

  const handleDelete = async () => {
    setLoading(true);

    const res = await DELETE(
      'situacion_documental_admin',
      data.categorias.cat_int_id
    );

    if (res.status === 200) {
      setLoading(false);
      goBackButtonRef?.current.click();
    }

    setLoading(false);
  };

  const handleFileChange = ({ target }) => {
    setFile(target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    setError(defaultValues);

    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setPercent(percent);
        }
      }
    };

    const formData = new FormData();
    formData.append('rec_text_obs', values.rec_text_obs);
    formData.append('cat_int_id', data.categorias.cat_int_id);
    formData.append('file', fileObj);

    try {
      const { data } = await axios.post(`${BASE_URL}records`, formData, config);

      setSuccess(data ? data.status : 500);
      reset();
      setLoading(false);
      setFile(null);

      // for progress bar
      setPercent(100);
      setTimeout(() => setPercent(0), 3000);
    } catch (error) {
      setError({
        ...errorData,
        file: 'Un error ocurrió al almacenar el archivo, inténtalo más tarde'
      });
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(null);
        // goBackButtonRef?.current.click();
        document.getElementById('situ-doc-cat-input-file').value = '';
        getCategoryInfo(data.categorias.cat_int_id);
      }, 3000);
    }

    //eslint-disable-next-line
  }, [success]);

  const { rec_text_obs } = values;

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <ItemDeleteModal
        customId={customId}
        deleteFunction={handleDelete}
        title={data.categorias.cat_text_name}
        loading={loading}
      />

      <h3 className="w-100 text-center mt-3 mb-1">
        {/* <span className="fw-bold me-2">Categoría:</span> */}
        {data.categorias?.cat_text_name}
      </h3>

      <p className="text-center mb-3">
        {data.categorias?.cat_text_description}
      </p>

      <div className="d-flex justify-content-between align-items-center mb-4 w-100">
        <button
          onClick={goBack}
          ref={goBackButtonRef}
          type="button"
          className="btn btn-secondary d-flex align-items-center"
          disabled={loading ? true : false}
        >
          <div className="fas fa-caret-left fa-lg me-2"></div>
          Regresar
        </button>

        <button
          data-bs-toggle="modal"
          data-bs-target={`#${customId}`}
          type="button"
          className="btn btn-outline-danger d-flex align-items-center ms-auto "
          disabled={loading ? true : false}
        >
          Eliminar categoría
          <div className="fas fa-trash-alt ms-2"></div>
        </button>

        <button
          type="button"
          className="btn btn-success ms-3"
          data-bs-toggle="collapse"
          data-bs-target="#newRecordForm"
          aria-expanded="false"
          aria-controls="newRecordForm"
        >
          Nuevo registro <i className="fas fa-plus ms-2"></i>
        </button>
      </div>

      <section className="collapse mb-3 w-100" id="newRecordForm">
        <form
          onSubmit={handleSubmit}
          className="w-100 d-flex flex-column position-relative p-3 border rounded"
          autoComplete="off"
        >
          {havePendingDocs && (
            <div className="alert alert-danger text-center border py-3 m-0">
              Tiene un documento pendiente por validar
            </div>
          )}

          {!havePendingDocs && (
            <>
              {success && (
                <AlertMessage
                  status={success}
                  id={data.categorias.cat_int_id}
                />
              )}

              <DefaultFile
                customClass="col-12"
                label="Documento"
                inputName="file"
                id="situ-doc-cat-input-file"
                extensions="*"
                onChange={handleFileChange}
                error={errorData?.file}
              />

              {percent ? (
                <div className="progress mb-3">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${percent}%` }}
                    aria-valuenow={percent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {percent}%
                  </div>
                </div>
              ) : null}

              <DefaultTextarea
                label="Observaciones"
                placeholder="Detalla tus observaciones o comentarios"
                rows="3"
                inputName="rec_text_obs"
                onChange={handleInputChange}
                value={rec_text_obs}
                error={errorData?.rec_text_obs}
                required={false}
              />

              <button
                type="submit"
                className="btn btn-success"
                disabled={(!fileObj || loading) && true}
              >
                Subir archivo
              </button>
            </>
          )}
        </form>
      </section>

      <div className="row w-100 mx-0 px-0 border rounded bg-secondary mb-2">
        <div className="col-8 col-md-6 py-2 border-end fw-bold text-white">
          Registros
        </div>
        <div className="d-none d-md-block py-2 border-end col-md-2 fw-bold text-white text-center">
          Creado
        </div>
        <div className="d-none d-md-block py-2 border-end col-md-2 fw-bold text-white text-center">
          Expira
        </div>
        <div className="col-4 col-md-2 py-2 fw-bold text-white text-center">
          Opciones
        </div>
      </div>

      {registros.length > 0 ? (
        registros.map((item) => (
          <RecordRow
            key={item.rec_int_id}
            item={item}
            auditor={data.auditor}
            setRegistros={setRegistros}
            setHavePendingDocs={setHavePendingDocs}
            categoryExpiration={data.categorias.cat_int_days}
            categoryName={data.categorias?.cat_text_name}
            categoryDesc={data.categorias?.cat_text_description}
          />
        ))
      ) : (
        <p className="w-100 mb-0 text-center">No hay registros previos</p>
      )}

      <div className="d-flex gap-5 mb-3">
        <ValidationTable />
      </div>
    </div>
  );
}

const ItemDeleteModal = ({ customId, title, deleteFunction, loading }) => {
  return (
    <div
      className="modal fade"
      id={customId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5
              className="modal-title gap-3 text-danger d-flex align-items-center"
              id="deleteModalLabel"
            >
              Advertencia
              <i className="fas fa-exclamation-triangle"></i>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p className="mb-0 text-center">
              Estás por borrar esta categoría
              <br />
              <span className="fw-bold">{title}</span>
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              disabled={loading ? true : false}
            >
              Cancelar
            </button>
            <button
              onClick={deleteFunction}
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              disabled={loading ? true : false}
            >
              Borrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
