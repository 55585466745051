import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { POST, DWNLD_POST } from 'helpers/request';
import InputText from 'inputs/InputText';
import Autocomplete from 'components/select-search/Autocomplete';
import { evaluate, sum } from 'mathjs';

export default function ReportDesempeno() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [busId, setBusId] = useState('');
  const [typeId, setTypeId] = useState('');

  const [typeTxt, setTypetxt] = useState('');
  const [weight, setWeight] = useState({
    requisito: '30',
    empresa: '50',
    trabajador: '30',
    equipo: '20'
  });

  const getData = async (paramTypeId) => {
    setLoading(true);
    setData([]);
    setTypeId(paramTypeId);

    const res = await POST(
      { cual: '10', bus_int_id: busId, typ_int_id: paramTypeId, xls: '0' },
      // { cual: '10', bus_int_id: '353', typ_int_id: '21', xls: '0' },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    if (res[0].valor) {
      setData(res[0].valor);
      setTypetxt(res.cab.Requisito);
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const colorSelector = (value) => {
    let number = parseInt(value);

    if (number <= 60) return 'danger';
    if (number >= 81) return 'success';
    return 'warning';
  };

  const handleDescargar = () => {
    DWNLD_POST(
      'reports',
      `reportedesempeno${busId}-${typeId}.xlsx`,
      10,
      busId,
      typeId
    );
  };

  // prettier-ignore
  const calculoDesempeno = (item) => {
    // Requisito entregados / requeridos
    const empresa_e = parseInt(item.empresa_e);
    const empresa_r = parseInt(item.empresa_r);

    const trabajador_e = parseInt(item.trabajador_e);
    const trabajador_r = parseInt(item.trabajador_r);

    const equipo_e = parseInt(item.equipo_e);
    const equipo_r = parseInt(item.equipo_r);
    // Otros requisitos entregados / requeridos

    // Otros requisitos entregados / requeridos
    const empresa_e_no = parseInt(item.empresa_e_no);
    const empresa_r_no = parseInt(item.empresa_r_no);

    const trabajador_e_no = parseInt(item.trabajador_e_no);
    const trabajador_r_no = parseInt(item.trabajador_r_no);

    const equipo_e_no = parseInt(item.equipo_e_no);
    const equipo_r_no = parseInt(item.equipo_r_no);
    // Otros requisitos entregados / requeridos

    // * Calculo requisito
    // prettier-ignore
    const empresa_requisito =    empresa_r === 0 ? 100 : evaluate(`(${empresa_e} / ${empresa_r}) * 100`);
    // prettier-ignore
    const trabajador_requisito = trabajador_r === 0 ? 100 : evaluate(`(${trabajador_e} / ${trabajador_r}) * 100`);
    // prettier-ignore
    const equipo_requisito =     equipo_r === 0 ? 100 : evaluate(`(${equipo_e} / ${equipo_r}) * 100`);

    // prettier-ignore
    const cumplimiento_empresa =    evaluate(`(${empresa_requisito} * ${weight.empresa}) / 100`)
    // prettier-ignore
    const cumplimiento_trabajador = evaluate(`(${trabajador_requisito} * ${weight.trabajador}) / 100`);
    // prettier-ignore
    const cumplimiento_equipo =     evaluate(`(${equipo_requisito} * ${weight.equipo}) / 100`);
    // * Calculo requisito

    // * Calculo otros
    // prettier-ignore
    const empresa_otros =    empresa_r_no === 0 ? 100 : evaluate(`(${empresa_e_no} / ${empresa_r_no}) * 100`);
    // prettier-ignore
    const trabajador_otros = trabajador_r_no === 0 ? 100 : evaluate(`(${trabajador_e_no} / ${trabajador_r_no}) * 100`);
    // prettier-ignore
    const equipo_otros =     equipo_r_no === 0 ? 100 : evaluate(`(${equipo_e_no} / ${equipo_r_no}) * 100`);

    // prettier-ignore
    const cumplimiento_empresa_otros =    evaluate(`(${empresa_otros} * ${weight.empresa}) / 100`)
    // prettier-ignore
    const cumplimiento_trabajador_otros = evaluate(`(${trabajador_otros} * ${weight.trabajador}) / 100`);
    // prettier-ignore
    const cumplimiento_equipo_otros =     evaluate(`(${equipo_otros} * ${weight.equipo}) / 100`);
    // * Calculo otros

    // prettier-ignore
    const cumplimiento_requisito = evaluate(`((${cumplimiento_empresa} + ${cumplimiento_trabajador} + ${cumplimiento_equipo}) * ${getTotalWeight().requisito}) / 100`);
    // prettier-ignore
    const cumplimiento_otros =     evaluate(`((${cumplimiento_empresa_otros} + ${cumplimiento_trabajador_otros} + ${cumplimiento_equipo_otros}) * ${getTotalWeight().restante}) / 100`);
    // prettier-ignore
    const desempeno =              evaluate(`${cumplimiento_requisito} + ${cumplimiento_otros}`);

    // prettier-ignore
    return {
      cumplimiento_requisito: + parseFloat(cumplimiento_requisito).toFixed(2),
      cumplimiento_otros:     + parseFloat(cumplimiento_otros).toFixed(2),
      desempeno:              + parseFloat(desempeno).toFixed(2)
    };
  };

  const getTotalWeight = () => {
    const suma_otros = sum(weight.empresa, weight.equipo, weight.trabajador);

    const otros = evaluate(`(${suma_otros} / 100) * 100`);

    const restante = evaluate(`100 - ${weight.requisito}`);

    const general = sum(weight.requisito, restante);

    // prettier-ignore
    return {
      requisito: weight.requisito,
      otros:    + parseInt(otros).toFixed(2) ,
      general:  + parseInt(general).toFixed(2) ,
      restante: + parseInt(restante).toFixed(2) 
    };
  };

  const validate_number = (value) => {
    if (value.length < 1) return 0;
    if (value < 0) return 0;
    if (isNaN(value)) return;
    return +value;
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">Reporte Desempeño</h2>

      <div style={{ position: 'sticky', top: '20px' }}>
        <div className="w-100 mb-3 d-flex flex-wrap gap-3 align-items-end">
          <Autocomplete
            label="Selecciona una empresa"
            classes="flex-grow-1"
            url="business_admin"
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              setBusId('');
              setData(null);
              setTypeId('');

              setTimeout(() => {
                setBusId(item.value);
              }, 100);
            }}
          />

          {busId && (
            <Autocomplete
              label="Tipo de requisito"
              classes="flex-grow-1"
              url={`business_types/${busId}`}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="typ_int_id"
              nameField="typ_text_name"
              placeholder="Escoja un tipo"
              onChange={(item) => getData(item.value)}
            />
          )}

          {busId && typeId && data.length > 0 && (
            <button
              onClick={handleDescargar}
              type="button"
              className="btn btn-primary"
              title="Descargar"
            >
              <i className="fas fa-download me-2"></i> Descargar
            </button>
          )}
        </div>

        {!loading && data && data.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Desc:</th>
                <th scope="col" className="text-center">
                  Requisito
                </th>
                <th scope="col" className="text-center">
                  Empresa
                </th>
                <th scope="col" className="text-center">
                  Trabajador
                </th>
                <th scope="col" className="text-center">
                  Equipo
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Peso:</th>
                <td>
                  <InputText
                    value={weight.requisito}
                    onChange={({ target }) =>
                      setWeight({
                        ...weight,
                        requisito: validate_number(target.value)
                      })
                    }
                    type="number"
                  />
                </td>
                <td>
                  <InputText
                    value={weight.empresa}
                    onChange={({ target }) =>
                      setWeight({
                        ...weight,
                        empresa: validate_number(target.value)
                      })
                    }
                    type="number"
                  />
                </td>
                <td>
                  <InputText
                    value={weight.trabajador}
                    onChange={({ target }) =>
                      setWeight({
                        ...weight,
                        trabajador: validate_number(target.value)
                      })
                    }
                    type="number"
                  />
                </td>
                <td>
                  <InputText
                    value={weight.equipo}
                    onChange={({ target }) =>
                      setWeight({
                        ...weight,
                        equipo: validate_number(target.value)
                      })
                    }
                    type="number"
                  />
                </td>
              </tr>
              <tr>
                <th scope="row">Total:</th>
                <td>
                  <InputText
                    disabled
                    value={getTotalWeight().requisito}
                    isInvalid={getTotalWeight().requisito > 100 ? true : false}
                  />
                </td>
                <td colSpan={3}>
                  <InputText
                    disabled
                    value={`${getTotalWeight().otros} % del ${
                      getTotalWeight().restante
                    } % restante`}
                    isInvalid={getTotalWeight().otros !== 100 ? true : false}
                  />
                  <p className="m-0">
                    La suma de los otros requisitos debe ser del 100 % del
                    porcentaje restante respecto a "Requisito"
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading &&
        data &&
        data.length > 0 &&
        data.map((item) => {
          const { cumplimiento_requisito, cumplimiento_otros, desempeno } =
            calculoDesempeno(item);

          return (
            <div
              key={item.bus_int_id}
              className="border d-flex flex-column w-100 p-3"
            >
              <Link
                to={`/situacionDocumental/${item.bus_int_id}/0/0`}
                className="mb-0 w-100 text-center word-break fw-bold sit-doc-item-row py-2 text-decoration-none text-darkmode"
              >
                <h6 className="w-100 text-center mb-0">{item.bus_text_name}</h6>
              </Link>

              <hr />

              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Empresa</th>
                    <th scope="col">Trabajador</th>
                    <th scope="col">Equipo</th>
                    <th scope="col">Cumplimiento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{typeTxt}</th>
                    <td>
                      {item.empresa_e}/{item.empresa_r}
                    </td>
                    <td>
                      {item.trabajador_e}/{item.trabajador_r}
                    </td>
                    <td>
                      {item.equipo_e}/{item.equipo_r}
                    </td>
                    <td>
                      {cumplimiento_requisito} % de {getTotalWeight().requisito}{' '}
                      %
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Otros requisitos</th>
                    <td>
                      {item.empresa_e_no}/{item.empresa_r_no}
                    </td>
                    <td>
                      {item.trabajador_e_no}/{item.trabajador_r_no}
                    </td>
                    <td>
                      {item.equipo_e_no}/{item.equipo_r_no}
                    </td>
                    <td>
                      {cumplimiento_otros} % de {getTotalWeight().otros} %
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="row">Desempeño</th>
                    <td colSpan={4} align="center">
                      <div className="progress" style={{ height: '20px' }}>
                        <div
                          className={`progress-bar bg-${colorSelector(
                            desempeno
                          )}`}
                          role="progressbar"
                          style={{
                            width: `${desempeno}%`,
                            fontSize: '12px'
                          }}
                          aria-valuenow={desempeno}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {desempeno}%
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          );
        })}
    </FullWidthContainer>
  );
}
