import React, { useEffect, useState } from "react";
import { GET, POST, PUT } from "helpers/request";
import { useHistory } from "react-router";
import { useForm } from "hooks/useForm";

import { DefaultInput, DefaultSelect } from "templates/input";

import AlertMessage from "components/layout/AlertMessage";
import RelationEditionList from "components/EditionGroups/RelationEditionList";

const defaultValues = {
  cat_int_pid: "",
  cat_text_name: "",
  cat_text_description: "",
  bus_int_id: "",
  cat_int_days: "",
  cat_int_days_warning: "",
  typ_int_id: "",
};

export default function CategoriesForm({ module, id, data }) {
  const [values, handleInputChange, reset] = useForm(data || defaultValues);
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  const [business, setBusiness] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const businessData = await GET("business?l=999&0=0");
        setBusiness(
          businessData.datos.map(({ bus_int_id, bus_text_name }) => ({
            value: bus_int_id,
            name: bus_text_name,
          }))
        );

        const typesData = await GET("types?l=999&0=0");
        setTypes(
          typesData.datos.map(({ typ_int_id, typ_text_name }) => ({
            value: typ_int_id,
            name: typ_text_name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetcher();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    if (id === "0") {
      const data = await POST(values, module, setError, reset);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setLoading(false);
      setSuccess(data ? data.status : 500);
    }

    //prettier-ignore
    if (id !== "0") {
      const data = await PUT(defaultValues, values, module, id, setError, reset);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setLoading(false)
      setSuccess(data ? data.status : 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        history.goBack();
      }, 3000);
    }
  }, [success, history]);

  const {
    cat_text_name,
    cat_text_description,
    cat_int_pid,
    bus_int_id,
    cat_int_days,
    cat_int_days_warning,
    typ_int_id,
  } = values;

  return (
    <div className="row w-100 mx-0">
      {id !== "0" ? (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Editar categoría</h2>
      ) : (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Nueva categoría</h2>
      )}

      <form
        onSubmit={handleSubmit}
        className={`${
          id !== "0" ? "col-7" : "col-12"
        } col-7 px-3 px-xl-5 d-flex flex-column`}
        autoComplete="off"
      >
        {success && <AlertMessage status={success} id={id} />}

        <DefaultInput
          label="Nombre"
          type="text"
          value={cat_text_name || ""}
          inputName="cat_text_name"
          onChange={handleInputChange}
          placeholder="Ingresa un nombre"
          error={errorData?.cat_text_name}
        />

        <DefaultInput
          label="Descripción"
          type="text"
          value={cat_text_description || ""}
          inputName="cat_text_description"
          onChange={handleInputChange}
          placeholder="Ingresa una descripción"
          error={errorData?.cat_text_description}
        />

        <DefaultInput
          label="ProductId"
          type="text"
          value={cat_int_pid || ""}
          inputName="cat_int_pid"
          onChange={handleInputChange}
          placeholder="Ingresa un id"
          error={errorData?.cat_int_pid}
        />

        <DefaultSelect
          label="Empresa"
          value={bus_int_id || ""}
          inputName="bus_int_id"
          placeholder="Seleccionar una opción"
          onChange={handleInputChange}
          error={errorData?.bus_int_id}
          options={business}
        />

        <DefaultSelect
          label="Tipo"
          value={typ_int_id || ""}
          inputName="typ_int_id"
          placeholder="Seleccionar una opción"
          onChange={handleInputChange}
          error={errorData?.typ_int_id}
          options={types}
        />

        <DefaultInput
          label="cat_int_days"
          type="text"
          value={cat_int_days || ""}
          inputName="cat_int_days"
          onChange={handleInputChange}
          placeholder="Ingresaun nombre"
          error={errorData?.cat_int_days}
        />

        <DefaultInput
          label="cat_int_days_warning"
          type="text"
          value={cat_int_days_warning || ""}
          inputName="cat_int_days_warning"
          onChange={handleInputChange}
          placeholder="Ingresa un nombre"
          error={errorData?.cat_int_days_warning}
        />

        <button
          type="submit"
          className="btn btn-primary mt-4"
          disabled={loading ? true : false}
        >
          {loading ? (
            <>
              <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
            </>
          ) : id !== "0" ? (
            "Editar"
          ) : (
            "Crear"
          )}
        </button>
      </form>

      {id !== "0" && <RelationEditionList groupFor="categories" id={id} />}
    </div>
  );
}
