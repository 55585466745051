import FullWidthContainer from 'components/layout/FullWidthContainer';
import Autocomplete from 'components/select-search/Autocomplete';
import { DWNLD_POST_V2, POST } from 'helpers/request';
import { useState } from 'react';
import { DefaultInput } from 'templates/input';

export default function Reporte_23() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [type, setType] = useState(null);
  const [format, setFormat] = useState(null);
  const [date, setDate] = useState(null);

  const getData = async () => {
    setLoading(true);
    setData([]);

    const res = await POST(
      {
        cual: '23',
        bus_int_pid: business.value,
        typ_int_id: type.value,
        for_int_id: format.value,
        date: date,
        xls: '0'
      },
      // { cual: '10', bus_int_id: '353', typ_int_id: '21', xls: '0' },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    const response = res['0'];

    if (response) {
      setData(response.valor);
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const handleDescargar = () => {
    DWNLD_POST_V2(
      'reports',
      `reporte-revision-docs-${business.label}-${date}.xlsx`,
      23,
      {
        cual: '23',
        bus_int_pid: business.value,
        typ_int_id: type.value,
        for_int_id: format.value,
        date: date,
        xls: '0'
      }
    );
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">
        Revisión de documentos por empresa y mes
      </h2>

      <div className="d-flex align-items-end gap-3 mb-3">
        <Autocomplete
          label="Selecciona una empresa"
          classes="flex-grow-1"
          url="business_admin"
          //son los nombres de los campos de el esquema, de id y de nombre
          valueField="bus_int_id"
          nameField="bus_text_name"
          placeholder="Escoja una opción"
          onChange={(item) => {
            setBusiness(item);
          }}
        />

        {business && (
          <Autocomplete
            label="Tipo de requisito"
            classes="flex-grow-1"
            url={`business_types/${business.value}`}
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="typ_int_id"
            nameField="typ_text_name"
            placeholder="Escoja un tipo"
            onChange={(item) => setType(item)}
          />
        )}

        {type && (
          <Autocomplete
            label="Formato"
            classes="flex-grow-1"
            url={`formatsxbusiness/${type.value}/${business.value}`}
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="for_int_id"
            nameField="for_text_name"
            placeholder="Escoja un formato"
            onChange={(item) => setFormat(item)}
          />
        )}

        <DefaultInput
          label="Fecha"
          type="date"
          mb="0"
          onChange={(e) => setDate(e.target.value)}
        />

        {business && type && format && date && (
          <button type="button" onClick={getData} className="btn btn-primary">
            Buscar
          </button>
        )}

        {!loading && data && (
          <button
            onClick={handleDescargar}
            type="button"
            className="btn btn-success d-flex align-items-center"
          >
            <i className="fas fa-download me-2"></i>Excel
          </button>
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading && data && (
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Proveedor</th>
              <th scope="col">RUC</th>
              <th scope="col">Trabajadores</th>
              <th scope="col">Residentes</th>
              <th scope="col">Ingresaron</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.bus_int_id}>
                <td>{item.bus_text_name}</td>
                <td>{item.bus_text_identification}</td>
                <td>{item.totaltrabajadores}</td>
                <td>{item.totaltrabajadoresresidentes}</td>
                <td>{item.totaltrabajadoresingresaron}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FullWidthContainer>
  );
}
