import React from 'react';

export default function PdfViewerModal({
  id,
  pdf,
  img,
  info,
  loadingFile,
  recordId
}) {
  const customId = 'pdf-viewer-rec' + id;

  return (
    <div
      className="modal fade"
      id={customId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby={customId + 'label'}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down modal-dialog-scrollable">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5 className="modal-title text-uppercase" id={customId + 'label'}>
              {info.categoryName || 'Ver archivo'}
            </h5>
            <button
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body row mx-0 g-2">
            {!loadingFile && !img && !pdf && (
              <h2
                className={`text-center text-warning my-4 col-12 col-md-${
                  !info ? '12' : '8'
                } my-auto`}
              >
                No se puede previsualizar el documento
              </h2>
            )}

            {loadingFile && (
              <h2
                className={`text-center my-4 col-12 col-md-${
                  !info ? '12' : '8'
                } my-auto`}
              >
                Cargando archivo...
              </h2>
            )}

            {pdf && (
              <div className={`col-12 col-md-${!info ? '12' : '8'}`}>
                <embed
                  src={pdf}
                  // src={`data:application/pdf;base64,${pdf}`}
                  type="application/pdf"
                  width="100%"
                  height="700px"
                  aria-label="Visualizador de pdf"
                  title="Visualizador de pdf"
                  className="flex-grow-1"
                />
              </div>
            )}

            {img && (
              <div
                className={`col-12 col-md-${
                  !info ? '12' : '8'
                } d-flex justify-content-center`}
              >
                <img src={img} alt="" height="400" className="w-auto mx-auto" />
              </div>
            )}

            {info && (
              <div className="d-flex flex-column align-items-start justify-content-center ps-md-4 col-12 col-md-4">
                <p>
                  <span className="fw-bold me-2">Descripción:</span>
                  {info.categoryDesc}
                </p>
                <hr />
                <p className="mb-0">
                  <span className="fw-bold me-2">Nombre:</span>
                  {info.name}
                </p>
                <hr />
                <p className="mb-0">
                  <span className="fw-bold me-2">Expira:</span>
                  {info.expiration.includes('3050')
                    ? 'No expira'
                    : info.expiration}
                </p>
                <hr />
                <p className="mb-0">
                  <span className="fw-bold me-2">Observaciones:</span>
                  {info.observations || '---'}
                </p>
              </div>
            )}
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-target={'#accep_deny_modal' + recordId}
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
            >
              Revisar <i className="fas fa-eye ms-2"></i>
            </button>

            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
