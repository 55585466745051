export default function InputCheck({
  className,
  id,
  name,
  onChange,
  label,
  checked,
  disabled = false,
}) {
  return (
    <div className="form-check">
      <input
        onChange={onChange}
        className="form-check-input"
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        disabled={disabled}
      />
      <label className="form-check-label text-break" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
