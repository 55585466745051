import { useEffect, useState } from "react";
import useRequest from "hooks/useRequest";

const typesTable = {
  trabajador: "3",
  equipo: "4",
};

export default function useRelations(id, type, busId) {
  const { reqTokenBase } = useRequest();

  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);

  const [assigning, setAssigning] = useState(false);

  const getData = async () => {
    try {
      const res = await reqTokenBase.get(
        `administration/typesxwoh/${id}/${typesTable[type]}/${busId}`
      );

      setData(res.data?.valores);
      setOriginalData(res.data?.valores);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleEdit = async (itemId, status) => {
    if (assigning || status === 0) return;
    setAssigning(true);

    try {
      if (status !== null && status !== false) {
        await reqTokenBase.delete(
          `administration/typesxwoh/${itemId}/${id}/${typesTable[type]}`
        );

        setData(
          data.map((item) =>
            item.out_type_int_id === itemId
              ? { ...item, out_estado: false }
              : item
          )
        );

        setAssigning(false);

        return;
      }

      const res = await reqTokenBase.post(`administration/typesxwoh`, {
        typ_int_id: itemId,
        bus_int_id: busId,
        woh_int_id: id,
        for_int_tipo: typesTable[type],
      });

      if (res.data.messages.includes("No hay formatos")) {
        setData(
          data.map((item) =>
            item.out_type_int_id === itemId ? { ...item, out_estado: 0 } : item
          )
        );

        setAssigning(false);

        return;
      }

      setData(
        data.map((item) =>
          item.out_type_int_id === itemId ? { ...item, out_estado: true } : item
        )
      );

      setAssigning(false);
    } catch (error) {
      console.log(error);
      setAssigning(false);
    }
  };

  const handleFilter = (word) => {
    setData(
      originalData.filter((item) =>
        item.out_typ_text_name
          .toLocaleLowerCase()
          .includes(word.toLocaleLowerCase())
      )
    );
  };

  useEffect(() => {
    getData();

    //eslint-disable-next-line
  }, []);

  return { loading, data, assigning, handleEdit, handleFilter };
}
