import React, { useEffect, useRef, useState } from 'react';

import useRequest from 'hooks/useRequest';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { DefaultInput, DefaultSelect } from 'templates/input';
import useScollContainer from 'hooks/useScrollContainer';
import SubmitErrorModal from 'components/cargar-datos-components/SubmitErrorModal';
import InfiniteScroll from 'react-infinite-scroll-component';

const fileTitleTable = {
  business_users: 'Formato-proveedor-usuario.xlsx',
  business: 'Formato-empresas.xlsx',
  equipos: 'Formato-equipos.xlsx',
  trabajadores: 'Formato-trabajadores.xlsx'
};

export default function CargarDatos() {
  const { reqTokenBaseFormData, reqDownload, donwloadFile, reqToken } =
    useRequest();

  const {
    data,
    // error,
    // keyword,
    // setKeyword,
    hasMore,
    erreasing,
    // edit,
    // getFilter,
    handleScroll,
    handleDelete,
    // handleRestore,

    getInitialData
  } = useScollContainer({
    type: 'carga',
    url: 'cargas/uploads',
    deleteUrl: 'cargas/uploads'
  });

  const errorModalRef = useRef();

  const [request, setRequest] = useState({
    upl_char_que: 'business_users',
    ref_int_id: '',
    file: ''
  });

  const [formLoading, setFormLoading] = useState(false);
  // const [requestError, setRequestError] = useState(null);
  const [, setRequestError] = useState(null);
  const [parentBusId, setParentBusId] = useState('');
  const [noBusiness, setNoBusiness] = useState(false);
  const [business, setBusiness] = useState([]);
  const [providers, setProviders] = useState([]);
  const [contractors, setContractors] = useState([]);

  const getBusiness = async () => {
    const res = await reqToken.get('business_admin?l=50000&o=0');
    const businessList = res.data.datos;

    setBusiness(businessList);
  };

  const handleTableChange = ({ target }) => {
    if (target.value === 'business_users') {
      setRequest({
        ...request,
        upl_char_que: target.value,
        ref_int_id: parentBusId
      });

      // setProviders([]);
      setContractors([]);

      return;
    }

    setRequest({
      ...request,
      upl_char_que: target.value
    });
  };

  const handleFileChange = ({ target }) => {
    if (target.files?.length === 0) {
      return;
    }

    setRequest({ ...request, file: target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNoBusiness(false);

    if (request.ref_int_id === '') {
      setNoBusiness(true);
      return;
    }

    setRequestError(null);
    setFormLoading(true);

    const formData = new FormData();
    formData.append(
      'upl_char_que',
      request.upl_char_que === 'colaboradores'
        ? 'trabajadores'
        : request.upl_char_que
    );
    formData.append('ref_int_id', request.ref_int_id);
    formData.append('file', request.file);

    try {
      let req;

      if (request.upl_char_que === 'colaboradores') {
        req = reqTokenBaseFormData.post('cargas/trabajadores', formData);
      } else {
        req = reqTokenBaseFormData.post('cargas/uploads', formData);
      }

      await req;

      setRequest({ ...request, file: '' });
      document.getElementById('massive-file-input').value = '';

      setFormLoading(false);
      getInitialData();
    } catch (error) {
      console.log(error);
      setRequestError(error.response.data.messages);
      setFormLoading(false);
    }
  };

  const handleDonwloadDemo = async () => {
    const res = await reqDownload.get(`cargas/uploads/${request.upl_char_que}`);
    donwloadFile(res.data, fileTitleTable[request.upl_char_que]);
  };

  const handleBusinessSelect = async ({ target }) => {
    if (!target.value) {
      setProviders([]);
      return;
    }

    const res = await reqToken.get(
      `business_admin?l=50000&o=0&c=${target.value}`
    );
    const providersList = res.data.datos;

    setParentBusId(target.value);

    setProviders(providersList);

    setRequest({ ...request, ref_int_id: target.value });

    setContractors([]);
    // setParentBusId(target.value);
  };

  const handleProviderSelect = async ({ target }) => {
    if (!target.value) {
      setContractors([]);
      return;
    }

    setContractors([]);

    const res = await reqToken.get(
      `business_admin?l=50000&o=0&c=${target.value}`
    );
    const contractorsList = res.data.datos;

    setContractors(contractorsList);

    setRequest({ ...request, ref_int_id: target.value });
  };

  const handleContractorSelect = ({ target }) => {
    setRequest({ ...request, ref_int_id: target.value });
  };

  useEffect(() => {
    getBusiness();
    //eslint-disable-next-line
  }, []);

  const { upl_char_que } = request;

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">Cargar datos</h2>
      <div className="row w-100 mx-0 gx-3">
        <div className="col-8 d-flex flex-column ps-0">
          <Headers />

          <InfiniteScroll
            className="autocomplete-list"
            dataLength={data.length}
            next={handleScroll}
            // scrollThreshold={0.9}
            hasMore={hasMore}
            loader={
              data.length > 0 ? (
                <p className="text-center w-100 py-3 mb-0 border">
                  Cargando...
                </p>
              ) : (
                <p className="text-center w-100 py-3 mb-0 border">Sin datos</p>
              )
            }
            height={data.length >= 10 ? 510 : 'auto'}
            endMessage={
              <p className="text-center w-100 mb-0 py-3 border">
                No hay más datos
              </p>
            }
          >
            {data.map((item) => (
              // <div className="w-100">{item.name}</div>
              <Item
                key={item.id}
                item={item.item}
                deleteItem={handleDelete}
                erreasing={erreasing}
              />
            ))}
          </InfiniteScroll>
        </div>

        <form
          className="col-4 d-flex flex-column border rounded p-3"
          autoComplete="off"
          onSubmit={handleSubmit}
          style={{ height: 'fit-content' }}
        >
          <DefaultSelect
            label="Tabla"
            inputName="upl_char_que"
            onChange={handleTableChange}
            defaultValue={upl_char_que}
            options={[
              { name: 'Proveedores-usuarios', value: 'business_users' },
              { name: 'Equipos', value: 'equipos' },
              { name: 'Trabajadores', value: 'trabajadores' },
              { name: 'Colaboradores', value: 'colaboradores' }
            ]}
            disabled={formLoading ? true : false}
          />

          <DefaultSelect
            label="Empresa"
            onChange={handleBusinessSelect}
            // defaultValue={business[0]?.bus_int_id}
            placeholder="Selecciona una empresa"
            options={business?.map((item) => ({
              value: item.bus_int_id,
              name: item.bus_text_name
            }))}
            disabled={formLoading ? true : false}
            error={
              noBusiness
                ? 'Debe seleccionar una empresa, proveedor o contratista'
                : null
            }
          />

          {request.upl_char_que !== 'business_users' &&
            providers?.length > 0 && (
              <DefaultSelect
                label="Proveedor"
                onChange={handleProviderSelect}
                placeholder="Selecciona un proveedor"
                // defaultValue={providers[0]?.bus_int_id}
                // placeholder={
                //   providers?.length > 0 ? "Selecciona un proveedor" : null
                // }
                options={
                  providers?.length > 0
                    ? providers?.map((item) => ({
                        value: item.bus_int_id,
                        name: item.bus_text_name
                      }))
                    : [
                        {
                          value: '',
                          name: 'No hay proveedores'
                        }
                      ]
                }
                required={false}
                disabled={formLoading ? true : false}
                error={
                  noBusiness
                    ? 'Debe seleccionar una empresa, proveedor o contratista'
                    : null
                }
              />
            )}

          {request.upl_char_que !== 'business_users' &&
            contractors?.length > 0 && (
              <DefaultSelect
                label="Contratista"
                onChange={handleContractorSelect}
                placeholder="Selecciona un contratista"
                options={contractors?.map((item) => ({
                  value: item.bus_int_id,
                  name: item.bus_text_name
                }))}
                required={false}
                disabled={formLoading ? true : false}
                error={
                  noBusiness
                    ? 'Debe seleccionar una empresa, proveedor o contratista'
                    : null
                }
              />
            )}

          <DefaultInput
            type="file"
            label="Archivo"
            inputName="file"
            id="massive-file-input"
            onChange={handleFileChange}
            disabled={formLoading ? true : false}
          />

          <div className="d-flex justify-content-between align-items-center w-100">
            <button
              type="button"
              className="btn btn-warning"
              onClick={handleDonwloadDemo}
              disabled={formLoading ? true : false}
            >
              Formato <i className="fas fa-download ms-2"></i>
            </button>
            <button
              className="btn btn-primary"
              disabled={formLoading ? true : false}
            >
              Subir <i className="fas fa-upload ms-2"></i>
            </button>
          </div>
        </form>

        <button
          ref={errorModalRef}
          type="button"
          className="d-none"
          data-bs-toggle="modal"
          data-bs-target="#cargar-datos-error-modal"
        >
          Error modal
        </button>

        <SubmitErrorModal />
      </div>
    </FullWidthContainer>
  );
}

const Item = ({ item, deleteItem, erreasing }) => {
  const handleDelete = async () => {
    deleteItem(item.upl_int_id);
  };

  const customId = `upl_item_${item.upl_int_id}`;

  return (
    <div
      className="row mx-0 w-100 border border-bottom-0 align-items-center hover-list-item"
      title={`Autor: ${item.usuario}`}
      style={{ cursor: 'pointer' }}
    >
      <ErrorModal item={item} customId={customId} />

      <span className="col-1 text-truncate text-center border-end py-2">
        {item.upl_int_id}
      </span>
      <span className="col-3 text-center text-truncate border-end py-2">
        {item.bus_text_name}
      </span>
      <span className="col-2 text-truncate border-end py-2">
        {item.upl_text_name}
      </span>
      <span className="col-2 text-truncate text-center border-end py-2">
        {item.upl_char_que}
      </span>
      <span className="col-2 text-truncate text-center border-end py-2">
        {item.upl_date_creation_date.split(' ')[0]}
      </span>
      <span className="col-2 d-flex align-items-center justify-content-center py-2 gap-2">
        <button
          className="btn btn-danger btn-sm"
          type="button"
          onClick={handleDelete}
          disabled={erreasing ? true : false}
        >
          <i className="far fa-trash-alt"></i>
        </button>

        <button
          type="button"
          className="btn btn-warning btn-sm"
          data-bs-toggle="modal"
          data-bs-target={`#${customId}`}
        >
          <i className="far fa-eye"></i>
        </button>
      </span>
    </div>
  );
};

const ErrorModal = ({ item, customId }) => {
  const info = JSON.parse(item.upl_text_errors);

  const validate = (item) => {
    if (typeof item === 'string') {
      return JSON.parse(item);
    }

    return item;
  };

  const infoData = {
    ...info,
    errores: validate(info?.errores),
    alertas: {
      usuarios: validate(info?.alertas?.usuarios),
      proveedores: validate(info?.alertas?.proveedores),
      bxu: validate(info?.alertas?.bxu)
    }
  };

  return (
    <div
      className="modal fade"
      id={customId}
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby={customId + '_label'}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5 className="modal-title text-uppercase" id={customId + '_label'}>
              Ver información de documento
            </h5>
            <button
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body">
            <ul className="list-group">
              <li className="list-group-item bg-light">
                <span className="fw-bold text-uppercase me-2">ID</span>{' '}
                {item.upl_int_id}
              </li>
              <li className="list-group-item bg-light">
                <span className="fw-bold text-uppercase me-2">Empresa</span>{' '}
                {item.bus_text_name}
              </li>
              <li className="list-group-item bg-light">
                <span className="fw-bold text-uppercase me-2">Archivo</span>{' '}
                {item.upl_text_name}
              </li>
              <li className="list-group-item bg-light">
                <span className="fw-bold text-uppercase me-2">Tabla</span>{' '}
                {item.upl_char_que}
              </li>
              <li className="list-group-item bg-light">
                <span className="fw-bold text-uppercase me-2">Fecha</span>{' '}
                {item.upl_date_creation_date.split(' ')[0]}
              </li>
            </ul>

            {infoData.messages && (
              <h6 className="text-center my-3 text-danger">
                {infoData.messages}
              </h6>
            )}

            {infoData.errores?.length > 0 && (
              <>
                <h6 className="text-center my-3">Informe de errores</h6>
                <ul className="list-group">
                  <li className="list-group-item bg-light">
                    <span className="fw-bold text-uppercase me-2">Errores</span>
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-darkmode" scope="col">
                            Fila
                          </th>
                          <th className="text-darkmode" scope="col">
                            Columna
                          </th>
                          <th className="text-darkmode" scope="col">
                            Desc.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {infoData.errores.map((item, index) => (
                          <tr key={`err_item_${index}`}>
                            <td className="text-darkmode">{item.fila}</td>
                            <td className="text-darkmode">{item.columna}</td>
                            <td className="text-darkmode">{item.desc}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </li>
                </ul>
              </>
            )}

            {(infoData.alertas.usuarios?.length > 0 ||
              infoData.alertas.proveedores?.length > 0 ||
              infoData.alertas.bxu?.length > 0) && (
              <h6 className="text-center my-3">Informe de advertencias</h6>
            )}

            <ul className="list-group">
              {infoData.alertas.usuarios?.length > 0 && (
                <li className="list-group-item bg-light">
                  <span className="fw-bold text-uppercase me-2">Usuarios</span>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-darkmode" scope="col">
                          Fila
                        </th>
                        <th className="text-darkmode" scope="col">
                          Mensajes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {infoData.alertas.usuarios?.map((item, index) => (
                        <tr key={index}>
                          <td className="text-darkmode">{item.FilaUser}</td>
                          <td>
                            {Object.keys(item.mensajes).map(function (key) {
                              return (
                                <p key={key} className="mb-0 text-darkmode">
                                  <span className="fw-bold">{key}:</span>{' '}
                                  {item.mensajes[key]}
                                </p>
                              );
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </li>
              )}

              {infoData.alertas.proveedores?.length > 0 && (
                <li className="list-group-item bg-light">
                  <span className="fw-bold text-uppercase me-2">
                    Proveedores
                  </span>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-darkmode" scope="col">
                          Fila
                        </th>
                        <th className="text-darkmode" scope="col">
                          Mensajes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {infoData.alertas.proveedores?.map((item, index) => (
                        <tr key={index}>
                          <td className="text-darkmode">{item.FilaBusiness}</td>
                          <td>
                            {Object.keys(item.mensajes).map(function (key) {
                              return (
                                <p key={key} className="mb-0 text-darkmode">
                                  <span className="fw-bold">{key}:</span>{' '}
                                  {item.mensajes[key]}
                                </p>
                              );
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </li>
              )}

              {infoData.alertas.bxu?.length > 0 && (
                <li className="list-group-item bg-light">
                  <span className="fw-bold text-uppercase me-2">
                    Usuario - Negocio
                  </span>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-darkmode" scope="col">
                          Fila
                        </th>
                        <th className="text-darkmode" scope="col">
                          Mensajes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {infoData.alertas.bxu?.map((item, index) => (
                        <tr key={index}>
                          <td className="text-darkmode">{item.Fila}</td>
                          <td>
                            {Object.keys(item.mensajes).map(function (key) {
                              return (
                                <p key={key} className="mb-0 text-darkmode">
                                  <span className="fw-bold">{key}:</span>{' '}
                                  {item.mensajes[key]}
                                </p>
                              );
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </li>
              )}
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Headers = () => {
  return (
    <div className="row mx-0 w-100 border rounded bg-secondary mb-4">
      <span className="col-1 text-center text-white fw-bold border-end py-2">
        Id
      </span>
      <span className="col-3 text-center text-white fw-bold border-end py-2">
        Empresa
      </span>
      <span className="col-2 text-center text-white fw-bold border-end py-2">
        Archivo
      </span>
      <span className="col-2 text-center text-white fw-bold border-end py-2">
        Tabla
      </span>
      <span className="col-2 text-center text-white fw-bold border-end py-2">
        Fecha
      </span>
      <span className="col-2 text-center text-white fw-bold py-2">
        <i className="far fa-trash-alt"></i>
      </span>
    </div>
  );
};
