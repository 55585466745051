import React from 'react';
import { useHistory, useParams } from 'react-router';

import useScollContainer from 'hooks/useScrollContainer';

import { DefaultInput } from 'templates/input';
import InfiniteScroll from 'react-infinite-scroll-component';

import '../styles/CRUDPage.css';
import ListItem from 'components/CrudListItemComponents/ListItem';

const urlTable = {
  contacts: 'administration/contacts_admin',
  trabajadores: 'administration/trabajadores',
  equipos: 'administration/equipos',
  proveedores: 'administration/proveedores_admin',
  formatos: 'empresa/formatos',
  users_proveedor: 'administration/usuarios_admin',
  users_business: 'administration/usuarios_admin',
  clasificacion: 'administration/formats_c'
};

const urlDeleteTable = {
  contacts: 'administration/contacts_admin',
  trabajadores: 'administration/trabajadores',
  equipos: 'administration/equipos',
  proveedores: 'administration/business',
  formatos: 'empresa/formatos',
  users_proveedor: 'administration/usuarios_admin',
  users_business: 'administration/usuarios_admin',
  clasificacion: 'administration/formats_c'
};

export default function CRUDPageById() {
  const { module, id, title } = useParams();
  const history = useHistory();

  // funcion que trae la data
  const {
    loading,
    data,
    error,
    keyword,
    setKeyword,
    hasMore,
    erreasing,
    edit,
    getFilter,
    handleScroll,
    handleDelete,
    handleRestore,
    handleHardDelete
  } = useScollContainer({
    type: module,
    url: urlTable[module],
    deleteUrl: urlDeleteTable[module],
    busId: id
  });

  const handleNew = () => {
    if (module === 'formatos') {
      history.replace(`/module/types/${id}/${title}/info/${module}/0`);
      return;
    }

    history.replace(`/module/business_admin/${id}/${title}/info/${module}/0`);
  };

  return (
    <div className="w-100 h-100 d-flex flex-column align-items-center">
      <form
        onSubmit={getFilter}
        autoComplete="off"
        className="d-none d-md-flex w-100 align-items-end gap-3 mb-4"
      >
        <DefaultInput
          customClass="flex-grow-1"
          onChange={({ target }) => setKeyword(target.value)}
          value={keyword}
          placeholder="Ingresa palabras clave"
          mb="0"
          required={false}
        />

        <button type="submit" className="btn btn-primary">
          <i className="fas fa-search me-2"></i>
          Buscar
        </button>

        <button onClick={handleNew} type="button" className="btn btn-success">
          <i className="fas fa-folder-plus me-2"></i>
          Agregar
        </button>
      </form>

      <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-2">
        <div className="d-none d-md-block col-md-1 fw-bold text-white border-end py-2 text-center">
          ID
        </div>

        <div
          className={`${
            module === 'trabajadores' || module === 'equipos'
              ? 'col-3 col-md-4'
              : 'col-6 col-md-8'
          } border-end py-2 text-truncate text-white fw-bold`}
        >
          Nombre
        </div>

        {(module === 'trabajadores' || module === 'equipos') && (
          <span className="col-3 col-md-4 border-end py-2 text-truncate text-center text-white fw-bold">
            {module === 'trabajadores' ? 'DNI' : 'Placa / Serie'}
          </span>
        )}

        <div className="col-4 col-md-3 fw-bold text-white text-center py-2">
          Opciones
        </div>
      </div>

      {error && (
        <div className="alert alert-danger text-center p-3">
          Un error ha ocurrido
        </div>
      )}

      {!error && !loading ? (
        <div className="w-100">
          <InfiniteScroll
            className="autocomplete-list"
            dataLength={data.length}
            next={handleScroll}
            // scrollThreshold={0.9}
            hasMore={hasMore}
            loader={
              data.length > 0 ? (
                <p className="text-center w-100 py-3 mb-0 border">
                  Cargando...
                </p>
              ) : (
                <p className="text-center w-100 py-3 mb-0 border">Sin datos</p>
              )
            }
            height={data.length >= 10 ? 510 : 'auto'}
            endMessage={
              <p className="text-center w-100 mb-0 py-3 border">
                No hay más datos
              </p>
            }
          >
            {data.map((item) => (
              // <div className="w-100">{item.name}</div>
              <ListItem
                key={item.id}
                busId={id}
                busTitle={title}
                type={module}
                id={item.id}
                name={item.name}
                qr={item.qr ?? ''}
                sso={item.item?.sso_link ?? ''}
                item={item.item}
                deleted={item.deleted}
                erreasing={erreasing}
                edit={edit}
                handleDelete={handleDelete}
                handleRestore={handleRestore}
                handleHardDelete={handleHardDelete}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="border w-100 text-center py-3">Cargando...</div>
      )}
    </div>
  );
}
