// import axios from "axios";
import AlertMessage from "components/layout/AlertMessage";
import { POST, PUT } from "helpers/request";
import { useForm } from "hooks/useForm";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DefaultInput } from "templates/input";

const defaultValues = {
  gro_text_description: "",
};

export default function GroupForm({ module, id, data }) {
  const [values, handleInputChange, reset] = useForm(data || defaultValues);
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    if (id === "0") {
      const data = await POST(values, module, setError, reset);
      setLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setSuccess(data ? data.status : 500);
    }

    //prettier-ignore
    if (id !== "0") {
      const data = await PUT(defaultValues, values, module, id, setError, reset);
      setLoading(false)
      window.scrollTo({ top: 0, behavior: "smooth" });
      setSuccess(data ? data.status : 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        history.goBack();
      }, 3000);
    }
  }, [success, history]);

  const { gro_text_description } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-3 p-xl-5 d-flex flex-column mt-3"
      autoComplete="off"
    >
      {id !== "0" ? (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Editar grupo</h2>
      ) : (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Nuevo grupo</h2>
      )}

      {success && <AlertMessage status={success} id={id} />}

      <DefaultInput
        label="Descripción"
        type="text"
        value={gro_text_description}
        inputName="gro_text_description"
        onChange={handleInputChange}
        placeholder="Ingresa una descripción"
        error={errorData?.gro_text_description}
      />

      <button
        type="submit"
        className="btn btn-primary mt-4"
        disabled={loading ? true : false}
      >
        {loading ? (
          <>
            <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
          </>
        ) : id !== "0" ? (
          "Editar"
        ) : (
          "Crear"
        )}
      </button>
    </form>
  );
}
