import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DELETE, GET, POST } from 'helpers/request';

import ListItem from './ListItem';

import '../../styles/CRUDPage.css';
import AlertModal from './AlertModal';
import useRequest from 'hooks/useRequest';

export default function FormatsRelations({
  setShowFormats,
  businessId,
  typeId,
  typeTitle,
  handleNewFormat,
  setStep,
  setInfo,
  setCurrentType
}) {
  const { reqTokenBase } = useRequest();

  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [assigning, setAssigning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [erreasing, setErreasing] = useState(false);

  const modalBtn = useRef();
  const [errorMsg, setErrorMsg] = useState('');

  const getData = useCallback(async () => {
    const dataAssignedItems = await GET(
      `formatsxbusiness/${typeId}/${businessId}`
    );

    const newList = dataAssignedItems.datos.map((item) => ({
      ...item,
      isAssigned:
        item.registrado_woh === '0' && item.registrado_bxf === '0'
          ? false
          : true,
      deleted: item.for_date_deletion_date ? true : false
    }));

    setOriginalData(newList);
    setData(newList);

    setLoading(false);
  }, [typeId, businessId]);

  const handleEditGroup = async (target, formatId) => {
    if (assigning) return;
    setAssigning(true);

    const assigned = target.className.includes('success');

    if (assigned) {
      await DELETE(`formatsxbusiness/${typeId}/${businessId}`, formatId);
      setData(
        data.map((item) =>
          item.for_int_id === formatId ? { ...item, isAssigned: false } : item
        )
      );

      return setAssigning(false);
    }

    if (!assigned) {
      const res = await POST(
        {
          typ_int_id: typeId,
          bus_int_id: businessId,
          for_int_id: formatId
        },
        'formatsxbusiness'
      );

      if (res && res.status === 201) {
        setData(
          data.map((item) =>
            item.for_int_id === formatId ? { ...item, isAssigned: true } : item
          )
        );
      }

      if (res && res.status === 204) {
        setErrorMsg(res.message);
        modalBtn.current.click();
      }

      if (res && res.status === 401) console.log(res);

      return setAssigning(false);
    }
  };

  const handleDelete = async (id) => {
    setErreasing(true);
    await DELETE('formats', id);
    setErreasing(false);
  };

  const handleRestore = async (id) => {
    setErreasing(true);
    await reqTokenBase.delete(`administration/formats/${id}/restore`);
    setErreasing(false);
  };

  const handleHardDelete = async (id) => {
    setErreasing(true);
    await reqTokenBase.delete(`administration/formats/${id}/remove`);

    setData(data.filter((item) => item.for_int_id !== id));

    setErreasing(false);
  };

  const handleFilter = (query) => {
    setData(
      originalData.filter((item) =>
        item.for_text_name
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase())
      )
    );
  };

  useEffect(() => {
    getData();
    setCurrentType({
      id: typeId,
      name: typeTitle
    });

    //eslint-disable-next-line
  }, [getData]);

  return (
    <div className="w-100">
      <AlertModal msg={errorMsg} />
      <button
        ref={modalBtn}
        type="button"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#relationModal"
      />

      <div className="d-flex align-items-center gap-3 mb-3">
        <button
          onClick={() =>
            setShowFormats({
              businessId: '',
              typeId: '',
              typeTitle: '',
              show: false
            })
          }
          type="button"
          className="btn btn-secondary"
          title="Regresar"
        >
          <i className="fas fa-caret-left fa-lg me-2"></i>
          Volver
        </button>
        <h5 className="bg-secondary text-white p-2 rounded fw-normal flex-grow-1 mb-0">
          Formatos de {typeTitle}
        </h5>
        <button
          onClick={handleNewFormat}
          type="button"
          className="btn btn-success"
          title="Crear formato para este tipo de requisito"
        >
          Formato <i className="fas fa-plus ms-2"></i>
        </button>
      </div>

      <div className="row w-100 mx-0 px-0 bg-secondary rounded mb-2">
        <div className="col-1 fw-bold text-white py-2 border-end text-center">
          ID
        </div>
        <div className="col-7 fw-bold text-white py-2 border-end">Nombre</div>
        <div className="col-4 fw-bold text-white py-2 text-center">
          Opciones
        </div>
      </div>

      {loading && (
        <div className="pt-5 pb-2 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      )}

      {!loading && (
        <input
          type="text"
          className="form-control w-100 mb-2 input-bg-dark"
          placeholder="Buscar..."
          onChange={({ target }) => handleFilter(target.value)}
        />
      )}

      {!loading && data && (
        <div className="items-list-container border-bottom">
          {data.length > 0 &&
            data.map((item, index) => (
              <ListItem
                key={`rel_for_list_item_${index}`}
                id={item.for_int_id}
                type="formats"
                data={item}
                edit={handleEditGroup}
                assigning={assigning}
                setStep={setStep}
                setInfo={setInfo}
                erreasing={erreasing}
                handleDelete={handleDelete}
                handleRestore={handleRestore}
                handleHardDelete={handleHardDelete}
              />
            ))}

          {data.length === 0 && (
            <div className="w-100 alert-danger text-center py-2 rounded">
              No hay formatos
            </div>
          )}
        </div>
      )}
    </div>
  );
}
