import React from 'react';
import useRequest from 'hooks/useRequest';

export default function QueueListItem({ id, title, item, setModalInfo }) {
  const { reqTokenBase } = useRequest();

  const getEmailData = async () => {
    const res = await reqTokenBase.get(`/administration/emails/${id}`);
    setModalInfo({
      subject: res.data.ema_text_subject,
      content: res.data.ema_text_contents,
      html: res.data.ema_text_contents_html,
      emails: res.data.ema_text_email,
      empresa: '',
      tipo: '',
      formato: '',
      itemId: id,
      files: null,
      onlyView: true,
      fillItem: res.data
    });
  };

  return (
    <div className="row w-100 mx-0 px-0 border-bottom align-items-center hover-list-item border border-bottom-0">
      <span className="col-3 col-md-1 border-end py-2 text-center">{id}</span>

      <span className="col-3 col-md-4 py-2 border-end text-truncate">
        {title}
      </span>
      <span className="col-3 col-md-5 py-2 text-truncate">
        {item.ema_text_email}
      </span>

      <div className="d-none d-md-flex border-start col-md-2 py-2 justify-content-center gap-3">
        <button
          onClick={getEmailData}
          type="button"
          className="btn btn-secondary btn-sm"
          title="Ver email"
          data-bs-toggle="modal"
          data-bs-target="#list-item-email-view"
        >
          <i className="far fa-eye me-2"></i>
          Ver email
        </button>
      </div>
    </div>
  );
}
