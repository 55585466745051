import TextEditor from '../TextEditor';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultInput, DefaultTextarea } from 'templates/input';

export default function ConfirmEmail({ modalInfo }) {
  const { reqTokenBase, reqDownloadBase } = useRequest();
  const history = useHistory();

  const regex = /([a-zA-Z0-9.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9._-]+)/gi;

  const closeBtnRef = useRef();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [testSuccess, setTestSuccess] = useState(false);
  const [filesLoading, setFilesLoading] = useState(false);

  const handleQueueEmail = async () => {
    setLoading(true);

    try {
      await reqTokenBase.get(
        `marketing/newsletter/${modalInfo?.itemId}/enqueue`
      );
      setLoading(false);

      closeBtnRef.current.click();
      // setInQueue(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTestEmail = async () => {
    setLoading(true);
    setTestSuccess(false);
    try {
      await reqTokenBase.get(
        `marketing/newsletter/${modalInfo?.itemId}/totest/${email}`
      );
      setTestSuccess(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleDownloadFile = async (fileId, title) => {
    setFilesLoading(true);
    try {
      const res = await reqDownloadBase.get(
        `marketing/files_newsletter/${fileId}?que=1`
      );

      const href = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', title); // name with extension
      document.body.appendChild(link);
      link.click();

      setFilesLoading(false);
    } catch (error) {
      console.log(error);
      setFilesLoading(false);
    }
  };

  const handleEdit = () => {
    history.push(`/marketing/newsletter/${modalInfo?.itemId}`);
  };

  useEffect(() => {
    if (testSuccess) {
      setTimeout(() => {
        setTestSuccess(false);
      }, 5000);
    }
  }, [testSuccess]);

  return (
    <div
      className="modal fade"
      id="list-item-email-view"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="queueModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content bg-light">
          <div className="modal-header bg-light">
            <h5 className="modal-title" id="list-item-email-view-label">
              Vista previa
            </h5>
            <button
              type="button"
              className="btn-close-modal btn text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
              disabled={loading ? true : false}
              ref={closeBtnRef}
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body py-5">
            {testSuccess && (
              <div className="alert alert-success text-center p-3 border mb-3">
                Prueba realizada con éxito
              </div>
            )}

            <div className="d-flex gap-3">
              {modalInfo?.empresa && (
                <DefaultInput
                  customClass="flex-grow-1"
                  label="Empresa"
                  value={modalInfo?.empresa}
                  disabled={true}
                />
              )}

              {modalInfo?.empresa && (
                <DefaultInput
                  customClass="flex-grow-1"
                  label="Tipo"
                  value={modalInfo?.tipo}
                  disabled={true}
                />
              )}

              {modalInfo?.empresa && (
                <DefaultInput
                  customClass="flex-grow-1"
                  label="Formato"
                  value={modalInfo?.formato}
                  disabled={true}
                />
              )}
            </div>

            <DefaultInput
              label="Tema"
              value={modalInfo?.subject}
              disabled={true}
            />

            {modalInfo?.emails && (
              <DefaultTextarea
                label="Enviado"
                rows={5}
                disabled={true}
                value={modalInfo?.emails?.split(',').join('\n')}
              />
            )}

            <label className="mb-2">Contenido</label>
            <TextEditor
              height="350"
              defaultContent={modalInfo?.html}
              hideToolbar
              disable
            />

            {modalInfo?.files?.length > 0 && (
              <>
                <label className="mb-2 mt-3">Archivos previos</label>
                <div className="d-flex flex-column border p-2 col-12 col-md-10 offset-md-1">
                  <ul className="list-group list-group-flush">
                    {modalInfo?.files.map((item) => (
                      <li
                        key={item.fxn_int_id}
                        className="list-group-item bg-transparent d-flex align-items-center"
                      >
                        <span className="text-truncate flex-grow-1">
                          {item.fxn_text_file}
                        </span>
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm ms-auto me-2"
                          onClick={() =>
                            handleDownloadFile(
                              item.fxn_int_id,
                              item.fxn_text_file
                            )
                          }
                          disabled={filesLoading ? true : false}
                        >
                          <i className="fas fa-download"></i>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}

            <DefaultTextarea
              className="mt-3"
              mb="0"
              label="Contenido ( Texto )"
              rows={5}
              disabled={true}
              value={modalInfo?.content}
            />
          </div>
          <div className="modal-footer bg-light">
            {!modalInfo?.onlyView ? (
              <div className="d-flex align-items-end w-100 gap-2">
                <DefaultInput
                  customClass="flex-grow-1"
                  placeholder="Email de prueba"
                  type="email"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                  mb="0"
                  required={false}
                />

                <button
                  onClick={handleTestEmail}
                  type="submit"
                  className={`btn btn-warning`}
                  disabled={loading ? true : regex.test(email) ? false : true}
                >
                  {loading ? (
                    <>
                      <i className="fas fa-circle-notch fa-spin me-2"></i>
                      Guardando...
                    </>
                  ) : (
                    <>
                      <i className="fas fa-mail-bulk me-2"></i>
                      Probar email
                    </>
                  )}
                </button>

                <button
                  onClick={handleEdit}
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  disabled={loading ? true : false}
                >
                  <i className="far fa-edit me-2"></i>
                  Revisar email
                </button>

                <button
                  onClick={handleQueueEmail}
                  type="button"
                  className="btn btn-primary"
                  disabled={loading ? true : false}
                >
                  <i className="fas fa-mail-bulk me-2"></i>
                  Agregar a la cola
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                disabled={loading ? true : false}
              >
                <i className="fas fa-times me-2"></i>
                Cerrar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
