import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { GET } from 'helpers/request';

import UsersForm from 'components/Forms/UsersForm';
import TypesForm from 'components/Forms/TypesForm';
import BusinessForm from 'components/Forms/BusinessForm';
import CategoriesForm from 'components/Forms/CategoriesForm';
import GroupForm from 'components/Forms/GroupForm';
import RecordsForm from 'components/Forms/RecordsForm';
import AccessForm from 'components/Forms/AccessForm';
import ContactsForm from 'components/Forms/ContactsForm';
import useRequest from 'hooks/useRequest';
import BlacklistForm from 'components/Forms/BlacklistForm';
import PlanesForm from 'components/Forms/PlanesForm';

export default function ModulePage() {
  const { reqTokenBase } = useRequest();
  const history = useHistory();

  const { module, id, busId, typeTitle, title } = useParams();
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState(true);

  const handleGoBack = (id = 0, name) => {
    if (!module !== 'formatos') {
      history.push(
        `/crud/${module}${id ? `/${id}` : ''}${name ? `/${name}` : ''}`
      );
      return;
    }

    history.push(`/crud/types`);
  };

  useEffect(() => {
    setLoading(true);

    const fetcher = async () => {
      if (
        module !== 'proveedores' &&
        module !== 'tipos' &&
        module !== 'formatos'
      ) {
        const data = await GET(module, id);
        setData(data);
      } else {
        const res = await reqTokenBase.get(`empresa/${module}/${id}`);

        setData(res.data);
      }

      setLoading(false);
    };

    if (id !== '0') {
      return fetcher();
    }

    setLoading(false);

    //eslint-disable-next-line
  }, [module, id]);

  if (loading) {
    return (
      <div className="custom-container">
        <div className="w-100 h-100 d-flex flex-column align-items-center card p-3 p-xl-5 bg-light">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="custom-container">
      <div className="w-100 h-100 d-flex flex-column align-items-center card p-3 bg-light">
        {module === 'types' && (
          <TypesForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'users' && (
          <UsersForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'business_admin' && (
          <BusinessForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'proveedores' && (
          <BusinessForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'categories' && (
          <CategoriesForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}

        {module === 'groups' && (
          <GroupForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'records' && (
          <RecordsForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'access' && (
          <AccessForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'contacts' && (
          <ContactsForm
            module={module}
            id={id}
            data={data}
            title={typeTitle}
            busId={busId}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'blacklist' && (
          <BlacklistForm
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
        {module === 'planes' && (
          <PlanesForm
            busId={title}
            module={module}
            id={id}
            data={data}
            title={title}
            handleGoBack={handleGoBack}
          />
        )}
      </div>
    </div>
  );
}
