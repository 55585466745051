import React, { useEffect, useState } from "react";

import AlertMessage from "components/layout/AlertMessage";
import { DefaultInput, DefaultTextarea } from "templates/input";
import useRequest from "hooks/useRequest";

const defaultValues = {
  typ_text_name: "",
  typ_text_description: "",
};

export default function TypesFormByBusiness({
  id,
  name,
  busId,
  handleComplete,
}) {
  const { reqToken } = useRequest();

  const [data, setData] = useState(defaultValues);
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const getData = async () => {
    try {
      const res = await reqToken.get(`types/${id}`);
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = ({ target }) => {
    setData({ ...data, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    try {
      if (id === "0") {
        await reqToken.post("types", {
          ...data,
          bus_int_id: busId,
        });
      }

      //prettier-ignore
      if (id !== "0") {
        await reqToken.put(`types/${id}`, {
          typ_text_name: data.typ_text_name,
          typ_text_description: data.typ_text_description,
          bus_int_id: busId,
        });
      }

      setSuccess(201);
    } catch (error) {
      if (error.response) {
        setError(error.response?.data.messages);
        setLoading(false);
        return;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        handleComplete();
      }, 3000);
    }

    //eslint-disable-next-line
  }, [success]);

  useEffect(() => {
    if (id !== "0") getData();
    //eslint-disable-next-line
  }, []);

  const { typ_text_name, typ_text_description } = data;

  return (
    <div className="w-100 d-flex flex-column">
      {success && <AlertMessage status={success} id={id} />}

      <form
        onSubmit={handleSubmit}
        className="w-100 d-flex flex-column align-items-stretch"
        autoComplete="off"
      >
        <DefaultInput
          label="Nombre"
          type="text"
          value={typ_text_name}
          inputName="typ_text_name"
          onChange={handleChange}
          placeholder="Ingresa un nombre"
          error={errorData?.typ_text_name}
          disabled={loading ? true : false}
        />

        <DefaultTextarea
          label="Descripción"
          value={typ_text_description}
          inputName="typ_text_description"
          onChange={handleChange}
          placeholder="Ingresa una descripción"
          required={false}
          error={errorData?.typ_text_description}
          disabled={loading ? true : false}
        />

        <button
          type="submit"
          className="btn btn-primary mt-4 w-auto ms-auto"
          disabled={loading ? true : false}
        >
          {loading ? (
            <>
              <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
            </>
          ) : id !== "0" ? (
            <>
              Editar
              <i className="fas fa-save ms-2"></i>
            </>
          ) : (
            <>
              Guardar
              <i className="fas fa-save ms-2"></i>
            </>
          )}
        </button>
      </form>
    </div>
  );
}
