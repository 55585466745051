import React from "react";

export default function ValidationTable() {
  return (
    <div className="d-flex gap-4 flex-wrap">
      <h5 className="w-100 text-center mt-4 mb-3">Validación</h5>

      <div className="d-flex w-100 align-items-center justify-content-center gap-5 mb-3 border py-3 px-4 rounded">
        <div className="d-flex gap-2 align-items-center">
          <p className="mb-0">Aprobado</p>

          <button type="button" className="btn btn-success btn-sm">
            <i className="far fa-check-circle fa-lg"></i>
          </button>
        </div>

        <div className="d-flex gap-2 align-items-center">
          <p className="mb-0">Pendiente</p>

          <button type="button" className="btn btn-warning btn-sm">
            <i className="far fa-question-circle fa-md fa-lg"></i>
          </button>
        </div>

        <div className="d-flex gap-2 align-items-center">
          <p className="mb-0">No válido</p>

          <button type="button" className="btn btn-danger btn-sm">
            <i className="far fa-times-circle fa-lg"></i>
          </button>
        </div>

        <div className="d-flex gap-2 align-items-center">
          <p className="mb-0">Obsoletos</p>

          <button type="button" className="btn btn-secondary btn-sm">
            <i className="far fa-folder-open fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
