export default function InputTextarea({
  className,
  label,
  id,
  value,
  name,
  onChange,
  isInvalid,
  rows = "5",
  required = true,
}) {
  return (
    <div className={className}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <textarea
        onChange={onChange}
        value={value}
        name={name}
        className={`form-control input-bg-dark ${
          isInvalid ? "is-invalid" : ""
        }`}
        id={id}
        rows={rows}
        required={required}
        style={{ resize: "none" }}
      />
    </div>
  );
}
