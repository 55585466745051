import { useCallback, useEffect, useState } from 'react';

import { adminUrl, headerToken } from 'api/api';

import {
  childKeySelector,
  keySelector,
  moduleSelector,
  parentKeySelector
} from 'components/EditionGroups/keysSelector';
import { DELETE } from 'helpers/request';
import useRequest from './useRequest';

export default function useRelations(module, id) {
  const { reqTokenBase } = useRequest();

  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);

  const [assigning, setAssigning] = useState(false);
  const [erreasing, setErreasing] = useState(false);

  const getData = useCallback(async () => {
    let dataAssignedItemsUrl;

    if (module === 'business') {
      dataAssignedItemsUrl = `business_types/${id}?=l=9999&o=0`;
    }
    if (module === 'users') {
      dataAssignedItemsUrl = `vwlistaccessusers/${id}?=l=9999&o=0`;
    }

    try {
      // const dataPromise = adminUrl.get(dataUrl, headerToken());
      const dataAssignedItemsPromise = await adminUrl.get(
        dataAssignedItemsUrl,
        headerToken()
      );

      const newList = dataAssignedItemsPromise.data?.datos?.map((item) => ({
        ...item,
        isAssigned:
          item.que === 't' || item.accesschecked === '1' ? true : false,
        deleted:
          item.typ_date_deletion_date || item.for_date_deletion_date
            ? true
            : false
      }));

      setOriginalData(newList);
      setData(newList);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [module, id]);

  const handleEditGroup = async (type, assignedItemId, prevIsAssigned) => {
    if (assigning) return;
    setAssigning(true);

    const key = keySelector(type);
    const module = moduleSelector(type);

    const parentGroup = parentKeySelector(type);
    const childAssignedItem = childKeySelector(type);

    const res = await adminUrl.post(
      module,
      {
        [parentGroup]: id,
        [childAssignedItem]: assignedItemId,
        que: prevIsAssigned ? 'd' : 'c'
      },
      headerToken()
    );

    if (res && (res.data.status === 201 || res.data.status === 202)) {
      setData(
        data.map((item) =>
          item[key] === assignedItemId
            ? { ...item, isAssigned: !prevIsAssigned }
            : item
        )
      );

      setAssigning(false);
    } else {
      setAssigning(false);
      return;
    }
  };

  const handleDelete = async (id) => {
    setErreasing(true);
    await DELETE('types', id);
    setErreasing(false);
  };

  const handleRestore = async (id) => {
    setErreasing(true);
    await reqTokenBase.delete(`administration/types/${id}/restore`);
    setErreasing(false);
  };

  const handleHardDelete = async (id) => {
    setErreasing(true);
    await reqTokenBase.delete(`administration/types/${id}/remove`);

    setData(data.filter((item) => item.typ_int_id !== id));

    setErreasing(false);
  };

  const handleFilter = (query) => {
    setData(
      originalData.filter((item) =>
        item.typ_text_name
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase())
      )
    );
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return {
    loading,
    data,
    assigning,
    handleEditGroup,
    getData,
    erreasing,
    handleDelete,
    handleRestore,
    handleHardDelete,
    handleFilter
  };
}
