// import axios from "axios";
import AlertMessage from 'components/layout/AlertMessage';
import Autocomplete from 'components/select-search/Autocomplete';
import { POST, PUT } from 'helpers/request';
import { useForm } from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DefaultInput, DefaultTextarea } from 'templates/input';

const defaultValues = {
  bla_text_id: '',
  bla_int_woh: '',
  bla_text_description: '',
  bus_int_id: ''
};

export default function BlacklistForm({
  module,
  id,
  data,
  title,
  handleGoBack
}) {
  const { reqTokenBase } = useRequest();
  const [values, handleInputChange, reset, setValues] = useForm(
    data || defaultValues
  );
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const [loadingInfo, setLoadingInfo] = useState(false);
  const [nonExistant, setNonExistant] = useState(false);
  const [infoData, setInfoData] = useState(
    data
      ? {
          woh_int_id: data.woh_int_id,
          bus_int_id: data.bus_int_id,
          bus_int_pid: data.bus_int_pid,
          bus_text_name: data.bus_text_name,
          woh_text_info: data.woh_text_info
        }
      : null
  );

  const history = useHistory();

  const getInfo = async () => {
    try {
      setLoadingInfo(true);
      setNonExistant(false);

      const res = await reqTokenBase.get(
        `administration/blacklist/${values.bla_int_woh === '1' ? 'c' : 'p'}/${
          values.bla_text_id
        }/${values.bus_int_id}`
      );

      console.log(res.data);

      if (res.data.datos.length === 0) {
        setNonExistant(true);
      } else {
        setInfoData(res.data.datos[0]);
      }

      setLoadingInfo(false);
    } catch (error) {
      setLoadingInfo(false);
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    if (id === '0') {
      const data = await POST(
        { ...values, ...infoData },
        module,
        setError,
        reset
      );
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setSuccess(data ? data.status : 500);
    }

    //prettier-ignore
    if (id !== "0") {
      const data = await PUT(defaultValues, {...values, }, module, id, setError, reset);
      setLoading(false)
      window.scrollTo({ top: 0, behavior: "smooth" });
      setSuccess(data ? data.status : 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        history.goBack();
      }, 3000);
    }
  }, [success, history]);

  const {
    bla_text_id,
    bla_int_woh,
    bla_text_description,
    bus_int_id,
    bus_text_pname
  } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-3 p-xl-5 d-flex flex-column mt-3"
      autoComplete="off"
    >
      {id !== '0' ? (
        <h2 className="w-100 text-center mb-3 mb-lg-5">
          Editar vetado ( {title.replace(/-/g, ' ')} )
        </h2>
      ) : (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Nuevo vetado</h2>
      )}

      <div className="d-flex justify-content-start align-items-center mb-4 gap-3">
        <button
          className="btn btn-secondary me-auto btn-sm"
          onClick={handleGoBack}
          type="button"
        >
          <i className="fa fas fa-caret-left fa-lg me-2"></i> Volver a la lista
        </button>
      </div>

      {success && <AlertMessage status={success} id={id} />}

      <div className="row">
        <div className="col-12 col-md-8">
          <h4>Datos</h4>

          <label className="fw-bold mb-2">Tipo</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="check-blacklist-1"
              checked={bla_int_woh === '1' ? true : false}
              // disabled={id !== "0" ? true : false}
              onChange={() => setValues({ ...values, bla_int_woh: '1' })}
              disabled={!!data}
            />
            <label className="form-check-label" htmlFor="check-blacklist-1">
              Trabajador
            </label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              id="check-blacklist-2"
              checked={bla_int_woh === '2' ? true : false}
              // disabled={id !== "0" ? true : false}
              onChange={() => {
                setValues({ ...values, bla_int_woh: '2' });
              }}
              disabled={!!data}
            />
            <label className="form-check-label" htmlFor="check-blacklist-2">
              Equipo
            </label>
          </div>

          {!data ? (
            <Autocomplete
              label="Empresa"
              classes="flex-grow-1 mb-3"
              url="business_admin"
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="bus_int_id"
              nameField="bus_text_name"
              placeholder="Escoja una opción"
              onChange={(item) => {
                setValues({ ...values, bus_int_id: item.value });
              }}
              paramsBusinessId={bus_int_id || '0'}
            />
          ) : (
            <DefaultInput
              label="Empresa"
              type="text"
              value={bus_text_pname}
              disabled
            />
          )}

          <DefaultInput
            label="Placa / Cédula"
            type="text"
            value={bla_text_id}
            inputName="bla_text_id"
            onChange={handleInputChange}
            placeholder="Ingresa una descripción"
            disabled={!!data}
            error={errorData?.bla_text_id}
          />
        </div>
        <div className="col-12 col-md-4">
          <h4>Información</h4>

          {loadingInfo && (
            <div className="d-flex align-items-center justify-content-center my-2">
              <div
                className="spinner-border text-dark"
                role="status"
                style={{ width: '1.5em', height: '1.5em' }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {!infoData && !nonExistant && (
            <p className="text-center alert alert-secondary">
              Necesitas completar los datos necesarios y consultar
            </p>
          )}
          {!infoData && nonExistant && (
            <p className="text-center alert alert-warning">
              No existe información sobre el trabajador/equipo
            </p>
          )}
          {infoData && !nonExistant && (
            <>
              <div className="d-flex flex-column">
                <span className="fw-bold">Empresa</span>
                <p>{infoData.bus_text_name}</p>
                <span className="fw-bold">Nombre</span>
                <p>{infoData.woh_text_info}</p>
              </div>
            </>
          )}
        </div>

        <div className="col-12 mb-4">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={getInfo}
            >
              Consultar
            </button>
          </div>
        </div>
      </div>

      <DefaultTextarea
        label="Descripción * (Requerido)"
        type="text"
        value={bla_text_description}
        inputName="bla_text_description"
        onChange={handleInputChange}
        placeholder="Ingresa una descripción"
        error={errorData?.bla_text_description}
      />

      <button
        type="submit"
        className="btn btn-primary mt-4 w-auto ms-auto"
        disabled={
          loading ||
          infoData === null ||
          values.bla_text_description.length === 0
            ? true
            : false
        }
      >
        {loading ? (
          <>
            <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
          </>
        ) : id !== '0' ? (
          <>
            Editar
            <i className="fas fa-save ms-2"></i>
          </>
        ) : (
          <>
            Guardar
            <i className="fas fa-save ms-2"></i>
          </>
        )}
      </button>
    </form>
  );
}
