import FullWidthContainer from 'components/layout/FullWidthContainer';
import Autocomplete from 'components/select-search/Autocomplete';
import { useHistory } from 'react-router-dom';

export default function Planes() {
  const history = useHistory();

  return (
    <FullWidthContainer>
      <div className="d-flex align-items-center justify-content-center w-75 mx-auto flex-grow-1">
        <Autocomplete
          label="Selecciona una empresa"
          classes="w-100 mb-5"
          url="business_admin"
          placeholder="Escoja una opción"
          onChange={(item) =>
            history.push(
              `/crud/planes/${item.value}/${item.label.replace(/\s/g, '-')}`
            )
          }
          valueField="bus_int_id"
          nameField="bus_text_name"
        />
      </div>
    </FullWidthContainer>
  );
}
