// import axios from "axios";
import React, { useEffect, useState } from "react";
import { GET, POST, PUT } from "helpers/request";
import { useHistory } from "react-router";
import { useForm } from "hooks/useForm";

import AlertMessage from "components/layout/AlertMessage";
import { DefaultInput, DefaultSelect } from "templates/input";

const defaultValues = {
  rec_date_expiration_date: "12/12/2021",
  rec_text_name: "",
  rec_text_obs: "",
  rec_int_status: "",
  bus_int_id: "",
  cat_int_id: "",
  typ_int_id: "",
};

export default function RecordsForm({ module, id, data }) {
  const [values, handleInputChange, reset] = useForm(data || defaultValues);
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  const [business, setBusiness] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const fetcher = async () => {
      try {
        const businessData = await GET("business?l=999&0=0");
        setBusiness(
          businessData.datos.map(({ bus_int_id, bus_text_name }) => ({
            value: bus_int_id,
            name: bus_text_name,
          }))
        );
        const categoriesData = await GET("categories?l=999&0=0");
        setCategories(
          categoriesData.datos.map(({ cat_int_id, cat_text_name }) => ({
            value: cat_int_id,
            name: cat_text_name,
          }))
        );
        const typesData = await GET("types?l=999&0=0");
        setTypes(
          typesData.datos.map(({ typ_int_id, typ_text_name }) => ({
            value: typ_int_id,
            name: typ_text_name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetcher();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    if (id === "0") {
      const data = await POST(values, module, setError, reset);
      setLoading(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setSuccess(data ? data.status : 500);
    }

    //prettier-ignore
    if (id !== "0") {
      const data = await PUT(defaultValues, values, module, id, setError, reset);
      setLoading(false)
      window.scrollTo({ top: 0, behavior: "smooth" });
      setSuccess(data ? data.status : 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        history.goBack();
      }, 3000);
    }
  }, [success, history]);

  const {
    rec_text_name,
    rec_text_obs,
    rec_int_status,
    bus_int_id,
    cat_int_id,
    typ_int_id,
  } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-3 p-xl-5 d-flex flex-column mt-3"
      autoComplete="off"
    >
      {id !== "0" ? (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Editar registro</h2>
      ) : (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Nuevo registro</h2>
      )}

      {success && <AlertMessage status={success} id={id} />}

      <DefaultInput
        label="Nombre"
        type="text"
        value={rec_text_name}
        inputName="rec_text_name"
        onChange={handleInputChange}
        placeholder="Ingresa un nombre"
        error={errorData?.rec_text_name}
      />

      <DefaultInput
        label="Observaciones"
        type="text"
        value={rec_text_obs}
        inputName="rec_text_obs"
        onChange={handleInputChange}
        placeholder="Ingrese sus observaciones"
        error={errorData?.rec_text_obs}
      />

      <DefaultSelect
        label="Estado"
        value={rec_int_status}
        inputName="rec_int_status"
        placeholder="Seleccionar una opción"
        onChange={handleInputChange}
        error={errorData?.rec_int_status}
        options={[
          { value: "0", name: "Opción 1" },
          { value: "1", name: "Opción 2" },
        ]}
      />

      <DefaultSelect
        label="Empresa"
        value={bus_int_id}
        inputName="bus_int_id"
        placeholder="Seleccionar una opción"
        onChange={handleInputChange}
        error={errorData?.bus_int_id}
        options={business}
      />

      <DefaultSelect
        label="Categoría"
        value={cat_int_id}
        inputName="cat_int_id"
        placeholder="Seleccionar una opción"
        onChange={handleInputChange}
        error={errorData?.cat_int_id}
        options={categories}
      />

      <DefaultSelect
        label="Tipo"
        value={typ_int_id}
        inputName="typ_int_id"
        placeholder="Seleccionar una opción"
        onChange={handleInputChange}
        error={errorData?.typ_int_id}
        options={types}
      />

      <button
        type="submit"
        className="btn btn-primary mt-4"
        disabled={loading ? true : false}
      >
        {loading ? (
          <>
            <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
          </>
        ) : id !== "0" ? (
          "Editar"
        ) : (
          "Crear"
        )}
      </button>
    </form>
  );
}
