import FullWidthContainer from 'components/layout/FullWidthContainer';
import Autocomplete from 'components/select-search/Autocomplete';
import { DWNLD_POST_V2, POST } from 'helpers/request';
import { useState } from 'react';

export default function ReportSinAsignar() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [businessId, setBusinessId] = useState('');

  const getData = async (busId) => {
    setLoading(true);
    setData([]);

    const res = await POST(
      { cual: '14', bus_int_id: busId, xls: '0' },
      // { cual: '10', bus_int_id: '353', typ_int_id: '21', xls: '0' },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    if (res[0].valor) {
      setData(res[0].valor);
      setBusiness({ ...res.cab, id: busId });
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const handleDescargar = () => {
    DWNLD_POST_V2(
      'reports',
      `reporte-sin-asignar-${business.Empresa}-${business.Fecha}.xlsx`,
      14,
      {
        bus_int_id: business.id,
        xls: 1
      }
    );
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">
        Reporte de formatos por proveedor sin asignar trabajadores o equipos
      </h2>

      <div className="d-flex align-items-end gap-3 mb-3">
        <div className="w-100 d-flex gap-3 align-items-end">
          <Autocomplete
            label="Selecciona una empresa"
            classes="flex-grow-1"
            url="business_admin"
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              setBusinessId('');
              setData(null);

              setTimeout(() => {
                setBusinessId(item.value);
              }, 100);

              // getData(item.value);
            }}
          />

          {businessId && (
            <Autocomplete
              label="Selecciona un proveedor"
              classes="flex-grow-1"
              url="business_admin"
              customParams={[`c=${businessId}`]}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="bus_int_id"
              nameField="bus_text_name"
              placeholder="Escoja una opción"
              onChange={(item) => {
                getData(item.value);
              }}
            />
          )}
        </div>

        {!loading && data && data.length > 0 && (
          <button
            onClick={handleDescargar}
            type="button"
            className="btn btn-success d-flex align-items-center"
          >
            <i className="fas fa-download me-2"></i>Excel
          </button>
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading && data && data.length > 0 && (
        <table className="table table-striped table-bordered">
          <thead>
            <tr className="bg-secondary text-white">
              <th scope="col">Empresa</th>
              <th scope="col">Requisito</th>
              <th scope="col">Formato</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.bus_text_name}</td>
                <td>{item.typ_text_name}</td>
                <td>{item.for_text_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FullWidthContainer>
  );
}
