import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { POST } from 'helpers/request';
import { DefaultInput } from 'templates/input';
import Autocomplete from 'components/select-search/Autocomplete';

const addDays = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);

  const parsed = copy.toISOString();

  return parsed.split('T')[0];
};

export default function ReportPorExpirar() {
  const [data, setData] = useState(null);
  const [parentId, setParentId] = useState('');
  const [parentProviderId, setParentProviderId] = useState('');

  const [currentId, setCurrentId] = useState('');

  const [date, setDate] = useState(addDays(new Date(), 7));

  const history = useHistory();
  const situDocBussRoute = '/situacionDocumental/';

  const handleClick = async (id, dateParam) => {
    if (!id && !currentId) return;

    setData(null);

    const res = await POST(
      { cual: '9', bus_int_id: id || currentId, fecha: dateParam || date },
      'reports',
      null,
      null
    );

    setData(res[0]);
  };

  const handleBusinessClick = (busId, catId, busName) => {
    history.push(
      `${situDocBussRoute}${busId}/${catId}/${busName.replace(/\s/g, '-')}`
    );
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">Documentos por expirar</h2>

      <div className="row mx-0 w-100">
        <div className="col-12 col-lg-3">
          <DefaultInput
            label="Selecciona una fecha"
            type="date"
            value={date}
            onChange={({ target }) => {
              setDate(target.value);
              handleClick(null, target.value);
            }}
          />
        </div>
        <div className="col-12 col-lg-3">
          <Autocomplete
            label="Selecciona una empresa"
            classes="w-100 pb-3"
            url="business_admin"
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              handleClick(item.value);
              setCurrentId(item.value);
              setParentId('');
              setParentProviderId('');

              setTimeout(() => {
                setParentId(item.value);
              }, 100);
            }}
          />
        </div>
        {parentId && parentId !== '0' && (
          <div className="col-12 col-lg-3">
            <Autocomplete
              label="Selecciona un proveedor"
              classes="w-100 pb-3"
              url="business_admin"
              customParams={[`c=${parentId}`]}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="bus_int_id"
              nameField="bus_text_name"
              placeholder="Escoja una opción"
              onChange={(item) => {
                handleClick(item.value);
                setParentProviderId('');
                setTimeout(() => {
                  setParentProviderId(item.value);
                }, 100);

                setCurrentId(item.value);
              }}
            />
          </div>
        )}
        {parentProviderId && parentProviderId !== '0' && (
          <div className="col-12 col-lg-3">
            <Autocomplete
              label="Selecciona un contratista"
              classes="w-100 pb-3"
              url="business_admin"
              customParams={[`c=${parentProviderId}`]}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="bus_int_id"
              nameField="bus_text_name"
              placeholder="Escoja una opción"
              onChange={(item) => {
                handleClick(item.value);
                setCurrentId(item.value);
              }}
            />
          </div>
        )}
      </div>

      <Headers />

      {data?.valor.length > 0 &&
        data.valor.map((item) => (
          <Item
            key={item.bus_int_id}
            item={item}
            handleBusinessClick={handleBusinessClick}
          />
        ))}

      {!data && (
        <div className="alert alert-secondary text-center py-3 border">
          Seleccione una empresa
        </div>
      )}

      {data?.valor.length === 0 && (
        <div className="alert alert-warning text-center py-3 border">
          No hay datos
        </div>
      )}
    </FullWidthContainer>
  );
}

const Item = ({ id, item, handleBusinessClick }) => {
  return (
    <div className="row mx-0 w-100 border rounded">
      <span className="col-5 text-center border-end py-2">
        {item.bus_text_name}
      </span>
      <span
        className="sit-doc-item-row col-5 text-center border-end py-2"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          handleBusinessClick(
            item.bus_int_id,
            item.cat_int_id,
            item.bus_text_name
          )
        }
      >
        {item.cat_text_name}
      </span>
      <span className="col-2 text-center py-2">
        {item.rec_date_expiration_date}
      </span>
    </div>
  );
};
const Headers = () => {
  return (
    <div className="row mx-0 w-100 border rounded bg-secondary mb-4">
      <span className="col-5 text-center text-white fw-bold border-end py-2">
        Empresa
      </span>
      <span className="col-5 text-center text-white fw-bold border-end py-2">
        Categoría
      </span>
      <span className="col-2 text-center text-white fw-bold py-2">Expira</span>
    </div>
  );
};
