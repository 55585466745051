import { useState } from 'react';
import useAuth from 'auth/useAuth';
import { useForm } from 'hooks/useForm';

import logo from '../images/logo.jpg';
import '../styles/LoginScreen.css';

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;

export default function Login() {
  const auth = useAuth();

  const [values, handleInputChange] = useForm({
    email:
      `${BASE_URL}` === 'https://flex.test/administration/'
        ? 'emprendedor@gad.empresa-atenea'
        : '',
    password:
      `${BASE_URL}` === 'https://flex.test/administration/'
        ? 'Firulais$2026'
        : ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const res = await auth.login(values.email, values.password);

    if (res) {
      setLoading(false);
      return setError(res);
    }
  };

  return (
    <div className="login-screen d-flex align-items-center justify-content-center">
      <form
        onSubmit={handleSubmit}
        className="login-card card p-3 p-xl-5 shadow bg-body"
      >
        <div className="w-100 mb-4 d-flex justify-content-center">
          <img src={logo} alt="Flex logo" width="200" />
        </div>

        {error && (
          <div
            className="alert alert-danger w-100 mb-4 text-center"
            role="alert"
          >
            {error}
          </div>
        )}

        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Ingresa tu usuario"
            id="emailLogin"
            name="email"
            value={values.email}
            onChange={handleInputChange}
            readOnly={loading ? true : false}
          />
          <label htmlFor="emailLogin" className="text-dark">
            Usuario
          </label>
        </div>

        <div className="form-floating mb-0">
          <input
            type="password"
            className="form-control"
            id="passLogin"
            placeholder="Contraseña"
            name="password"
            value={values.password}
            onChange={handleInputChange}
            readOnly={loading ? true : false}
          />
          <label htmlFor="passLogin" className="text-dark">
            Contraseña
          </label>
        </div>

        <button
          type="submit"
          className="btn btn-warning mt-3"
          disabled={loading ? true : false}
        >
          {loading ? (
            <div className="d-flex align-items-center justify-content-center">
              <div
                className="spinner-border text-dark"
                role="status"
                style={{ width: '1.5rem', height: '1.5rem' }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            'Ingresar'
          )}
        </button>
      </form>
    </div>
  );
}
