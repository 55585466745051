import { useEffect, useState } from 'react';

import useRequest from './useRequest';

// const idTable = {
//   contacts: 'con_int_id',
//   trabajadores: 'tra_int_id',
//   equipos: 'equ_int_id',
//   proveedores: 'bus_int_id',
//   formatos: 'for_int_id',
//   users_proveedor: 'use_int_id',
//   users_business: 'use_int_id',
//   carga: 'upl_int_id',
//   clasificacion: 'fcl_int_id'
// };

export default function useScrollContainer({
  type,
  url,
  deleteUrl,
  URL_id = '',
  busId = '',
  params = []
}) {
  const { reqTokenBase } = useRequest();

  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const [keyword, setKeyword] = useState('');
  const [index, setIndex] = useState(20);

  const [hasMore, setHasMore] = useState(true);

  const [erreasing, setErreasing] = useState(false);
  const [edit, setEdit] = useState(false);

  const [loading, setLoading] = useState(false);

  const clearPrevData = () => {
    setData([]);
    setKeyword('');
    setIndex(20);
    setHasMore(true);
    setLoading(true);
  };

  const mapFn = (arr) => {
    let localArr = arr;

    let mapped = [];

    const nameKeysTable = {
      contacts: 'con_varchar_email',
      proveedores: 'bus_text_name',
      carga: 'upl_text_name',
      newsletter: 'nlt_text_subjet',
      cargamasiva: 'for_text_name'
    };

    const idKeysTable = {
      contacts: 'con_int_id',
      proveedores: 'bus_int_id',
      carga: 'upl_int_id',
      newsletter: 'nlt_int_id',
      cargamasiva: 'rma_int_id'
    };

    const deletedKeysTable = {
      proveedores: 'bus_date_deletion_date',
      contacts: 'con_date_deletion_date',
      newsletter: 'nlt_date_deletion_date',
      cargamasiva: 'rma_date_deletion_date'
    };

    if (type === 'users_business') {
      mapped = localArr.map((item) => ({
        name: item.use_text_name + ' ' + item.use_text_lastname,
        id: item.use_int_id,
        deleted: item.use_date_deletion_date ? true : false,
        item
      }));

      return mapped;
    }

    if (type === 'trabajadores') {
      mapped = localArr.map((item) => ({
        name: item.tra_varchar_nombres + ' ' + item.tra_varchar_apellidos,
        id: item.tra_int_id,
        qr: item.qr,
        deleted: item.tra_date_deletion_date ? true : false,
        item
      }));

      return mapped;
    }

    if (type === 'equipos') {
      mapped = localArr.map((item) => ({
        name: `Marca: ${item.equ_varchar_marca} - Modelo: ${item.equ_varchar_modelo}`,
        id: item.equ_int_id,
        deleted: item.equ_date_deletion_date ? true : false,
        item
      }));

      return mapped;
    }

    if (type === 'sent' || type === 'queue') {
      mapped = localArr.map((item) => ({
        name: item.ema_text_subject,
        id: item.ema_int_id,
        deleted: item.ema_date_deletion_date ? true : false,
        item: item
      }));

      return mapped;
    }

    mapped = localArr.map((item) => ({
      name: item[nameKeysTable[type]],
      id: item[idKeysTable[type]],
      deleted: item[deletedKeysTable[type]] ? true : false,
      item
    }));

    return mapped;
  };

  const getInitialData = async () => {
    try {
      clearPrevData();

      const res = await reqTokenBase.get(
        `${url}?b=${busId}&t=${busId}&l=20&o=0&i=${URL_id}&${params.join('&')}`
      );

      setData(mapFn(res.data.datos || res.data.data || res.data));

      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const getFilter = (e) => {
    e.preventDefault();

    setData([]);
    setIndex(20);
    setHasMore(false);
    setLoading(true);

    setTimeout(async () => {
      const res = await reqTokenBase.get(
        `${url}?b=${busId}&t=${busId}&l=20&o=0&i=${URL_id}&k=${keyword.replace(
          /\s/g,
          ' '
        )}&${params.join('&')}`
      );

      setData(mapFn(res.data.datos || res.data.data || res.data));

      setLoading(false);

      setHasMore(true);
    }, 1000);
  };

  const handleScroll = async () => {
    const res = await reqTokenBase.get(
      `${url}?b=${busId}&t=${busId}&l=20&o=${index}&i=${URL_id}&k=${keyword}&${params.join(
        '&'
      )}`
    );

    const resLength = res.data.datos
      ? res.data.datos.length
      : res.data.data
      ? res.data.data.length
      : res.data.length;

    if (resLength === 0) {
      setHasMore(false);
      return;
    }

    setData([...data, ...mapFn(res.data.datos || res.data.data || res.data)]);
    setIndex(index + 20);

    if (resLength < 20) {
      setHasMore(false);
    }
  };

  const handleDelete = async (id) => {
    setErreasing(true);

    try {
      await reqTokenBase.delete(`${deleteUrl || url}/${id}`);

      setErreasing(false);
    } catch (error) {
      console.log(error);
      setErreasing(false);
    }
  };

  const handleRestore = async (id) => {
    setEdit(true);

    try {
      await reqTokenBase.delete(`${deleteUrl || url}/${id}/restore`);

      setData(
        data.map((item) =>
          item.id === id ? { ...item, deleted: false } : item
        )
      );

      setEdit(false);
    } catch (error) {
      console.log(error);
      setEdit(false);
    }
  };

  const handleHardDelete = async (id) => {
    setErreasing(true);

    try {
      await reqTokenBase.delete(`${deleteUrl || url}/${id}/remove`);

      setData(data.filter((item) => item.id !== id));

      setErreasing(false);
    } catch (error) {
      console.log(error);
      setErreasing(false);
    }
  };

  useEffect(() => {
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return {
    clearPrevData,
    loading,
    data,
    error,
    keyword,
    setKeyword,
    hasMore,
    erreasing,
    edit,
    getFilter,
    handleScroll,
    handleDelete,
    handleRestore,
    handleHardDelete,

    getInitialData
  };
}
