export default function InputSelect({
  className,
  label,
  id,
  value,
  name,
  onChange,
  isInvalid,
  options,
  placeholder = "Selecciona una opción",
  disabled = false,
}) {
  return (
    <div className={className}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        name={name}
        onChange={onChange}
        value={value}
        className={`form-select input-bg-dark ${isInvalid ? "is-invalid" : ""}`}
        id={id}
        aria-describedby={`${id}Feedback`}
        disabled={disabled}
      >
        {!value && <option value="">{placeholder}</option>}
        {options?.map((item) => (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>

      {isInvalid && (
        <div id={`${id}Feedback`} className="invalid-feedback">
          {isInvalid}
        </div>
      )}
    </div>
  );
}
