import axios from "axios";
import qs from "qs";

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;
// const BASE_BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

export function headerToken() {
  return {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
    },
  };
}

export const adminUrl = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  transformRequest: [(data) => qs.stringify(data)],
});
