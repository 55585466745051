import useRequest from 'hooks/useRequest';
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import '../styles/ListItem.css';

export default function ListItem({
  module,
  id,
  title = '',
  item,
  deleted,
  handleDelete,
  handleHardDelete
}) {
  const history = useHistory();
  const { reqToken } = useRequest();

  const [isDeleted, setIsDeleted] = useState(deleted);
  const [loading, setLoading] = useState(false);

  const [hardDelete, setHardDelete] = useState(false);

  const titleForUrl = () => {
    return title.replace(/ /g, '-');
  };

  const customId = 'ListItemId' + id;
  const hashCustomId = '#' + customId.replaceAll(' ', '');
  const customRoute = '/module/' + module + '/' + id + '/' + titleForUrl();

  const deleteFunction = () => {
    handleDelete(id);
    setIsDeleted(true);
  };

  const handleRedirect = () => {
    history.push(customRoute);
  };

  const handleProveedores = () => {
    history.push(
      `/module/business_admin/${id}/${title.replaceAll(
        ' ',
        '-'
      )}/info/proveedores`
    );
  };

  const handleRestore = async () => {
    setLoading(true);
    await reqToken.delete(
      `${module === 'business_admin' ? 'business' : module}/${id}/restore`
    );
    setIsDeleted(false);
    setLoading(false);
  };

  const handleHardDeleteLocal = async () => {
    setLoading(true);
    handleHardDelete(id);
    setLoading(false);
  };

  return (
    <>
      <div className="custom-list-item list-group-item w-100 d-flex row px-0 mx-0 bg-light border border-bottom-0 hover-list-item">
        <div className="col-2 col-md-1 d-flex align-items-center fw-bold border-end">
          <p className="custom-list-item-title mb-0 text-truncate">{id}</p>
        </div>
        <div className="col-6 col-md-8 d-flex align-items-center border-end">
          <p className="custom-list-item-title mb-0 text-truncate">{title}</p>
        </div>
        <div className="col-4 col-md-3 custom-list-item-btns d-flex align-items-center justify-content-center gap-2">
          {!isDeleted && (
            <>
              <button
                onClick={handleRedirect}
                type="button"
                className="btn btn-secondary px-2 py-1 btn-sm"
              >
                <i className="far fa-edit"></i>
              </button>

              <button
                type="button"
                className="btn btn-danger px-2 py-1 btn-sm"
                data-bs-toggle="modal"
                data-bs-target={hashCustomId}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </>
          )}

          {isDeleted && (
            <>
              {!hardDelete && (
                <button
                  type="button"
                  className="btn btn-warning px-2 py-1"
                  onClick={handleRestore}
                  disabled={loading}
                >
                  Restaurar
                </button>
              )}

              {!hardDelete && (
                <button
                  type="button"
                  className="btn btn-danger px-2 py-1"
                  onClick={() => setHardDelete(true)}
                  disabled={loading}
                  title="Borrar permanentemente"
                >
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  <i className="far fa-trash-alt"></i>
                </button>
              )}

              {hardDelete && (
                <>
                  <button
                    type="button"
                    className="btn btn-danger px-2 py-1"
                    onClick={handleHardDeleteLocal}
                    disabled={loading}
                    title="Borrar permanentemente"
                  >
                    <i className="fas fa-exclamation-triangle me-2"></i>
                    Borrar
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary px-2 py-1"
                    onClick={() => setHardDelete(false)}
                    disabled={loading}
                  >
                    Cancelar
                  </button>
                </>
              )}
            </>
          )}

          {module === 'business_admin' && !isDeleted && (
            <>
              <button
                type="button"
                className="btn btn-primary px-2 py-1 btn-sm"
                // disabled={editProccess ? true : false}
                onClick={handleProveedores}
                title="Ver proveedores de esta empresa"
              >
                <i className="fas fa-truck-moving"></i>
              </button>

              {module === 'business_admin' && item?.sso_link && (
                <a
                  href={item.sso_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-success d-flex align-items-center btn-sm ms-1"
                  title="Ingresar como Administrador"
                >
                  <i className="fas fa-sign-in-alt"></i>
                </a>
              )}
            </>
          )}
        </div>
      </div>

      <ItemDeleteModal
        key={customId}
        customId={customId}
        title={title}
        deleteFunction={deleteFunction}
      />
    </>
  );
}

const ItemDeleteModal = ({ customId, title, deleteFunction }) => {
  return (
    <div
      className="modal fade"
      id={customId}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h5
              className="modal-title gap-3 text-danger d-flex align-items-center"
              id="deleteModalLabel"
            >
              Advertencia
              <i className="fas fa-exclamation-triangle"></i>
            </h5>
            <button
              type="button"
              className="btn-close-modal btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fas fa-times fa-lg"></i>
            </button>
          </div>
          <div className="modal-body">
            <p className="mb-0 text-center">
              Estás por borrar el item con nombre
              <br />
              <span className="fw-bold">{title}</span>
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              onClick={deleteFunction}
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Borrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
