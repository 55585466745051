import DeleteModal from 'components/CrudListItemComponents/DeleteModal';
import React, { useState } from 'react';

export default function ListItem({
  type,
  data,
  edit,
  assigning,
  setShowFormats,
  id,
  busId,
  setStep,
  setInfo,
  erreasing,
  handleDelete,
  handleRestore,
  handleHardDelete
}) {
  const [isDeleted, setIsDeleted] = useState(data.deleted);
  const [hardDelete, setHardDelete] = useState(false);

  const customDeleteId = 'list-item-delete-' + id;
  const hashCustomDeleteId = '#' + customDeleteId.replaceAll(' ', '');

  const handleEdit = () => {
    setStep(1);
    setInfo({
      id: data.typ_int_id,
      name: data.typ_text_name
    });
  };

  const handleEditFormats = () => {
    setStep(2);
    setInfo({
      id: data.for_int_id,
      name: data.for_text_name
    });
  };

  const handleLocalDelete = () => {
    handleDelete(id);
    setIsDeleted(true);
  };

  const handleLocalRestore = () => {
    handleRestore(id);
    setIsDeleted(false);
  };

  const handleLocalHardDelete = () => {
    handleHardDelete(id);
    setHardDelete(false);
    setIsDeleted(false);
  };

  if (type === 'business') {
    return (
      <div className="row w-100 mx-0 px-0 align-items-center border border-bottom-0 hover-list-item">
        <DeleteModal
          customId={customDeleteId}
          title={data.typ_text_name}
          id={data.typ_int_id}
          handleDelete={handleLocalDelete}
          erreasing={erreasing}
        />

        <p className="col-1 mb-0 py-2 border-end text-center">
          {data.typ_int_id}
        </p>
        <p className="col-7 mb-0 py-2 border-end">{data.typ_text_name}</p>
        <div className="col-4 d-flex align-items-center justify-content-center gap-2 py-2">
          {!isDeleted && (
            <>
              <button
                onClick={() => edit(type, data.typ_int_id, data.isAssigned)}
                disabled={assigning ? true : false}
                className={`btn px-2 py-1 ${
                  data.isAssigned
                    ? 'btn-success btn-sm'
                    : 'btn-secondary btn-sm'
                }`}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Asignar tipo"
              >
                <i className="fas fa-check"></i>
              </button>

              {/* solo si la lista es para business para poder ver los formatos de cada tipo */}
              <button
                onClick={() =>
                  setShowFormats({
                    businessId: busId,
                    typeId: data.typ_int_id,
                    typeTitle: data.typ_text_name,
                    show: true
                  })
                }
                type="button"
                className="btn px-2 py-1 btn-warning btn-sm"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Asignar formatos"
                disabled={type === 'business' && data.isAssigned ? false : true}
              >
                <i className="fas fa-list"></i>
              </button>

              <button
                onClick={handleEdit}
                type="button"
                disabled={assigning ? true : false}
                className="btn btn-secondary btn-sm ms-3"
                title="Editar tipo"
              >
                <i className="far fa-edit"></i>
              </button>

              <button
                type="button"
                disabled={assigning ? true : false}
                className="btn btn-danger btn-sm"
                title="Borrar tipo"
                data-bs-toggle="modal"
                data-bs-target={hashCustomDeleteId}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </>
          )}

          {isDeleted && (
            <>
              {!hardDelete && (
                <button
                  type="button"
                  className="btn btn-warning px-2 py-1"
                  onClick={handleLocalRestore}
                  disabled={erreasing}
                >
                  Restaurar
                </button>
              )}

              {!hardDelete && (
                <button
                  type="button"
                  className="btn btn-danger px-2 py-1"
                  onClick={() => setHardDelete(true)}
                  disabled={erreasing}
                  title="Borrar permanentemente"
                >
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  <i className="far fa-trash-alt"></i>
                </button>
              )}

              {hardDelete && (
                <>
                  <button
                    type="button"
                    className="btn btn-danger px-2 py-1"
                    onClick={handleLocalHardDelete}
                    disabled={erreasing}
                    title="Borrar permanentemente"
                  >
                    <i className="fas fa-exclamation-triangle me-2"></i>
                    Borrar
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary px-2 py-1"
                    onClick={() => setHardDelete(false)}
                    disabled={erreasing}
                  >
                    Cancelar
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  if (type === 'formats') {
    return (
      <div className="row w-100 mx-0 px-0 align-items-center border border-bottom-0 hover-list-item">
        <DeleteModal
          customId={customDeleteId}
          title={data.for_text_name}
          id={data.for_int_id}
          handleDelete={handleLocalDelete}
          erreasing={erreasing}
        />

        <p className="col-1 mb-0 py-2 border-end text-center">
          {data.for_int_id}
        </p>
        <p className="col-7 mb-0 py-2 border-end">{data.for_text_name}</p>
        <div className="col-4 d-flex align-items-center justify-content-center gap-2 py-2">
          {!isDeleted && (
            <>
              <button
                onClick={({ target }) =>
                  edit(target, data.for_int_id, data.isAssigned)
                }
                disabled={assigning ? true : false}
                className={`btn px-2 py-1 btn-sm ${
                  data.isAssigned ? 'btn-success' : 'btn-secondary'
                }`}
              >
                {data.for_int_tipo === '1' && (
                  <i
                    className="fas fa-book"
                    style={{ pointerEvents: 'none' }}
                  ></i>
                )}

                {data.for_int_tipo === '2' && (
                  <i
                    className="far fa-building"
                    style={{ pointerEvents: 'none' }}
                  ></i>
                )}

                {data.for_int_tipo === '3' && (
                  <i
                    className="fas fa-user-friends"
                    style={{ pointerEvents: 'none' }}
                  ></i>
                )}

                {data.for_int_tipo === '4' && (
                  <i
                    className="fas fa-tools"
                    style={{ pointerEvents: 'none' }}
                  ></i>
                )}
              </button>

              <button
                onClick={handleEditFormats}
                type="button"
                disabled={assigning ? true : false}
                className="btn btn-secondary btn-sm ms-3"
                title="Editar tipo"
              >
                <i className="far fa-edit"></i>
              </button>

              <button
                type="button"
                disabled={assigning ? true : false}
                className="btn btn-danger btn-sm"
                title="Borrar formato"
                data-bs-toggle="modal"
                data-bs-target={hashCustomDeleteId}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </>
          )}

          {isDeleted && (
            <>
              {!hardDelete && (
                <button
                  type="button"
                  className="btn btn-warning px-2 py-1"
                  onClick={handleLocalRestore}
                  disabled={erreasing}
                >
                  Restaurar
                </button>
              )}

              {!hardDelete && (
                <button
                  type="button"
                  className="btn btn-danger px-2 py-1"
                  onClick={() => setHardDelete(true)}
                  disabled={erreasing}
                  title="Borrar permanentemente"
                >
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  <i className="far fa-trash-alt"></i>
                </button>
              )}

              {hardDelete && (
                <>
                  <button
                    type="button"
                    className="btn btn-danger px-2 py-1"
                    onClick={handleLocalHardDelete}
                    disabled={erreasing}
                    title="Borrar permanentemente"
                  >
                    <i className="fas fa-exclamation-triangle me-2"></i>
                    Borrar
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary px-2 py-1"
                    onClick={() => setHardDelete(false)}
                    disabled={erreasing}
                  >
                    Cancelar
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  if (type === 'users') {
    return (
      <div className="row w-100 mx-0 px-0 py-2 align-items-center border">
        <p className="col-2 mb-0">{data.acc_int_id}</p>
        <p className="col-6 mb-0">{data.acc_text_description}</p>
        <div className="col-4 d-flex align-items-center justify-content-center gap-2">
          <button
            onClick={() => edit(type, data.acc_int_id, data.isAssigned)}
            disabled={assigning ? true : false}
            className={`btn px-2 py-1 ${
              data.isAssigned ? 'btn-success' : 'btn-secondary'
            }`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Asignar tipo"
          >
            <i className="far fa-check-square"></i>
          </button>
          {data.isAssigned && (
            <a
              href={data.url}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-warning px-2 py-1"
              title={`Inicicar sesión como ${data.acc_text_description}`}
            >
              <i className="fas fa-sign-in-alt"></i>
            </a>
          )}
        </div>
      </div>
    );
  }
}
