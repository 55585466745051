/* eslint-disable react/jsx-pascal-case */
import FullWidthContainer from 'components/layout/FullWidthContainer';
import { Modal_info } from 'components/reporte-docs-lectura/Modal-info';
import Autocomplete from 'components/select-search/Autocomplete';
import { GET, POST } from 'helpers/request';
import { useState } from 'react';

export default function ReportDocsLectura() {
  const [data, setData] = useState(null);
  const [formatsLists, setFormatsLists] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [logs, setLogs] = useState(null);

  const [information, setInformation] = useState({
    businessId: '',
    documentId: '',
    formatId: ''
  });

  const getDocs = async (businessId) => {
    setInformation({ ...information, businessId });
    setData(null);

    const res = await POST(
      {
        cual: '24',
        que: 'f',
        bus_int_pid: businessId
      },
      // { cual: '21', bus_int_pid: 280, for_int_id: 906, xls: '0' },
      'reports',
      null,
      null
    );

    setFormatsLists(res[0].valor);
  };

  const getData = async (formatId) => {
    const { for_int_id, r2r_int_id } = formatsLists.find(
      (format) => format.for_int_id === formatId
    );

    setLoading(true);
    setData([]);
    setInformation({
      ...information,
      formatId: for_int_id,
      documentId: r2r_int_id
    });

    const res = await POST(
      {
        cual: '24',
        que: 'b',
        bus_int_pid: information.businessId,
        r2r: r2r_int_id,
        f: formatId
      },
      // { cual: '21', bus_int_pid: 280, for_int_id: 906, xls: '0' },
      'reports',
      null,
      null
    );

    // console.log({ res });

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    if (res[0].valor) {
      setData(res[0].valor);
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const getLogs = async (businessId) => {
    setLoadingLogs(true);

    const res = await POST(
      {
        cual: '24',
        que: 'l',
        bus_int_pid: businessId,
        r2r: information.documentId
      },
      // { cual: '21', bus_int_pid: 280, for_int_id: 906, xls: '0' },
      'reports',
      null,
      null
    );

    setLogs(res[0].valor);
    setLoadingLogs(false);
  };

  return (
    <FullWidthContainer>
      <Modal_info logs={logs} loading={loadingLogs} />

      <h2 className="text-center mb-5">
        Reporte documentos de lectura por empresa
      </h2>

      <div className="d-flex align-items-end gap-3 mb-3">
        <Autocomplete
          label="Selecciona una empresa"
          classes="flex-grow-1"
          url="business_admin"
          //son los nombres de los campos de el esquema, de id y de nombre
          valueField="bus_int_id"
          nameField="bus_text_name"
          placeholder="Escoja una opción"
          onChange={(business) => {
            setFormatsLists(null);
            getDocs(business.value);
          }}
        />

        {formatsLists && (
          <div className="d-flex flex-column" style={{ maxWidth: '50%' }}>
            <label htmlFor="documento">Formato</label>
            <select
              id="documento"
              className="form-select input-bg-dark"
              onChange={(e) => {
                const value = e.target.value;
                getData(value);
              }}
            >
              <option>Selecciona una opcion</option>
              {formatsLists.map((formato) => (
                <option
                  onChange={(formato) => console.log(formato)}
                  key={formato.for_int_id}
                  value={formato.for_int_id}
                >
                  {formato.for_text_name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading && data && data.length > 0 && (
        <table class="table">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Empresa</th>
              <th scope="col">Asignado</th>
              <th scope="col">Leido</th>
              <th scope="col">Info</th>
            </tr>
          </thead>
          <tbody>
            {data.map((business) => (
              <tr key={business.bus_int_id}>
                <th scope="row">{business.bus_int_id}</th>
                <td>{business.bus_text_name}</td>
                <td>
                  {business.asignado === 't' ? (
                    <span className="fas fa-check text-success text-center w-100"></span>
                  ) : (
                    <span className="fas fa-close text-danger text-center w-100"></span>
                  )}
                </td>
                <td>
                  {business.leido === 't' ? (
                    <span className="fas fa-check text-success text-center w-100"></span>
                  ) : (
                    <span className="fas fa-times text-danger text-center w-100"></span>
                  )}
                </td>
                <td>
                  {business.asignado === 't' && (
                    <button
                      onClick={() => getLogs(business.bus_int_id)}
                      disabled={loadingLogs}
                      className="btn btn-secondary btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#report-docs-lectura-modal"
                    >
                      <span className="fas fa-eye"></span>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </FullWidthContainer>
  );
}
