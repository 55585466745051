import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { POST } from 'helpers/request';
import Autocomplete from 'components/select-search/Autocomplete';

export default function ReportValidation() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = async (id) => {
    setLoading(true);
    const res = await POST(
      { cual: '2', bus_int_pid: id },
      'reports',
      null,
      null
    );
    setData(res[0]);
    setLoading(false);
  };

  const history = useHistory();
  const situDocBussRoute = '/situacionDocumental/';

  const handleBusinessClick = (id) => {
    history.push(`${situDocBussRoute}${id}/0/0`);
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">
        Reporte de empresas con documentos por validar
      </h2>

      <Autocomplete
        label="Selecciona una empresa"
        classes="w-100 pb-3"
        url="business_admin"
        //son los nombres de los campos de el esquema, de id y de nombre
        valueField="bus_int_id"
        nameField="bus_text_name"
        placeholder="Escoja una opción"
        onChange={(item) => {
          getData(item.value);
        }}
      />

      {loading && (
        <div className="text-center border p-3 rounded">Cargando...</div>
      )}

      {!loading && data?.valor && <Headers />}

      {!loading &&
        data?.valor.length > 0 &&
        data?.valor.map((item) => (
          <Item
            key={item.bus_int_id}
            id={item.bus_int_id}
            name={item.bus_text_name}
            number={item.porvalidar}
            handleBusinessClick={handleBusinessClick}
          />
        ))}

      {!loading && data?.valor.length === 0 && (
        <div className="alert alert-warning text-center">Sin datos</div>
      )}
    </FullWidthContainer>
  );
}

const Item = ({ id, name, number, handleBusinessClick }) => {
  return (
    <div className="row mx-0 w-100 border rounded">
      <span className="col-2 text-center border-end py-3">{id}</span>
      <span
        className="sit-doc-item-row col-5 text-start word-break border-end py-3"
        style={{ cursor: 'pointer' }}
        onClick={() => handleBusinessClick(id)}
      >
        {name}
      </span>
      <span className="col-5 text-center py-3">{number}</span>
    </div>
  );
};
const Headers = () => {
  return (
    <div className="row mx-0 w-100 border rounded bg-secondary mb-4">
      <span className="col-2 text-center text-white fw-bold border-end py-2">
        Id
      </span>
      <span className="col-5 text-center text-white fw-bold border-end py-2">
        Nombre
      </span>
      <span className="col-5 text-center text-white fw-bold py-2">
        Documentos por validar
      </span>
    </div>
  );
};
