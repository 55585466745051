import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { GET } from 'helpers/request';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import SituDocItemCategory from 'components/situ-doc-components/SituDocItemCategory';
import SituDocItemRow from 'components/situ-doc-components/SituDocItemRow';

import { DefaultInput } from 'templates/input';

import { Link } from 'react-router-dom';
import Autocomplete from 'components/select-search/Autocomplete';

export default function SituacionDocumental() {
  const { paramsBusinessId, paramBusName, paramCatId } = useParams();

  // object with id and name
  const [businessData, setBusinessData] = useState(null);
  const [loadingBusinessData, setLoadingBusinessData] = useState(false);

  // business situacion documental data
  const [originalInfo, setOriginalInfo] = useState(null);
  const [businessInfo, setBusinessInfo] = useState(null);

  // each category info
  const [categoryInfo, setCategoryInfo] = useState(null);

  // info para el go back
  const [filtersInfo, setFiltersInfo] = useState([]);
  const [openedCategory, setOpenedCategory] = useState('');

  const [loading, setLoading] = useState(false);

  const [SSOLink, setSSOLink] = useState('');

  const getBusinessInfo = useCallback(async (id) => {
    if (id === '0') return setLoading(false);

    setLoadingBusinessData(true);
    setBusinessInfo(null);

    const data = await GET('situacion_documental_admin', id);

    setSSOLink(data.urlsso);

    let newArr = [];

    for (const key in data) {
      if (key !== 'infotipos' && key !== 'sql' && key !== 'urlsso') {
        newArr.push({
          category: data.infotipos.find((item) => item.typ_int_id === key),
          data: data[key]
        });
      }
    }

    setOriginalInfo(newArr);
    setBusinessInfo(newArr);

    if (filtersInfo.length === 0) {
      setFiltersInfo(
        newArr.map((item) => ({ id: item.category.typ_int_id, query: '' }))
      );
    }

    setLoading(false);
    setLoadingBusinessData(false);

    //eslint-disable-next-line
  }, []);

  const getCategoryInfo = async (categoryId) => {
    setLoading(true);

    const data = await GET('situacion_documental', categoryId);
    setCategoryInfo(data);
    setLoading(false);
  };

  // click on select, set businessData with full option data and send ID for search
  const handleSelectClick = (item) => {
    setBusinessData(item);
    getBusinessInfo(item.value);
  };

  const handleCategoryClick = (categoryId, parentId) => {
    getCategoryInfo(categoryId);
    setOpenedCategory(parentId);
  };

  const handleGoBack = () => {
    setCategoryInfo(null);
    getBusinessInfo(businessData.value);
  };

  const filterByType = (id, type) => {
    setBusinessInfo(
      businessInfo.map((item) =>
        item.category.typ_int_id === id
          ? {
              ...item,
              data: item.data.map((category) =>
                category.cat_int_tipo === type
                  ? { ...category, hide: false }
                  : // : { ...category, hide: id }
                    { ...category, hide: category.hide === type ? false : type }
              )
            }
          : item
      )
    );
  };

  const filterByQuery = (id, query) => {
    setBusinessInfo(
      originalInfo.map((item) =>
        item.category.typ_int_id === id
          ? {
              ...item,
              data: item.data.filter((category) =>
                category.cat_text_name
                  .toLowerCase()
                  .includes(query.toLowerCase())
              )
            }
          : item
      )
    );
  };

  const sortByType = (id, position) => {
    setBusinessInfo(
      businessInfo.map((item) =>
        item.category.typ_int_id === id
          ? {
              ...item,
              data: item.data.sort(
                (a, b) =>
                  b.situaciondocumental.replace(/[()]/g, '').split(',')[
                    position
                  ] -
                  a.situaciondocumental.replace(/[()]/g, '').split(',')[
                    position
                  ]
              )
            }
          : item
      )
    );
  };

  const handleRightClick = (id, position) => {
    const nodes = document.getElementsByClassName(
      `col-cat-${id}-pos-${position}`
    );

    const nodesArray = Array.from(nodes);

    nodesArray.forEach((node) => node.classList.toggle('hide-inner-text'));
  };

  const titleToString = (string) => {
    // if (string.includes("*")) {
    //   return string.slice(2, string.length).replace(/ /g, "-");
    // }
    return string.replace(/-/g, ' ');
  };

  useEffect(() => {
    if (paramsBusinessId !== '0') {
      getBusinessInfo(paramsBusinessId);
      setBusinessData({
        label: paramBusName,
        value: paramsBusinessId
      });

      if (paramCatId !== '0') {
        getCategoryInfo(paramCatId);
      }

      return;
    }

    //eslint-disable-next-line
  }, [getBusinessInfo, paramsBusinessId]);

  useEffect(() => {
    if (businessInfo && openedCategory) {
      document.getElementById(`cat_toggle_btn_${openedCategory}`)?.click();
      setTimeout(() => {
        document
          .getElementById(`cat_togglesearch_btn_${openedCategory}`)
          ?.click();
      }, 100);

      setOpenedCategory('');
    }

    //eslint-disable-next-line
  }, [businessInfo]);

  return (
    <FullWidthContainer>
      <h3 className="text-center mb-3 text-break">
        [ Tipo Requisito ] Situación Documental{' '}
        {businessData && businessData.label !== '0' && (
          <Link
            to={`/module/business_admin/${
              businessData.value
            }/${businessData.label.replaceAll(' ', '-')}`}
            className="text-decoration-none h5"
          >
            ( {titleToString(businessData.label)} )
          </Link>
        )}
      </h3>

      {!loading && !categoryInfo && (
        <div className="d-flex gap-3 align-items-end mb-5 pb-3">
          <Autocomplete
            label="Selecciona una empresa"
            classes="w-100"
            url="business"
            placeholder="Escoja una opción"
            onChange={(item) => handleSelectClick(item)}
            paramsBusinessId={
              businessData ? businessData.value : paramsBusinessId
            }
            setBusinessData={setBusinessData}
            valueField="bus_int_id"
            nameField="bus_text_name"
          />

          {businessData && SSOLink && (
            <a
              href={SSOLink}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-warning d-flex align-items-center"
              title="Ingresar como usuario"
            >
              Ingresar <i className="fas fa-sign-in-alt ms-2"></i>
            </a>
          )}
        </div>
      )}

      {/* if loading business data */}
      {!loading && loadingBusinessData && (
        <div className="pt-5 pb-2 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {/* if business info and no categoryInfo, show business info */}
      {!loading && !categoryInfo && businessInfo?.length > 0 && (
        <div className="d-flex flex-column w-100 mt-2">
          {businessInfo.map((group) => (
            <div key={group.category.typ_int_id} className="mb-5 pb-3">
              <Headers
                title={group.category.typ_text_name}
                id={group.category.typ_int_id}
                filterByType={filterByType}
                filterByQuery={filterByQuery}
                sortByType={sortByType}
                handleRightClick={handleRightClick}
                collapseId={`collpase-cat-${group.category.typ_int_id}`}
                categoryData={group.data}
                setFiltersInfo={setFiltersInfo}
                filtersInfo={filtersInfo}
                openedCategory={openedCategory}
              />
              <div
                className="w-100 collapse"
                id={`collpase-cat-${group.category.typ_int_id}`}
              >
                {group.data.map(
                  (item) =>
                    !item.hide && (
                      <SituDocItemRow
                        key={item.cat_int_id}
                        item={item}
                        onClick={handleCategoryClick}
                        id={group.category.typ_int_id}
                        idField="typ_int_id"
                      />
                    )
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* if business lenght is less than 0 return this */}
      {!loading && !categoryInfo && businessInfo?.length === 0 && (
        <div className="alert alert-danger w-100 text-center fw-bold mt-2">
          Esta empresa no cuenta con registros asignados
        </div>
      )}

      {/* if categoryInfo, show categoryInfo */}
      {!loading && categoryInfo && (
        <SituDocItemCategory
          data={categoryInfo}
          goBack={handleGoBack}
          businessData={businessData}
          getCategoryInfo={getCategoryInfo}
        />
      )}

      {loading && (
        <div className="d-flex justify-content-center text-secondary my-auto">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </FullWidthContainer>
  );
}

// prettier-ignore
const Headers = ({ title, id, filterByType, filterByQuery, sortByType, handleRightClick, collapseId, categoryData, setFiltersInfo, filtersInfo, openedCategory }) => {
  const [active, setActive] = useState('')
  const [showCategory, setShowCategory] = useState(false)
  const [totalValues, setTotalValues] = useState(null)
  const [search, setSearch] = useState(false)
  const [query, setQuery] = useState('')

  const handleActive = (id) => {
    active === id ? setActive('') : setActive(id)
  }

  const handleShow = () => {
    setShowCategory(!showCategory)
    setSearch(false)
    setQuery('')
  }

  const sum = (r, a) => r.map((b, i) => a[i] + b);

  const handleToggleSearch = () => {
    if(!search) {
      setTimeout(() => {
        document.getElementById(`search_cat_${id}`).focus()
      }, 100);
    }

    if(search) {
      setQuery('')
      filterByQuery(id, '')
      setFiltersInfo(prevData => prevData.map(item => item.id === id ? {...item, query: ''} : item))
    }

    setSearch(!search)
  }

  const handleSearch = ({target}) => {
    setQuery(target.value)
    filterByQuery(id, target.value)
    setFiltersInfo(prevData => prevData.map(item => item.id === id ? {...item, query: target.value} : item))
  }

  useEffect(() => {
    const arr = categoryData.map(item => item.situaciondocumental.replace(/[()]/g, "").split(",").map(item => Number(item)))
    setTotalValues(arr.reduce(sum))

    setTimeout(() => {
      if(openedCategory === id) {
        const currentFilter = filtersInfo.find(item => item.id === id)
  
        if(currentFilter && currentFilter.query !== '') {
          filterByQuery(id, currentFilter.query)
          setQuery(currentFilter.query)
          setFiltersInfo(prevData => prevData.map(item => item.id === id ? {...item, query: currentFilter.query} : item))
        }
      }
    }, 100);

    //eslint-disable-next-line
  }, [])      

  return (
    <div className={`w-100 bg-gray text-white row mx-0 border border-bottom${active ? '-0' : ''} position-relative`}>
      <button id={`cat_toggle_btn_${id}`} onClick={handleShow} className="btn text-white btn-sm bg-gray border-bottom-0 collapse-absolute-btn border rounded-0 w-auto me-0" type="button" data-bs-toggle="collapse" data-bs-target={`#${collapseId}`} aria-expanded="false" aria-controls={collapseId}>
        {showCategory ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
      </button>

      <div className="row row-cols-2 gy-3 mt-0 mx-0 col-1 pb-3 border-end">
        <div className={`col d-flex justify-content-center ${!showCategory && 'invisible'}`}>
          <i
            className={`fas fa-book hover-primary w-50 d-flex justify-content-center ${active === '1' && 'green-theme'}`}
            style={{ cursor: "pointer" }}
            onClick={() => {filterByType(id, '1'); handleActive('1')}}
          />
        </div>

        <div className={`col d-flex justify-content-center ${!showCategory && 'invisible'}`}>
          <i 
            className={`far fa-building hover-primary w-50 d-flex justify-content-center ${active === '2' && 'green-theme'}`}
            style={{ cursor: "pointer" }}
            onClick={() => {filterByType(id, '2'); handleActive('2')}} 
          />
        </div>

        <div className={`col d-flex justify-content-center ${!showCategory && 'invisible'}`}>
          <i
            className={`fas fa-user-friends hover-primary w-50 d-flex justify-content-center ${active === '3' && 'green-theme'}`}
            style={{ cursor: "pointer" }}
            onClick={() => {filterByType(id, '3'); handleActive('3')}}
          />
        </div>

        <div className={`col d-flex justify-content-center ${!showCategory && 'invisible'}`}>
          <i
            className={`fas fa-tools hover-primary w-50 d-flex justify-content-center ${active === '4' && 'green-theme'}`}
            style={{ cursor: "pointer" }}
            onClick={() => {filterByType(id, '4'); handleActive('4')}}
          />
        </div>
      </div>

      <div className="col-5 border-end flex-grow-1 d-flex align-items-center">
        {!search && 
          <div 
            className="w-100 d-flex align-items-center"
            onClick={handleShow} 
            style={{ cursor: "pointer" }} 
            data-bs-toggle="collapse" 
            data-bs-target={`#${collapseId}`} 
            aria-expanded="false" 
            aria-controls={collapseId} 
          >
            <p className="fw-bold text-center mb-0 w-100" style={{pointerEvents: 'none'}}>
              {title}
            </p>
          </div>
        }

        <DefaultInput 
          customClass={`w-100 ${search ? '' : 'd-none'}`}
          placeholder='Nombre de categoría'
          value={query}
          onChange={handleSearch}
          id={`search_cat_${id}`}
          mb='0'
        />

        {showCategory && (
          <button id={`cat_togglesearch_btn_${id}`} onClick={handleToggleSearch} className="btn ms-2">
            {!search ? <i className="fas fa-search text-white"></i> : <i className="fas fa-times text-white"></i>}
          </button>
        )}
      </div>

      <span style={{ cursor: "pointer" }} onClick={() => sortByType(id, '0')} onContextMenu={e => {e.preventDefault(); handleRightClick(id,'0')}} className="fw-bold text-center col-1 py-3 text-truncate border-end">
        Válidos
        {!showCategory && totalValues && <>
          <br />
          <b className={`w-100 ${totalValues[0]===0 ? 'text-danger':''}`}>{totalValues[0]}</b>
        </>}
      </span>
      <span style={{ cursor: "pointer" }} onClick={() => sortByType(id, '1')} onContextMenu={e => {e.preventDefault(); handleRightClick(id,'1')}} className="fw-bold text-center col-1 py-3 text-truncate border-end">
        Caducados
        {!showCategory && totalValues && <>
          <br />
          <b className={`w-100 ${totalValues[1]===0 ? '':'text-danger'}`}>{totalValues[1]}</b>
        </>}
      </span>
      <span style={{ cursor: "pointer" }} onClick={() => sortByType(id, '2')} onContextMenu={e => {e.preventDefault(); handleRightClick(id,'2')}} className="fw-bold text-center col-1 py-3 text-truncate border-end">
        Inexistentes
        {!showCategory && totalValues && <>
          <br />
          <b className={`w-100 ${totalValues[2]===0 ? '':'text-danger'}`}>{totalValues[2]}</b>
        </>}
      </span>
      <span style={{ cursor: "pointer" }} onClick={() => sortByType(id, '3')} onContextMenu={e => {e.preventDefault(); handleRightClick(id,'3')}} className="fw-bold text-center col-1 py-3 text-truncate border-end">
        Por validar
        {!showCategory && totalValues && <>
          <br />
          <b className={`w-100 ${totalValues[3]===0 ? '':'text-success'}`}>{totalValues[3]}</b>
        </>}
      </span>
      <span style={{ cursor: "pointer" }} onClick={() => sortByType(id, '4')} onContextMenu={e => {e.preventDefault(); handleRightClick(id,'4')}} className="fw-bold text-center col-1 py-3 text-truncate border-end">
        No válidos
        {!showCategory && totalValues && <>
          <br />
          <b className={`w-100`}>{totalValues[4]}</b>
        </>}
      </span>
      <span style={{ cursor: "pointer" }} onClick={() => sortByType(id, '5')} onContextMenu={e => {e.preventDefault(); handleRightClick(id,'5')}} className="fw-bold text-center col-1 py-3 text-truncate">
        Histórico
        {!showCategory && totalValues && <>
          <br />
          <b className="w-100">{totalValues[5]}</b>
        </>}
      </span>
    </div>
  );
};
