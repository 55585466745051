// import axios from "axios";
import AlertMessage from 'components/layout/AlertMessage';
import { POST, PUT } from 'helpers/request';
import { useForm } from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DefaultInput } from 'templates/input';

const defaultValues = {
  bus_int_id: '',
  pla_text_nombre: '',
  pla_ctrabajadores: '',
  pla_cequipos: '',
  pla_ctotal: '',
  pla_rtrabajadores: '',
  pla_requipos: '',
  pla_rempresa: '',
  pla_rtotal: '',
  pla_valor: ''
};

export default function PlanesForm({
  module,
  id,
  data,
  title,
  handleGoBack,
  busId
}) {
  const { reqToken } = useRequest();
  const [values, handleInputChange, reset] = useForm(
    data || { ...defaultValues, bus_int_id: busId }
  );
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const [businessData, setBusinessData] = useState(null);

  const getBusinessData = async () => {
    const res = await reqToken.get(
      `business_admin/${id === '0' ? busId : values?.bus_int_id}`
    );
    setBusinessData(res.data);
  };

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    if (id === '0') {
      const data = await POST(values, module, setError);
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setSuccess(data ? data.status : 500);
    }

    //prettier-ignore
    if (id !== "0") {
      const data = await PUT(defaultValues, values, module, id, setError, reset);
      setLoading(false)
      window.scrollTo({ top: 0, behavior: "smooth" });
      setSuccess(data ? data.status : 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        history.push(
          `/crud/planes/${busId}/${businessData.bus_text_name.replace(
            /\s/g,
            '-'
          )}`
        );
      }, 3000);
    }

    //eslint-disable-next-line
  }, [success, history]);

  useEffect(() => {
    getBusinessData();

    //eslint-disable-next-line
  }, []);

  const {
    bus_int_id,
    pla_text_nombre,
    pla_ctrabajadores,
    pla_cequipos,
    pla_ctotal,
    pla_rtrabajadores,
    pla_requipos,
    pla_rempresa,
    pla_rtotal,
    pla_valor
  } = values;

  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 p-3 p-xl-5 d-flex flex-column mt-3"
      autoComplete="off"
    >
      {id !== '0' ? (
        <h2 className="w-100 text-center mb-3 mb-lg-5">
          Editar plan ( {title.replace(/-/g, ' ')} )
        </h2>
      ) : (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Nuevo plan</h2>
      )}

      <div className="d-flex justify-content-start align-items-center mb-4 gap-3">
        <button
          className="btn btn-secondary me-auto btn-sm"
          onClick={() =>
            bus_int_id
              ? handleGoBack(bus_int_id, businessData.bus_text_name)
              : history.goBack()
          }
          type="button"
        >
          <i className="fa fas fa-caret-left fa-lg me-2"></i> Volver a la lista
        </button>
      </div>

      {success && <AlertMessage status={success} id={id} />}

      <DefaultInput
        label="Nombre"
        type="text"
        value={pla_text_nombre}
        inputName="pla_text_nombre"
        onChange={handleInputChange}
        placeholder="Ingresa una descripción"
        error={errorData?.pla_text_nombre}
      />

      <h4 className="mt-3">Cantidad de:</h4>

      <DefaultInput
        label="Trabajadores"
        type="number"
        value={pla_ctrabajadores}
        inputName="pla_ctrabajadores"
        onChange={handleInputChange}
        placeholder="Ingresa un valor"
        error={errorData?.pla_ctrabajadores}
      />

      <DefaultInput
        label="Equipos"
        type="number"
        value={pla_cequipos}
        inputName="pla_cequipos"
        onChange={handleInputChange}
        placeholder="Ingresa un valor"
        error={errorData?.pla_cequiposD}
      />

      <DefaultInput
        label="Total"
        type="number"
        value={pla_ctotal}
        inputName="pla_ctotal"
        onChange={handleInputChange}
        placeholder="Ingresa un valor"
        error={errorData?.pla_ctotalELD}
      />

      <h4 className="mt-3">Requisitos</h4>

      <DefaultInput
        label="Trabajadores"
        type="number"
        value={pla_rtrabajadores}
        inputName="pla_rtrabajadores"
        onChange={handleInputChange}
        placeholder="Ingresa un valor"
        error={errorData?.pla_rtrabajadores}
      />

      <DefaultInput
        label="Equipos"
        type="number"
        value={pla_requipos}
        inputName="pla_requipos"
        onChange={handleInputChange}
        placeholder="Ingresa un valor"
        error={errorData?.pla_requiposD}
      />

      <DefaultInput
        label="Empresa"
        type="number"
        value={pla_rempresa}
        inputName="pla_rempresa"
        onChange={handleInputChange}
        placeholder="Ingresa un valor"
        error={errorData?.pla_rempresaD}
      />

      <DefaultInput
        label="Total"
        type="number"
        value={pla_rtotal}
        inputName="pla_rtotal"
        onChange={handleInputChange}
        placeholder="Ingresa un valor"
        error={errorData?.pla_rtotalELD}
      />

      <DefaultInput
        label="Valor del plan"
        type="number"
        value={pla_valor}
        inputName="pla_valor"
        onChange={handleInputChange}
        placeholder="Ingresa un valor"
        error={errorData?.pla_valor}
      />

      <button
        type="submit"
        className="btn btn-primary mt-4 w-auto ms-auto"
        disabled={loading ? true : false}
      >
        {loading ? (
          <>
            <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
          </>
        ) : id !== '0' ? (
          <>
            Editar
            <i className="fas fa-save ms-2"></i>
          </>
        ) : (
          <>
            Guardar
            <i className="fas fa-save ms-2"></i>
          </>
        )}
      </button>
    </form>
  );
}
