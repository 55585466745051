import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MobileSideBar from 'components/layout/MobileSideBar';
import UserCard from 'components/layout/UserCard';
import useAuth from 'auth/useAuth';

import logo from 'images/flex---navbar.jpg';
import logo_dark from 'images/flex_dark---navbar.png';

export default function Navbar() {
  const auth = useAuth();
  const closeMenuBtn = useRef();
  const [isDark, setIsDark] = useState(localStorage.getItem('darkSwitch'));

  const selectSearch = document.querySelector('.react-select-search__input');

  const resetTheme = ({ target }) => {
    if (target.checked) {
      document.body.setAttribute('data-theme', 'dark');
      localStorage.setItem('darkSwitch', 'dark');

      if (selectSearch) {
        selectSearch.style.color = '#fff';
      }

      setIsDark(true);
    } else {
      document.body.removeAttribute('data-theme');
      localStorage.removeItem('darkSwitch');

      if (selectSearch) {
        selectSearch.style.color = '#000';
      }

      setIsDark(false);
    }
  };

  const handleLogout = () => {
    auth.logout();
  };

  useEffect(() => {
    if (localStorage.getItem('darkSwitch')) {
      document.body.setAttribute('data-theme', 'dark');

      if (selectSearch) {
        selectSearch.style.color = '#fff';
      }
    } else {
      document.body.removeAttribute('data-theme');

      if (selectSearch) {
        selectSearch.style.color = '#000';
      }
    }

    //eslint-disable-next-line
  }, []);

  return (
    <nav className="nav-top navbar navbar-expand-lg navbar-light bg-light fixed-top border-bottom shadow">
      <div className="container-fluid d-flex justify-content-between">
        <div className="d-flex gap-2">
          <Link className="navbar-brand" to="/">
            {!isDark ? (
              <img src={logo} alt="Flex logo" width="50" />
            ) : (
              <img src={logo_dark} alt="Flex logo" width="50" />
            )}
          </Link>
          <h2 className="m-0 d-none d-md-block">
            {auth.user?.userData.bus_name}
          </h2>
        </div>

        <div className="form-check form-switch ms-auto me-3">
          <input
            className="form-check-input"
            type="checkbox"
            id="darkSwitch"
            defaultChecked={isDark ? true : false}
            onChange={resetTheme}
          />
          <label className="form-check-label" htmlFor="darkSwitch">
            Modo obscuro
          </label>
        </div>

        <div className="d-none d-lg-block">
          <UserCard handleLogout={handleLogout} />
        </div>

        <button
          ref={closeMenuBtn}
          className="d-lg-none btn lateral-menu-btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#MobileSideBar"
          aria-controls="MobileSideBar"
        >
          <i className="fas fa-bars fa-lg"></i>
        </button>

        <MobileSideBar
          handleLogout={handleLogout}
          btn={closeMenuBtn}
          busName={auth.user?.userData.bus_name}
        />
      </div>
    </nav>
  );
}
