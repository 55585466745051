import React, { useEffect, useRef, useState } from 'react';

import { POST, PUT } from 'helpers/request';
import { useForm } from 'hooks/useForm';
import { DefaultInput, DefaultTextarea } from 'templates/input';
import AlertMessage from 'components/layout/AlertMessage';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import { useRouteMatch } from 'react-router-dom';
import CRUDPageById from 'pages/CRUDPageById';
import ModuleFormPageById from 'pages/ModuleFormPageById';
import { Switch } from 'react-router-dom';

const defaultValues = {
  typ_text_name: '',
  typ_text_description: ''
};

export default function TypesForm({ module, id, data, title, handleGoBack }) {
  const [values, handleInputChange] = useForm(data || defaultValues);
  const [errorData, setError] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  const [tab, setTab] = useState(1);
  const tabOneRef = useRef();
  const tabTwoRef = useRef();
  const location = useLocation();
  const { path } = useRouteMatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(defaultValues);

    if (id === '0') {
      const data = await POST(values, module, setError);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
      setSuccess(data ? data.status : 500);
    }

    //prettier-ignore
    if (id !== "0") {
      const data = await PUT(defaultValues, values, module, id, setError);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setLoading(false)
      setSuccess(data ? data.status : 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(null);
    }, 3000);

    if (success === 201) {
      setTimeout(() => {
        history.goBack();
      }, 3000);
    }
  }, [success, history]);

  useEffect(() => {
    if (id !== '0') {
      if (location.pathname.includes('info')) {
        tabTwoRef.current.click();
      } else {
        tabOneRef.current.click();
      }
    }

    //eslint-disable-next-line
  }, []);

  const { typ_text_name, typ_text_description } = values;

  return (
    <container className="w-100 p-3 p-xl-5 d-flex flex-column mt-3">
      {id !== '0' ? (
        <h2 className="w-100 text-center mb-3 mb-lg-5">
          Editar tipo ( {title.replace(/-/g, ' ')} )
        </h2>
      ) : (
        <h2 className="w-100 text-center mb-3 mb-lg-5">Nuevo tipo</h2>
      )}

      <div className="d-flex justify-content-start align-items-center gap-3 pb-3">
        <button
          className="btn btn-primary me-auto btn-sm"
          onClick={handleGoBack}
          type="button"
        >
          <i className="fa fas fa-long-arrow-alt-left me-2"></i> Lista de tipos
        </button>

        {id !== '0' && (
          <Tabs tabOneRef={tabOneRef} tabTwoRef={tabTwoRef} setTab={setTab} />
        )}
      </div>
      <hr className="mb-5" />

      {success && <AlertMessage status={success} id={id} />}

      {tab === 1 && (
        <Form
          id={id}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          errorData={errorData}
          loading={loading}
          typ_text_name={typ_text_name}
          typ_text_description={typ_text_description}
        />
      )}

      {tab === 2 && (
        <Switch>
          {/* <PrivateRoute exact path={`${path}`} component={Form} /> */}
          <PrivateRoute
            exact
            path={`${path}/info/:module`}
            component={CRUDPageById}
          />
          <PrivateRoute
            exact
            path={`${path}/info/:module/:itemId`}
            component={ModuleFormPageById}
          />
        </Switch>
      )}
    </container>
  );
}

const Form = ({
  id,
  handleSubmit,
  handleInputChange,
  errorData,
  loading,
  typ_text_name,
  typ_text_description
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="w-100 d-flex flex-column"
      autoComplete="off"
    >
      <DefaultInput
        label="Nombre"
        type="text"
        value={typ_text_name}
        inputName="typ_text_name"
        onChange={handleInputChange}
        placeholder="Ingresa un nombre"
        error={errorData?.typ_text_name}
      />

      <DefaultTextarea
        label="Descripción"
        value={typ_text_description}
        inputName="typ_text_description"
        onChange={handleInputChange}
        placeholder="Ingresa una descripción"
        required={false}
        error={errorData?.typ_text_description}
      />

      <button
        type="submit"
        className="btn btn-primary mt-4"
        disabled={loading ? true : false}
      >
        {loading ? (
          <>
            <i className="fas fa-circle-notch fa-spin me-2"></i> Cargando
          </>
        ) : id !== '0' ? (
          'Editar'
        ) : (
          'Crear'
        )}
      </button>
    </form>
  );
};

const Tabs = ({ tabOneRef, tabTwoRef, setTab }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const handleInfo = () => {
    setTab(1);
    history.replace(`${url}`);
  };

  const handleFormats = () => {
    setTab(2);
    history.push(`${url}/info/formatos`);
  };

  //prettier-ignore
  return (
    <div className="btn-group mb-0 flex-shrink-1 mb-3 mb-md-0" role="group" aria-label="Selección de secciones">
      <input onClick={handleInfo} type="radio" className="btn-check" name="btnradio" id="tab-1" autoComplete="off" value='1' ref={tabOneRef} />
      <label className="btn btn-outline-secondary btn-sm" htmlFor="tab-1"><i className="fas fa-file me-lg-2"></i> Información</label>
      <input onClick={handleFormats} type="radio" className="btn-check" name="btnradio" id="tab-2" autoComplete="off" value='2' ref={tabTwoRef} />
      <label className="btn btn-outline-secondary btn-sm" htmlFor="tab-2"><i className="fas fa-list me-lg-2"></i> Formatos</label>
    </div>
  )
};
