import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { POST } from 'helpers/request';
import Autocomplete from 'components/select-search/Autocomplete';

export default function ReportValids() {
  const [data, setData] = useState(null);
  const [parentId, setParentId] = useState('');
  const [parentProviderId, setParentProviderId] = useState('');

  const history = useHistory();
  const situDocBussRoute = '/situacionDocumental/';

  const handleClick = async (id) => {
    if (id === '0') return;

    const res = await POST(
      { cual: '6', bus_int_pid: id },
      'reports',
      null,
      null
    );

    setData(res[0]);
  };

  const handleBusinessClick = (id) => {
    history.push(`${situDocBussRoute}${id}/0/0`);
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">
        Reporte de empresas con documentos Inexistentes
      </h2>

      <div className="row mx-0 w-100">
        <div className={`col-12 col-lg-4`}>
          <Autocomplete
            label="Selecciona una empresa"
            classes="w-100 pb-3"
            url="business_admin"
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              handleClick(item.value);
              setParentProviderId('');
              setParentId('');

              setTimeout(() => {
                setParentId(item.value);
              }, 100);
            }}
          />
        </div>
        {parentId && parentId !== '0' && (
          <div className={`col-12 col-lg-4`}>
            <Autocomplete
              label="Selecciona un proveedor"
              classes="w-100 pb-3"
              url="business_admin"
              customParams={[`c=${parentId}`]}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="bus_int_id"
              nameField="bus_text_name"
              placeholder="Escoja una opción"
              onChange={(item) => {
                handleClick(item.value);
                setParentProviderId('');

                setTimeout(() => {
                  setParentProviderId(item.value);
                }, 100);
              }}
            />
          </div>
        )}
        {parentProviderId && parentProviderId !== '0' && (
          <div className={`col-12 col-lg-4`}>
            <Autocomplete
              label="Selecciona un contratista"
              classes="w-100 pb-3"
              url="business_admin"
              customParams={[`c=${parentProviderId}`]}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="bus_int_id"
              nameField="bus_text_name"
              placeholder="Escoja una opción"
              onChange={(item) => handleClick(item.value)}
            />
          </div>
        )}
      </div>

      <Headers />

      {data?.valor.length > 0 &&
        data.valor.map((item) => (
          <Item
            key={item.bus_int_id}
            id={item.bus_int_id}
            name={item.bus_text_name}
            number={item.cantvalidos}
            handleBusinessClick={handleBusinessClick}
          />
        ))}

      {!data && (
        <div className="alert alert-secondary text-center py-3 border">
          Seleccione una empresa
        </div>
      )}

      {data?.valor.length === 0 && (
        <div className="alert alert-warning text-center py-3 border">
          No hay datos
        </div>
      )}
    </FullWidthContainer>
  );
}

const Item = ({ id, name, number, handleBusinessClick }) => {
  return (
    <div className="row mx-0 w-100 border rounded">
      <span className="col-2 text-center border-end py-3">{id}</span>
      <span
        className="sit-doc-item-row col-10 text-start word-break border-end py-3"
        style={{ cursor: 'pointer' }}
        onClick={() => handleBusinessClick(id)}
      >
        {name}
      </span>
    </div>
  );
};
const Headers = () => {
  return (
    <div className="row mx-0 w-100 border rounded bg-secondary mb-4">
      <span className="col-2 text-center text-white fw-bold border-end py-2">
        Id
      </span>
      <span className="col-10 text-center text-white fw-bold border-end py-2">
        Empresa
      </span>
    </div>
  );
};
