export default function InputText({
  className,
  label,
  placeholder,
  id,
  value,
  name,
  onChange,
  onClick,
  isInvalid,
  type = 'text',
  required = true,
  disabled = false
}) {
  return (
    <div className={className}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      <input
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
        className={`form-control input-bg-dark ${
          isInvalid ? 'is-invalid' : ''
        }`}
        id={id}
        required={required}
        autoComplete="off"
        disabled={disabled}
        onClick={onClick}
      />
      {isInvalid && (
        <div id={`${id}Feedback`} className="invalid-feedback">
          {isInvalid}
        </div>
      )}
    </div>
  );
}
