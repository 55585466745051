import React, { useCallback, useState } from 'react';

import { PDF_DWNLD_GET, PDF_GET } from 'helpers/request';

import PdfViewerModal from 'components/pdf-viewer-modal/PdfViewerModal';
import AccepDenyModal from './Accep_Deny_Modal';

import 'styles/RecordRow.css';
import DeleteModal from './DeleteModal';

export default function RecordRow({
  item,
  auditor,
  setHavePendingDocs,
  setRegistros,
  categoryExpiration,
  categoryName,
  categoryDesc
}) {
  const [record, setRecord] = useState(item);
  const isPdf =
    item.rec_text_type !== ''
      ? item.rec_text_type.split('/')[1].includes('pdf')
      : '';
  const isImg =
    item.rec_text_type !== ''
      ? item.rec_text_type.split('/')[1].includes('png') ||
        item.rec_text_type.split('/')[1].includes('jpeg')
      : '';
  const [pdf, setPdf] = useState(null);
  const [img, setImg] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);

  const getPdf = useCallback(async () => {
    if (isPdf) {
      setLoadingFile(true);
      const data = await PDF_GET('records_lectura', item.rec_int_id);
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      setPdf(url);
      setLoadingFile(false);
    }

    if (isImg) {
      setLoadingFile(true);
      const data = await PDF_GET('records_lectura', item.rec_int_id);
      const img = new Blob([data], {
        type: `ìmage/${item.rec_text_type.split('/')[1]}`
      });

      const imgObjUrl = URL.createObjectURL(img);

      setImg(imgObjUrl);
      setLoadingFile(false);
    }

    //eslint-disable-next-line
  }, [item.rec_int_id]);

  const handleForceDownload = async () => {
    await PDF_DWNLD_GET('records_lectura', item.rec_text_name, item.rec_int_id);
  };

  const { rec_int_id, rec_text_name, rec_int_status } = record;

  return (
    <div className="w-100 bg-light rounded border mb-2 row mx-0 align-items-center">
      <PdfViewerModal
        id={rec_int_id}
        pdf={pdf}
        img={img}
        loadingFile={loadingFile}
        info={{
          name: item.rec_text_name,
          expiration: item.rec_date_expiration_date,
          observations: item.rec_text_obs,
          categoryName: categoryName,
          categoryDesc: categoryDesc
        }}
        recordId={item.rec_int_id}
      />
      <AccepDenyModal
        id={rec_int_id}
        setRecord={setRecord}
        recObs={item.rec_text_validar}
        auditor={auditor}
        created={record.rec_date_creation_date}
        expiration={record.rec_date_expiration_date}
        categoryExpiration={categoryExpiration}
        fileName={item.rec_text_name}
        setHavePendingDocs={setHavePendingDocs}
        rec_creado={item.rec_creado}
      />
      <DeleteModal
        recordId={item.rec_int_id}
        recordName={item.rec_text_name}
        setRegistros={setRegistros}
      />

      <div className="col-8 col-md-6 py-2 border-end">
        <p className="mb-0 w-100 text-truncate">
          {isPdf && <i className="far fa-file-pdf me-2"></i>}
          <span
            className={
              item.rec_date_deletion_date && 'text-decoration-line-through'
            }
            title={item.rec_int_id}
          >
            {rec_text_name}
          </span>
          {item.rec_date_deletion_date && (
            <span className="text-decoration-none ms-2 text-danger">
              (Eliminado)
            </span>
          )}
        </p>
      </div>

      <p
        className="d-none d-md-block col-md-2 text-truncate text-center mb-0 py-2 border-end"
        title={item.rec_creado}
      >
        <i className="far fa-calendar-alt me-2"></i>
        {record.rec_creado}
      </p>

      <p className="d-none d-md-block col-md-2 text-truncate text-center mb-0 py-2 border-end">
        <i className="far fa-calendar-alt me-2"></i>
        {categoryExpiration === '0'
          ? 'No expira'
          : record.rec_date_expiration_date}
      </p>

      <div className="col-4 col-md-2 d-flex align-items-center gap-2 justify-content-end py-2">
        {!item.rec_date_deletion_date && (
          <>
            <button
              onClick={getPdf}
              type="button"
              className="btn btn-primary btn-sm rec-row-btn-read"
              data-bs-toggle="modal"
              data-bs-target={'#pdf-viewer-rec' + rec_int_id}
            >
              <i className="far fa-eye"></i>
            </button>

            <button
              onClick={handleForceDownload}
              type="button"
              className="btn btn-primary btn-sm"
            >
              <i className="fas fa-download"></i>
            </button>

            <button
              type="button"
              className={`btn btn-sm ${
                (rec_int_status === '0' && 'btn-success') ||
                (rec_int_status === '1' && 'btn-warning') ||
                (rec_int_status === '2' && 'btn-danger') ||
                (rec_int_status === '3' && 'btn-secondary')
              }`}
              data-bs-toggle="modal"
              data-bs-target={'#accep_deny_modal' + rec_int_id}
              title={
                (rec_int_status === '0' && 'Aprobado') ||
                (rec_int_status === '1' && 'Pendiente') ||
                (rec_int_status === '2' && 'No válido') ||
                (rec_int_status === '3' && 'Obsoleto')
              }
            >
              {rec_int_status === '0' && (
                <i className="far fa-check-circle fa-lg"></i>
              )}
              {rec_int_status === '1' && (
                <i className="far fa-question-circle fa-md fa-lg"></i>
              )}
              {rec_int_status === '2' && (
                <i className="far fa-times-circle fa-lg"></i>
              )}
              {rec_int_status === '3' && (
                <i className="far fa-folder-open fa-lg"></i>
              )}
            </button>

            <button
              disabled={item.rec_date_deletion_date ? true : false}
              data-bs-toggle="modal"
              data-bs-target={`#deleteRecModal-${item.rec_int_id}`}
              type="button"
              className={`btn btn-${
                item.rec_date_deletion_date ? 'secondary' : 'danger'
              } btn-sm`}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </>
        )}
      </div>
    </div>
  );
}
