import React, { useState } from 'react';

import FullWidthContainer from 'components/layout/FullWidthContainer';
import { POST } from 'helpers/request';
import Autocomplete from 'components/select-search/Autocomplete';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

const BASE_URL = process.env.REACT_APP_ADMINISTRATION_BASE_URL;

export default function Reporte20() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [busId, setBusId] = useState('');
  const [formatId, setFormatId] = useState('');
  const [index, setIndex] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [typeAndValue, setTypeAndValue] = useState({
    type: '',
    value: ''
  });

  const estadotxt = ['Vigente', 'Pendiente', 'Inválido', 'Obsoleto','Caducado','Inexistente'];

  const getData = async (formatId, tipo) => {
    setLoading(true);
    setData([]);
    setFormatId(formatId);

    const res = await POST(
      {
        cual: '20',
        bus_int_pid: busId,
        for_int_id: formatId,
        xls: '0',
        l: 20,
        o: 0,
        t: tipo
      },
      // { cual: '10', bus_int_id: '353', typ_int_id: '21', xls: '0' },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    if (res[0].valor) {
      setData(res[0].valor);
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const handleScroll = async () => {
    const res = await POST(
      {
        cual: '20',
        bus_int_pid: busId,
        for_int_id: formatId,
        xls: '0',
        l: 20,
        o: index,
        t: typeAndValue.type
      },
      // { cual: '10', bus_int_id: '353', typ_int_id: '21', xls: '0' },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      return;
    }

    if (res[0].valor) {
      setData([...data, ...res[0].valor]);
      setIndex(index + 20);

      if (res[0].valor.length < 20) {
        setHasMore(false);
      }

      return;
    }

    setData([]);
  };

  const handleDescargar = async () => {
    const URL = `${BASE_URL}/reports`;

    const config = {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    const formData = new FormData();
    formData.append('cual', 20);
    formData.append('bus_int_pid', busId);
    formData.append('for_int_id', formatId);
    formData.append('xls', 1);
    formData.append('t', typeAndValue.type);

    try {
      const res = await axios.post(URL, formData, config);
      console.info(res);
      const href = window.URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute(
        'download',
        `documentos-de-empresa-por-trabajador-equipo-o-empresa${busId}-${formatId}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">
        Documentos por trabajador, equipo o empresa
      </h2>

      <div style={{ position: 'sticky', top: '20px' }}>
        <div className="w-100 mb-3 d-flex flex-wrap gap-3 align-items-end">
          <Autocomplete
            label="Selecciona una empresa"
            classes="flex-grow-1"
            url="business_admin"
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              setBusId('');
              setData(null);
              setFormatId('');

              setTimeout(() => {
                setBusId(item.value);
              }, 100);
            }}
          />

          {busId && (
            <>
              <Autocomplete
                label="Formatos trabajadores"
                classes="flex-grow-1"
                url={`datos/tlf/${busId}`}
                customParams={['t=3']}
                //son los nombres de los campos de el esquema, de id y de nombre
                valueField="for_int_id"
                nameField="for_text_name"
                placeholder="Escoja un formato"
                clearValue={typeAndValue.type !== '3' ? true : null}
                onChange={(item) => {
                  setTypeAndValue({
                    type: '3',
                    value: item.value
                  });
                  getData(item.value, '3');
                }}
              />
              <Autocomplete
                label="Formatos equipos"
                classes="flex-grow-1"
                url={`datos/tlf/${busId}`}
                customParams={['t=4']}
                //son los nombres de los campos de el esquema, de id y de nombre
                valueField="for_int_id"
                nameField="for_text_name"
                placeholder="Escoja un formato"
                clearValue={typeAndValue.type !== '4' ? true : null}
                onChange={(item) => {
                  setTypeAndValue({
                    type: '4',
                    value: item.value
                  });
                  getData(item.value, '4');
                }}
              />
              <Autocomplete
                label="Formatos empresa"
                classes="flex-grow-1"
                url={`datos/tlf/${busId}`}
                customParams={['t=2']}
                //son los nombres de los campos de el esquema, de id y de nombre
                valueField="for_int_id"
                nameField="for_text_name"
                placeholder="Escoja un formato"
                clearValue={typeAndValue.type !== '2' ? true : null}
                onChange={(item) => {
                  setTypeAndValue({
                    type: '2',
                    value: item.value
                  });
                  getData(item.value, '2');
                }}
              />
            </>
          )}

          {busId && data?.length > 0 && (
            <button
              onClick={handleDescargar}
              type="button"
              className="btn btn-primary"
              title="Descargar"
            >
              <i className="fas fa-download me-2"></i> Descargar
            </button>
          )}
        </div>
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading && data && data.length > 0 && (
        <>
          <div className="row m-0 border hover border fw-bold text-white bg-secondary text-center pe-3">
            <span className="col-2 border-end text-wrap">Proveedor</span>
            <span className="col-2 border-end text-wrap">Documento</span>
            <span
              className={`col-${
                typeAndValue.type !== '2' ? '1' : '3'
              } border-end text-wrap`}
            >
              Creado
            </span>
            <span
              className={`col-${
                typeAndValue.type !== '2' ? '1' : '3'
              } border-end text-wrap`}
            >
              Expira
            </span>
            <span className="col-2 border-end text-wrap">Estado</span>
            {typeAndValue.type !== '2' ? (
              <>
                <span className="col-2 border-end text-wrap">
                  {typeAndValue.type === '3' ? 'Trabajador' : 'Equipo'}
                </span>
                <span className="col-2 text-wrap">Identificación</span>
              </>
            ) : null}
          </div>

          <InfiniteScroll
            className="w-100"
            dataLength={data.length}
            next={handleScroll}
            // scrollThreshold={0.9}
            hasMore={hasMore}
            loader={
              <p className="text-center w-100 py-3 mb-0 border">Cargando...</p>
            }
            height={500}
            endMessage={
              <p className="text-center w-100 mb-0 py-3 border">
                No hay más datos
              </p>
            }
          >
            {data.map((item, index) => (
              <div
                className="row m-0 border hover border-top-0 text-center py-sm-2"
                key={index}
              >
                <span className="col-2 border-end"> {item.proveedor} </span>
                <span className="col-2 border-end"> {item.documento} </span>
                <span
                  className={`col-${
                    typeAndValue.type !== '2' ? '1' : '3'
                  } border-end`}
                >
                  {item.creado === null ? '-' : item.creado}
                </span>
                <span
                  className={`col-${
                    typeAndValue.type !== '2' ? '1' : '3'
                  } border-end`}
                >
                  {item.expira === null ? '-' : item.expira}
                </span>
                <span className="col-2 border-end">
                  {item.estado === null
                    ? 'Sin documento'
                    : estadotxt[item.estado]}
                </span>
                {typeAndValue.type !== '2' ? (
                  <>
                    <span className="col-2 border-end">
                      {' '}
                      {item.trabajador}{' '}
                    </span>
                    <span className="col-2"> {item.identificacion} </span>
                  </>
                ) : null}
              </div>
            ))}
          </InfiniteScroll>
        </>
      )}
    </FullWidthContainer>
  );
}
