import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

export default function TabsButtons({
  tab,
  handleTab,
  id,
  title,
  setTab,
  busIntPid
}) {
  const tabOneRef = useRef();
  const history = useHistory();
  const { url } = useRouteMatch();
  const location = useLocation();

  const handleClearUrl = ({ target }) => {
    history.replace(`${url}`);
    setTab(Number(target.value));
  };

  const handleSituacionDocumental = () => {
    history.replace(`/situacionDocumental/${id}/0/0`);
  };

  const handleContacts = () => {
    setTab(4);
    history.replace(`${url}/info/contacts`);
  };

  const handleTrabajadores = () => {
    setTab(4);
    history.replace(`${url}/info/trabajadores`);
  };

  const handleEquipos = () => {
    setTab(4);
    history.replace(`${url}/info/equipos`);
  };

  const handleUsers = () => {
    setTab(4);
    history.replace(`${url}/info/users_business`);
  };

  const handleProviders = () => {
    setTab(4);
    history.push(`${url}/info/proveedores`);
  };

  useEffect(() => {
    if (location.pathname.includes('info')) {
      const module =
        location.pathname.split('/')[location.pathname.split('/').length - 1];
      document.getElementById(`bus-menu-${module}-btn`)?.click();
    } else {
      tabOneRef.current.click();
    }

    //eslint-disable-next-line
  }, []);

  //prettier-ignore
  return (
    <div className="btn-group mb-0 flex-grow-1 mb-3 mb-md-0" role="group" aria-label="Selección de secciones">
      <input onClick={handleClearUrl} type="radio" className="btn-check" name="btnradio" id="tab-1" autoComplete="off" value='1' ref={tabOneRef} />
      <label className="btn btn-outline-secondary btn-sm" htmlFor="tab-1"><i className="fas fa-info me-lg-2"></i> Información</label>

      <input onClick={handleClearUrl} type="radio" className="btn-check" name="btnradio" id="tab-3" autoComplete="off" checked={tab === 3 ? true : false} value='3' />
      <label className="btn btn-outline-secondary btn-sm" htmlFor="tab-3"><i className="fas fa-list me-lg-2"></i> Requisitos</label>

      <input onClick={handleSituacionDocumental} type="radio" className="btn-check" name="btnradio" id="tab-4" autoComplete="off" value='4' />
      <label className="btn btn-outline-secondary btn-sm" htmlFor="tab-4"><i className="fas fa-book me-lg-2"></i> Situación documental</label>

      <input onClick={handleContacts} type="radio" className="btn-check" name="btnradio" autoComplete="off" value='2' id='bus-menu-contactos-btn'/>
      <label className="btn btn-outline-secondary btn-sm" htmlFor="bus-menu-contactos-btn"><i className="fas fa-address-book me-lg-2"></i> Contactos</label>

      {busIntPid !== '0' &&
        <> 
          <input onClick={handleTrabajadores} type="radio" className="btn-check" name="btnradio" autoComplete="off" value='5' id='bus-menu-trabajadores-btn'/>
          <label className="btn btn-outline-secondary btn-sm" htmlFor="bus-menu-trabajadores-btn"><i className="fas fa-users me-lg-2"></i> Trabajadores</label>

          <input onClick={handleEquipos} type="radio" className="btn-check" name="btnradio" autoComplete="off" value='6' id='bus-menu-equipos-btn'/>
          <label className="btn btn-outline-secondary btn-sm" htmlFor="bus-menu-equipos-btn"><i className="fas fa-briefcase me-lg-2"></i> Equipos</label>
        </>
      }

      <input onClick={handleUsers} type="radio" className="btn-check" name="btnradio" autoComplete="off" value='7' id='bus-menu-usuarios-btn'/>
      <label className="btn btn-outline-secondary btn-sm" htmlFor="bus-menu-usuarios-btn"><i className="fas fa-users me-lg-2"></i> Usuarios</label>

      <input onClick={handleProviders} type="radio" className="btn-check" name="btnradio" autoComplete="off" value='8' id='bus-menu-proveedores-btn'/>
      <label className="btn btn-outline-secondary btn-sm" htmlFor="bus-menu-proveedores-btn"><i className="fas fa-truck-moving me-lg-2"></i> Proveedores</label>
    </div>
  )
}
