import React from "react";
import { DefaultInput } from "templates/input";

export default function ClasificacionForm({
  id,
  title,
  handleSubmit,
  handleChange,
  setData,
  loading,
  error,
  busId,

  fcl_text_name,
}) {
  return (
    <div className="row mx-0 w-100">
      <h3 className="text-center mb-3 col-12">
        {id !== "0" ? "Editar clasificación" : "Nueva clasificación"} ({" "}
        {title.replace(/-/g, " ")} )
      </h3>

      <DefaultInput
        customClass="col-12"
        label="Nombre"
        placeholder="e.j. Calidad"
        value={fcl_text_name}
        inputName="fcl_text_name"
        onChange={handleChange}
        error={error.fcl_text_name}
      />

      <div className="col-12 d-flex justify-content-end">
        <button
          type="button"
          onClick={handleSubmit}
          className={`btn btn-${loading ? "secondary" : "primary"}`}
          disabled={loading ? true : false}
        >
          {loading ? (
            <>
              <i className="fas fa-circle-notch fa-spin me-2"></i>
              Guardando...
            </>
          ) : (
            <>
              <i className="fas fa-save me-2"></i>
              Guardar
            </>
          )}
        </button>
      </div>
    </div>
  );
}
