export default function ListItem({
  type,
  data,
  edit,
  assigning,
  setTypeObj,
  getFormats,
  woh
}) {
  const estado = data.out_estado;

  const colorTable = {
    null: 'secondary',
    false: 'secondary',
    true: 'success',
    0: 'warning'
  };

  const iconTable = {
    0: <i className="fas fa-exclamation px-1"></i>,
    null: <i className="fas fa-check"></i>,
    false: <i className="fas fa-check"></i>,
    true: <i className="fas fa-check"></i>
  };

  const titleTable = {
    0: 'Sin formatos asignables',
    null: 'Asignar',
    false: 'Asignar',
    true: 'Desactivar'
  };

  return (
    <div className="row w-100 mx-0 px-0 py-2 align-items-center border">
      <p className="col-2 mb-0 text-truncate">{data.out_type_int_id}</p>
      <p className="col-6 mb-0">{data.out_typ_text_name}</p>
      <div className="col-4 d-flex align-items-center justify-content-center gap-2">
        <button
          onClick={() => edit(data.out_type_int_id, data.out_estado)}
          disabled={assigning ? true : false}
          className={`btn px-2 py-1 btn-${
            typeof estado === 'string' ? 'success' : colorTable[estado]
          } btn-sm`}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={titleTable[estado]}
        >
          {typeof estado === 'string' ? iconTable['true'] : iconTable[estado]}
        </button>

        <button
          onClick={() => {
            setTypeObj(data);
            getFormats(data.out_type_int_id, woh);
          }}
          className="btn btn-sm btn-warning"
          title="Formatos"
        >
          <i className="fas fa-list"></i>
        </button>
      </div>
    </div>
  );
}
