import FullWidthContainer from 'components/layout/FullWidthContainer';
import Autocomplete from 'components/select-search/Autocomplete';
import { DWNLD_POST_V2, POST } from 'helpers/request';
import { useState } from 'react';
import { format } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import InfiniteScroll from 'react-infinite-scroll-component';

const get_date_less_90 = () => {
  const date = new Date();
  date.setDate(date.getDate() - 390);
  return date;
};

export default function Reporte21() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [formatId, setFormatId] = useState(null);
  const [desde, setDesde] = useState(get_date_less_90());
  const [hasta, setHasta] = useState(new Date());
  const [businessId, setBusinessId] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(20);

  const getData = async () => {
    setLoading(true);
    setData([]);

    const res = await POST(
      {
        cual: '21',
        bus_int_pid: businessId,
        for_int_id: formatId,
        desde: format(desde, 'yyyy-MM-dd'),
        hasta: format(hasta, 'yyyy-MM-dd'),
        l: 20,
        o: 0,
        xls: '0'
      },
      // { cual: '21', bus_int_pid: 280, for_int_id: 906, xls: '0' },
      'reports',
      null,
      null
    );

    // console.log({ res });

    if (res.status && res.status === 402) {
      setData([]);
      setLoading(false);
      return;
    }

    if (res[0].valor) {
      setData(res[0].valor);
      setBusiness({ ...res.cab, id: businessId });
      setLoading(false);
      return;
    }

    setData([]);

    setLoading(false);
  };

  const handleScroll = async () => {
    const res = await POST(
      {
        cual: '21',
        bus_int_pid: businessId,
        for_int_id: formatId,
        desde: format(desde, 'yyyy-MM-dd'),
        hasta: format(hasta, 'yyyy-MM-dd'),
        xls: '0',
        l: 20,
        o: index
      },
      'reports',
      null,
      null
    );

    if (res.status && res.status === 402) {
      setData([]);
      return;
    }

    if (res[0].valor) {
      setData([...data, ...res[0].valor]);
      setIndex(index + 20);

      if (res[0].valor.length < 20) {
        setHasMore(false);
      }

      return;
    }

    setData([]);
  };

  const handleDescargar = () => {
    DWNLD_POST_V2(
      'reports',
      `reporte-historico-trabajador-${business.Empresa}-${business.Fecha}.xlsx`,
      21,
      {
        bus_int_pid: business.id,
        for_int_id: formatId,
        desde: format(desde, 'yyyy-MM-dd'),
        hasta: format(hasta, 'yyyy-MM-dd'),
        xls: 1
      }
    );
  };

  return (
    <FullWidthContainer>
      <h2 className="text-center mb-5">
        Documentos historicos de trabajador por empresa
      </h2>

      <div className="d-flex align-items-end gap-3 mb-3">
        <div className="w-100 d-flex gap-3 align-items-end">
          <Autocomplete
            label="Selecciona una empresa"
            classes="flex-grow-1"
            url="business_admin"
            //son los nombres de los campos de el esquema, de id y de nombre
            valueField="bus_int_id"
            nameField="bus_text_name"
            placeholder="Escoja una opción"
            onChange={(item) => {
              setBusinessId('');
              setFormatId('');
              setData(null);

              setTimeout(() => {
                setBusinessId(item.value);
              }, 100);

              // getData(item.value);
            }}
          />

          {businessId && (
            <Autocomplete
              label="Selecciona un formato"
              classes="flex-grow-1"
              url={`datos/tlf/${businessId}`}
              customParams={[`t=3`]}
              //son los nombres de los campos de el esquema, de id y de nombre
              valueField="for_int_id"
              nameField="for_text_name"
              placeholder="Escoja una opción"
              onChange={(item) => {
                setFormatId(item.value);
              }}
            />
          )}

          {formatId && (
            <>
              <div className="d-flex flex-column flex-grow-1">
                <span className="form-label">Desde</span>
                <ReactDatePicker
                  selected={desde}
                  onChange={(date) => setDesde(date)}
                />
              </div>
              <div className="d-flex flex-column flex-grow-1">
                <span className="form-label">Hasta</span>
                <ReactDatePicker
                  selected={hasta}
                  onChange={(date) => setHasta(date)}
                />
              </div>
            </>
          )}

          {formatId && (
            <button onClick={getData} className="btn btn-primary">
              Buscar
            </button>
          )}
        </div>

        {!loading && data && data.length > 0 && (
          <button
            onClick={handleDescargar}
            type="button"
            className="btn btn-success d-flex align-items-center"
          >
            <i className="fas fa-download me-2"></i>Excel
          </button>
        )}
      </div>

      {loading && (
        <div className="text-center boder py-3 mt-3">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && data && data.length === 0 && (
        <div className="fw-bold text-uppercase text-center boder py-3 mt-3">
          No hay datos
        </div>
      )}

      {!loading && data && data.length > 0 && (
        <>
          <div className="row m-0 border hover fw-bold text-white bg-secondary text-center pe-3">
            <span className="col-2 border-end text-wrap">Creado</span>
            <span className="col-2 border-end text-wrap">Expira</span>
            <span className="col-6 border-end text-wrap">Nombre</span>
            <span className="col-2 text-wrap">Estado</span>
          </div>

          <InfiniteScroll
            className="w-100"
            dataLength={data.length}
            next={handleScroll}
            // scrollThreshold={0.9}
            hasMore={hasMore}
            loader={
              <p className="text-center w-100 py-3 mb-0 border">Cargando...</p>
            }
            height={500}
            endMessage={
              <p className="text-center w-100 mb-0 py-3 border">
                No hay más datos
              </p>
            }
          >
            {data.map((item, index) => (
              <div
                className="row m-0 border hover border-top-0 text-center py-sm-2"
                key={index}
              >
                <span className="col-2 border-end">
                  {' '}
                  {item.rec_date_creation_date.split(' ')[0]}{' '}
                </span>
                <span className="col-2 border-end">
                  {' '}
                  {item.rec_date_expiration_date}{' '}
                </span>
                <span className="col-6 border-end"> {item.rec_text_name} </span>
                <span className="col-2 border-end"> {item.estado} </span>
              </div>
            ))}
          </InfiniteScroll>
        </>
      )}
    </FullWidthContainer>
  );
}
