import { useState } from 'react';
import useRelations from './useRelations';

import ListItem from './ListItem';
import RelationModal from './RelationModal';

import '../../styles/CRUDPage.css';
import useRequest from 'hooks/useRequest';

export default function RelationList({ columns, id, type, busId }) {
  const { reqToken } = useRequest();
  const wohTable = {
    trabajador: '3',
    equipo: '4'
  };

  const { loading, data, assigning, handleEdit, handleFilter } = useRelations(
    id,
    type,
    busId
  );

  const [typeObj, setTypeObj] = useState(null);
  const [loadingFormats, setLoadingFormats] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formats, setFormats] = useState({
    original: [],
    data: []
  });

  const getFormats = async (typeId, woh) => {
    setLoadingFormats(true);
    const res = await reqToken.get(`formatsxwoh/${typeId}/${id}/${woh}`);
    setFormats({
      original: res.data.valores,
      data: res.data.valores
    });
    setLoadingFormats(false);
  };

  const handleFilterFormats = (value) => {
    setFormats({
      original: formats.original,
      data: formats.original.filter((item) =>
        item.for_text_name.toLowerCase().includes(value.toLowerCase())
      )
    });
  };

  const handleAssign = async (paramId, que) => {
    setEdit(true);

    if (que === 't') {
      await reqToken.delete(
        `formatsxwoh/${paramId}/${typeObj.out_type_int_id}/${id}/${wohTable[type]}`
      );

      setFormats({
        original: formats.original.map((item) =>
          item.for_int_id === paramId ? { ...item, que: 'f' } : item
        ),
        data: formats.data.map((item) =>
          item.for_int_id === paramId ? { ...item, que: 'f' } : item
        )
      });

      setEdit(false);
    }

    if (que === 'f') {
      await reqToken.post(`formatsxwoh`, {
        typ_int_id: typeObj.out_type_int_id,
        bus_int_id: busId,
        woh_int_id: id,
        for_int_tipo: wohTable[type],
        for_int_id: paramId
      });

      setFormats({
        original: formats.original.map((item) =>
          item.for_int_id === paramId ? { ...item, que: 't' } : item
        ),
        data: formats.data.map((item) =>
          item.for_int_id === paramId ? { ...item, que: 't' } : item
        )
      });

      setEdit(false);
    }
  };

  if (typeObj) {
    return (
      <FormatsList
        typeObj={typeObj}
        setTypeObj={setTypeObj}
        columns={columns}
        formats={formats}
        loadingFormats={loadingFormats}
        edit={edit}
        handleFilterFormats={handleFilterFormats}
        handleAssign={handleAssign}
        handleFilter={handleFilter}
      />
    );
  }

  return (
    <div className={`col-12 col-md-${columns}`}>
      <div className="bg-secondary text-white d-flex align-items-center justify-content-center rounded py-2">
        <span className="fw-bold">Tipos de requisitos</span>
      </div>

      <div className="row w-100 mx-0 px-0 bg-secondary mt-2 mb-1 rounded">
        <div className="col-2 fw-bold text-truncate text-white py-2 border-end">
          ID
        </div>
        <div className="col-6 fw-bold text-truncate text-white py-2 border-end">
          Nombre
        </div>
        <div className="col-4 fw-bold text-truncate text-white py-2 text-center">
          Asignar
        </div>
      </div>

      <RelationModal />

      {!loading && (
        <div className="w-100">
          <input
            onChange={({ target }) => handleFilter(target.value)}
            type="text"
            className="form-control input-bg-dark rounded-0"
            placeholder="Nombre..."
          />
        </div>
      )}

      {loading && (
        <div className="pt-5 pb-2 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      )}

      {!loading && (
        <div className="items-list-container">
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <ListItem
                key={`rel_list_item_${index}`}
                type={type}
                data={item}
                edit={handleEdit}
                assigning={assigning}
                setTypeObj={setTypeObj}
                getFormats={getFormats}
                woh={wohTable[type]}
              />
            ))
          ) : (
            <div className="alert alert-warning text-center border p-3 mb-0">
              No hay datos
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const FormatsList = ({
  typeObj,
  setTypeObj,
  columns,
  formats,
  loadingFormats,
  edit,
  handleFilterFormats,
  handleAssign,
  handleFilter
}) => {
  return (
    <div className={`col-12 col-md-${columns}`}>
      <div className="d-flex align-items-center gap-2">
        <button
          onClick={() => {
            setTypeObj(null);
            handleFilter('');
          }}
          type="button"
          className="btn btn-secondary"
        >
          <i className="fas fa-caret-left me-2"></i>Volver
        </button>
        <div className="flex-grow-1 bg-secondary text-white d-flex align-items-center justify-content-center rounded p-2">
          <span className="fw-bold w-100 text-center">
            Requisitos de {typeObj.out_typ_text_name}
          </span>
        </div>
      </div>

      <div className="row w-100 mx-0 px-0 bg-secondary mt-2 mb-1 rounded">
        <div className="col-2 fw-bold text-truncate text-white py-2 border-end">
          ID
        </div>
        <div className="col-6 fw-bold text-truncate text-white py-2 border-end">
          Nombre
        </div>
        <div className="col-4 fw-bold text-truncate text-white py-2 text-center">
          Asignar
        </div>
      </div>

      <RelationModal />

      {!loadingFormats && (
        <div className="w-100">
          <input
            onChange={({ target }) => handleFilterFormats(target.value)}
            type="text"
            className="form-control input-bg-dark rounded-0"
            placeholder="Nombre..."
          />
        </div>
      )}

      {loadingFormats && (
        <div className="pt-5 pb-2 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Cargando...</span>
          </div>
        </div>
      )}

      {!loadingFormats && (
        <div className="items-list-container">
          {formats.data && formats.data.length > 0 ? (
            formats.data.map((item) => (
              <div
                key={item.for_int_id}
                className="row w-100 mx-0 px-0 py-2 align-items-center border"
              >
                <p className="col-2 mb-0 text-truncate">{item.for_int_id}</p>
                <p className="col-6 mb-0">{item.for_text_name}</p>
                <div className="col-4 d-flex align-items-center justify-content-center gap-2">
                  <button
                    onClick={() => handleAssign(item.for_int_id, item.que)}
                    disabled={edit}
                    className={`btn px-2 py-1 btn-${
                      item.que === 't' ? 'success' : 'secondary'
                    } btn-sm`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={item.que === 't' ? 'Asignado' : 'No asignado'}
                  >
                    <i className="fas fa-check"></i>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="alert alert-warning text-center border p-3 mb-0">
              No hay datos
            </div>
          )}
        </div>
      )}
    </div>
  );
};
